import React from 'react'
import { styled } from '@mui/material/styles'
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'



export type SwitchProps = MuiSwitchProps

export default function Switch(props: SwitchProps) {
  return <MuiSwitch {...props} />
}
