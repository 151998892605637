import { Box, useTheme } from '@mui/material'
import Logo from 'src/components/Logo/Logo';
import PatternLinesRegistration from 'src/components/PatternLines/PatternLinesRegistration';
import Typography from "src/components/Typography/Typography";
import CreateTemplateForm from './createTemplateForm';

export const CreateTemplate: React.FC = () => {
    const theme = useTheme()
    return (
        <Box
        sx={{
          position: 'relative',
          padding: { xs: '25px 16px 62px', sm: '49px 0 62px 0' },
          overflowX: 'hidden',
          background: `${theme.palette.gray[900]}`,
          minHeight: '100vh',
        }}
      >
        {/*top*/}
        <PatternLinesRegistration
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            width: '881px',
            height: '587px',
            overflow: 'hidden',
            [theme.breakpoints.down('lg')]: {
              height: '358px',
            },
            [theme.breakpoints.down('sm')]: {
              width: '290px',
              height: '161px',
            },
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: { xs: '-488px', sm: '-402px', lg: '-223px' },
              right: 332,
              transform: 'rotate(120deg)',
              background: `${theme.palette.gray[900]}`,
              width: 650,
              height: 1300,
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '86px',
            right: '26px',
            transform: 'rotate(30deg)',
            [theme.breakpoints.down('lg')]: {
              top: '48px',
              right: '-9px',
            },
            [theme.breakpoints.down('sm')]: {
              top: '25px',
              right: '-5px',
            },
          }}
        >
          <Logo
            size="large"
            variant="logo-black"
            sx={{
              width: { xs: '195px', sm: '300px', lg: '449px' },
              height: 'auto',
            }}
          />
        </Box>
        {/*bottom*/}
        <PatternLinesRegistration
          sx={{
            position: 'absolute',
            bottom: '0',
            width: '625px',
            height: '110px',
            left: 0,
            [theme.breakpoints.down('sm')]: {
              width: '312px',
              height: '27px',
            },
            overflow: 'hidden',
            '&:after': {
              content: '" "',
              position: 'absolute',
              top: '-213px',
              right: { xs: '-251px', sm: '-125px', md: '-89px' },
              transform: 'rotate(120deg)',
              background: `${theme.palette.gray[900]}`,
              width: '275px',
              height: '365px',
            },
          }}
        />
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="labelBig" sx={{ color: theme.palette.primary.light }}>
              {'Create a Game Template'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: { xs: '7px', sm: '16px' },
            }}
          >
          </Box>
  
        </Box>
  
        <Box
          sx={{
            width: { sm: '810px', xs: '100%' },
            margin: '0 auto',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <CreateTemplateForm />
        </Box>
      </Box>
    )
}