import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store/configureStore'
import {StreakType} from 'src/helpers/types'

const streakSelector = (state:RootState, gameId:string, type:StreakType) =>state.adminReducer.user.user?.statistics?.streaks &&
        state.adminReducer.user.user.statistics.streaks.filter((x:any) => x.intGameId === gameId && x.type===type)
       
  
export const filteredStreaks = createSelector(
    streakSelector,
    (streaks) => {
        if(streaks!==undefined && streaks.length>0){
            return {
                streak: streaks[0].streak,
                bestStreak: streaks[0].bestStreak,

            };
        }
        else{
            return {
                streak: 0,
                bestStreak: 0,
            };
        };
        
    }
);