import { PNG_ICONS } from 'src/assets/icons/icons'
import { ImageProps } from 'src/components/Image/Image'
import assets from 'src/assets'
import {ChallengeCardProps} from 'src/components/ChallengeCard/ChallengeCard'
import { EnterChallengePopupProps } from 'src/components/Popup/EnterChallengePopup/EnterChallengePopup'
import { ChallengeDetailsCardProps } from 'src/components/ChallengeDetailsCard/ChallengeDetailsCard'
import bgChallengesFrontland from 'src/assets/images/bgChallengesFrontland.jpg'
import bgChallengesFortnite from 'src/assets/images/bgChallengesFortnite.jpg'
import bannerFrontlandPhoto from 'src/assets/images/photoDownloadBaner.jpg'
import bannerFortnitePhoto from 'src/assets/images/downloadBanerFortnite.jpg'
import ChallengeCardFrontland from 'src/assets/images/ChallengeCardFrontland.png'
import challengeCardFortnine from 'src/assets/images/challengeCardFortnine.png'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import { AccountOptions, Statuses } from 'src/components/LinkedAccounts/LinkedAccounts'
import { AccountItem }  from 'src/components/AccountFieldArrayItem/AccountFieldArrayItem'
import ImageWithTitleSmall from 'src/components/ImageWithTitleSmall/ImageWithTitleSmall'
import ImageWithTitleWrapper from 'src/components/ImageWithTitleWrapper/ImageWithTitleWrapper'
import Typography from 'src/components/Typography/Typography'
import TrophyNumber from 'src/components/TrophyNumber/TrophyNumber'
import { Stack, Breakpoint, Theme } from '@mui/material'
import { Options } from 'src/components/Select/Select'
import Link from 'src/components/Link/Link'
import TimeAgo from 'react-timeago'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { config } from "src/config/config";
import { AccountType, GameTemplateType, DataHolderType, ActiveChallengeType, ChallengeStatus, GameId, ParticipantType,
    UserType, LogEventType, ChallengeEventType ,LiveEventType, GlobalLiveEventType, 
    TopListType,TopList_Type, GameHistoryType, SortOrder, TokenType } from "./types";
import { TableDataT } from 'src/components/LiveActivityNew/LiveActivityNew'

import {  translateGameType } from "src/utils/translate";
import { formatTokenToString, formatTokenToStringWithSymbol } from 'src/utils/tokenUtil'

export function accountMapper(accounts:AccountType[],processingEpic:boolean) {
      
  let notConnected =[] as AccountOptions;
  let connected= [] as AccountItem[]; 

  accounts?.forEach((account) => {
     //While we connect EPic we show it as connected with PROCESS status
     if(account.connected || (processingEpic && account.intGameId===GameId.FORTNITE)){
        connected.push(
        {
          value: account.nickname !== undefined ? account.nickname as string :  '',
          status: processingEpic && account.intGameId===GameId.FORTNITE ? Statuses.PROCESS : Statuses.CONNECTED,
          SvgIconProps: account.intGameId===GameId.FRONTLAND ? { name: 'play-fab' }: {name: 'epic-games'},
          disabled: true,
          LinkProps: {
            href: '#',
          },
          onChangeClick: () => void 0,
        });
     }
     else{
        notConnected.push(
          {
            id:  account.intGameId===GameId.FRONTLAND ? 'playfab': 'epicgames',
            SvgIconProps: account.intGameId===GameId.FRONTLAND ? { name: 'play-fab' }: {name: 'epic-games'},
            LinkProps: { 
              href: '#' 
            },
          }
        );
     }

  });

  return {
    notConnected: notConnected,
    connected: connected
  };
}


export function logEventText(logEvent:LogEventType, userId:number|undefined, nickname:string|undefined) : string | JSX.Element {
  switch (logEvent.challengeEventType) {
      case ChallengeEventType.CREATED:
          return `Challenge created`;
      case ChallengeEventType.JOINED:
          return logEvent.nickname !== undefined ? `${logEvent.nickname} joined challenge` : (userId === logEvent.participantId ? `${nickname} joined challenge` : 'Player joined challenge');
      case ChallengeEventType.LEFT:
          return logEvent.nickname !== undefined ? `${logEvent.nickname} left challenge` : (userId === logEvent.participantId ? `${nickname} left challenge` : 'Player left challenge');
      case ChallengeEventType.GAME_ROUND_COMPLETE:
          return `${logEvent.nickname} won a round ${logEvent.description}`;
      case ChallengeEventType.QUEUED_FOR_START:
          return `Challenge is queued to start`;
      case ChallengeEventType.GAME_STARTED:
          return `Challenge has started`;
      case ChallengeEventType.PLAYER_ERROR:
          return logEvent.nickname !== undefined ? `${logEvent.nickname} has an error. ${logEvent.description}` : (userId === logEvent.participantId ? `${nickname} has an error. ${logEvent.description}` : `Player has an error. ${logEvent.description}`);
      case ChallengeEventType.GAME_ERROR:
          return logEvent.nickname !== undefined ? `Challenge has an error, ${logEvent.nickname} ${logEvent.description}` : `Challenge has an error. ${logEvent.description}`;
      case ChallengeEventType.PLAY_TIME_OVER:
          return `Collecting scores`;
      case ChallengeEventType.GAME_FINISHED:
          return logEvent.nickname !== undefined ? `Challenge has finished! Winner ${logEvent.nickname}` : `Challenge has finished!`;
      case ChallengeEventType.CHALLENGE_PAYOUT:
          return <><span>{logEvent.nickname !== undefined ? logEvent.nickname + ' recieved' : 'Player recieved'} </span></>;
      case ChallengeEventType.REFUNDED_CHALLENGE_FEE:
          return <><span>{logEvent.nickname !== undefined ? logEvent.nickname +' was refunded' : 'Player was refunded' } </span></>;
      default:
          return "Unknown";
  }

}

export function liveEventAction(logEvent:LogEventType) : string{
  switch (logEvent.challengeEventType) {
      case ChallengeEventType.JOINED:
          return logEvent.nickname !== undefined ? `${logEvent.nickname}` :  'Player';
      case ChallengeEventType.GAME_STARTED:
          return logEvent.intGameId===GameId.FRONTLAND ? 'Challenge':'Challenge'
      case ChallengeEventType.CHALLENGE_PAYOUT:
          return logEvent.nickname !== undefined ? logEvent.nickname + ' recieved' : 'Player recieved';
      default:
          return "";
  }
}

function historyStatusColor(gameHistory:GameHistoryType){
  
  if(gameHistory.challengeError){
    return '#EEA40D';
  }  
  switch(gameHistory.challengeStatus){
    case ChallengeStatus.CANCELLED:
     return '#EEA40D';
    case ChallengeStatus.FINAL_DONE:
      if(gameHistory.participantError){
          return '#EEA40D'
      }
      else if(gameHistory.payout!==undefined && gameHistory.payout>0){
        return undefined;  
            
      }
      else{
        return undefined
      }
    default:
      return undefined;
  }  
}
export function runningGamesMapper(runningChallenges:ActiveChallengeType[]) : TableDataT {
  return {      
      headRow: {
        cell: [
          { value: 'Date' }, 
          { value: 'Challenge' }, 
          { value: 'User (s)' }, 
          { value: 'Result' },
          { value: 'Running time' },
          { value: 'Link' }
        ],
      },
      bodyRow: runningChallenges.map((challenge) => {   
        return { 
          cell: [
            { 
              value: new Date(challenge.started as Date).toLocaleDateString()+'', 
            },
            {
              value: () => (
                <ImageWithTitleWrapper 
                  variant="game" 
                  icon={challenge.intGameId===GameId.FRONTLAND ? PNG_ICONS.frontland : PNG_ICONS.fortnite} 
                  text={challenge.name as string} />
              ),
            },
            { 
              value: challenge.participantCount+'',
            },
            {
              value: challenge.status, color:undefined ,
            },
            {
              value: ()=>(<Typography variant="paragraphSmall"><TimeAgo formatter={formatter} date={challenge.started as Date}/></Typography>) 
            },
            {
              value: () => (
                <Link href={`/lobby/history/${challenge.id}`} color="gray.25" style={{ display: 'flex' }} disabled>
                  <SvgIcon name="arrow-right-line" />
                </Link>
              )
            }
          ]
        }
      })
   };
}

export function gamesPlayedMapper(gameHistory:GameHistoryType[]){
  return {
    limitRows: 8,
    data:{
      headRow: {
        cell: [
          { value: 'Date',  },
          { value: 'Game' },
          { value: 'Players' },
          { value: 'Status' },
          { value: '' },
        ],
      },
      bodyRow: gameHistory.map((history) => (
         {
          cell: [
            { value: new Date(history.datePlayed).toLocaleDateString()+''},
            {
              value: history.intGameId===GameId.FRONTLAND ? 
                 () => <ImageWithTitleSmall icon={PNG_ICONS.frontland} text="frontland" mobileHideTitle={true}/>
                 :
                 () => <ImageWithTitleSmall icon={PNG_ICONS.fortnite} text="fortnite" mobileHideTitle={true}/>
            },
            { value: history.participantCount+'' },
            {
              value: historyStatus(history), color:historyStatusColor(history)                
            },
            {
              value: () => (
                <Link href={`/lobby/history/${history.challengeId}`} color="gray.25" style={{ display: 'flex' }} disabled>
                  <SvgIcon name="arrow-right-line" />
                </Link>
              ),
            },

          ]
         }  
      ))
    }
  };
}

function historyStatus(gameHistory:GameHistoryType){
  
  if(gameHistory.challengeError){
    return 'Failed';
  }  
  switch(gameHistory.challengeStatus){
    case ChallengeStatus.CANCELLED:
     return 'Cancelled';
    case ChallengeStatus.FINAL_DONE:
      if(gameHistory.participantError){
          return 'Disqualified'
      }
      else if(gameHistory.payout!==undefined && gameHistory.payout>0){
        return (  
            () => (
              <>
                <Stack direction="row" gap="33px">
                  <TrophyNumber place={gameHistory.placement as number } />
                  <CoinImageWithAmount amount={`+${formatTokenToString(gameHistory.payout,gameHistory.tokenType as TokenType)}`} />
                </Stack>
              </>
            )
        )
      }
      else{
        return 'Lost'
      }
    default:
      return '';
  }  
}

export function leaderboardDataMapper(topLists : TopListType[], theme:Theme){
  let leaderboards=[] as any;
  topLists.forEach((list, index) =>{
      
    if(list.topListType===TopList_Type.MOST_WINS 
      || list.topListType===TopList_Type.MOST_PLAYED_GAMES || list.topListType===TopList_Type.HIGEST_WIN_PERCENTAGE
      || list.topListType===TopList_Type.BEST_WEEKLY_STREAK || list.topListType===TopList_Type.BEST_ALLTIME_STREAK){
      let leaderboard = {
        withTrophy: true,
        rowCount: 8,
        title: list.title,
        subtitle: list.description,   
        width:'100%',
        height:'auto',    
        data: {
          headRow: [] as any,
          bodyRow:[] as any
        },
        px: 0,
        bodystackprops:{
          pt: '17px',
          pl: '19px',
          pb: '13px',
          sx: {
            [theme.breakpoints.down('sm')]: {
              pt: '15px',
              pl: '17px',
              pr: '16px',
            },
          },
        },
        titledotprops: {} as any,
        griditemprops: {} as any,
      };

      let headerRow;
      switch(list.topListType){
        case TopList_Type.MOST_WINS:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Wins'},
            ],
          }; 
          leaderboard.titledotprops={ bottom: '8px' }; 
          leaderboard.griditemprops={xs:12, sm2:6, lg2:3.6};               
          break;
        case TopList_Type.MOST_PLAYED_GAMES:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Games'},
            ],
          };          
          leaderboard.titledotprops={ bottom: '8px' };  
          leaderboard.griditemprops={xs:12, sm2:6, lg2:3.6};      
          break;
        case TopList_Type.HIGEST_WIN_PERCENTAGE:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'W/G', width: '68px' },
              { value: 'Win%', width: '68px' },
            ],
          };      
          leaderboard.titledotprops={ bottom: '8px' };   
          leaderboard.griditemprops={xs:12,  lg2:4.8};     
          break;
        case TopList_Type.BEST_WEEKLY_STREAK:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Streak' },
            ],
          }; 
          leaderboard.titledotprops={ bottom: '8px' };
          leaderboard.griditemprops={xs:12, lg2:6};     
          break;
        case TopList_Type.BEST_ALLTIME_STREAK:
          headerRow = {
            cell: [
              { value: 'Player' },
              { value: 'Streak' },
            ],
          };  
          leaderboard.titledotprops={ bottom: { xs: '7px', sm: '8px' } }  
          leaderboard.griditemprops={xs:12, lg2:6};          
          break;
        default:
          headerRow =  {
            cell: [
             
            ],
          };
      }
      leaderboard.data.headRow=headerRow;

      list.topList.forEach((item,itemIndex) =>{
          let listItem;
          switch(list.topListType){
            case TopList_Type.MOST_WINS:
            case TopList_Type.MOST_PLAYED_GAMES:
            case TopList_Type.BEST_WEEKLY_STREAK:
            case TopList_Type.BEST_ALLTIME_STREAK:
             listItem={            
              cell: [
                { value: item.nickname, src: 'https://i.pravatar.cc/28' },
                { value: item.value },
              ],            
             }
             break;
             case TopList_Type.HIGEST_WIN_PERCENTAGE:
              listItem={            
                cell: [
                  { value: item.nickname, src: 'https://i.pravatar.cc/28' },
                  { value: `${item.longExtraValue1}/${item.longExtraValue2}` },
                  { value: item.formattedDecimalValue+'%' },
                ],            
               }
               break;
              default:
                listItem={};
          }

          leaderboard.data.bodyRow.push(listItem);
      })

      leaderboards.push(leaderboard);
    }

  });

  return leaderboards;
}
export function frontlandLobbyDataMapper(activeChallenge : ActiveChallengeType, user: UserType, breakpoint?: Breakpoint )  {  
  //Get template
  var data = frontlandLobbyData[activeChallenge.status];
  //Clone template so we do not update actual template when setting values
  data =  JSON.parse(JSON.stringify(data));

  if(data!==undefined){

    if(activeChallenge.error){
      data.title='Challenge has an error'     
    }
    data.subtitle=activeChallenge.errorDescription!==undefined ? activeChallenge.errorDescription : data.subtitle

    let tables=[] as any[];
    activeChallenge.participants.forEach((participant, index) =>{
        let table = JSON.parse(JSON.stringify(defaultLobbyTable)); //Need to clone otherwise we will copy over values
        let itsMe = participant.userId===user.id;
        table.data.bodyRow[0].cell[0].value= itsMe ? user.nickname : participant.nickname!==undefined ? participant.nickname : `Player ${index+1}`;
        table.text = itsMe ? 'Your side' : 'opposite side'
        table.playerid = participant.userId;

        let placement =  getPlacement(activeChallenge.status,participant);
        table.placement = placement;
        table.withTrophy = placement ===1;

        if(data !== undefined && data.winner!==undefined && activeChallenge.status===ChallengeStatus.FINAL_DONE && placement===1){          
          data.winner.nickname = participant.nickname as string;
          data.winner.prize = participant.payout!==undefined ? formatTokenToString(participant.payout, activeChallenge.tokenType) : '';           
        }

        table.data.bodyRow[0].cell[1].value = getFrontlandStatus(activeChallenge.status,participant);
        table.data.bodyRow[0].cell[1].color = getFrontlandStatusColor(activeChallenge.status,participant);
        table.data.bodyRow[0].cell[2].value = getFrontlandScore(activeChallenge.status,participant);
        table.data.bodyRow[0].cell[3].value = participant.feeRefunded ? 'Yes' : 'No' ;
        tables.push(table);
    })

    //Add spot/s for empty 
    tables = [...tables, ...(Array.apply(null,  Array(activeChallenge.maxParticipans-tables.length)).map((e, i) => (
         getEmptyLobbyTable(activeChallenge.status!==ChallengeStatus.NOT_STARTED)
    )))];

    data.leftTable=tables[0];
    data.rightTable=tables[1]; 

    data.challengeDetailsCardProps = LobbyCardMapper(activeChallenge, breakpoint);

  }
  
  return data;
}

export function fortniteLobbyDataMapper(activeChallenge : ActiveChallengeType, user: UserType, breakpoint?: Breakpoint)  {
  // Get template
  var data = fortniteLobbyData[activeChallenge.status];
  //Clone template so we do not update actual template when setting values
  data =  JSON.parse(JSON.stringify(data));

  if(data!==undefined){

    if(activeChallenge.error){
      data.title='Challenge has an error'     
      data.loading=false //Otherwise we will not see subtitle
    }
    data.subtitle=activeChallenge.errorDescription!==undefined ? activeChallenge.errorDescription : data.subtitle
    
    data.timerExpirySeconds= showTimer(activeChallenge.status) ? secondsToDone(activeChallenge) : undefined;    
    data.players.ready=activeChallenge.participantCount;
    data.players.total=activeChallenge.maxParticipans;

    data.table.data.bodyRow=[];    
    //Create winnerList, is undefined for other than FINAL_DONE
    if(activeChallenge.status===ChallengeStatus.FINAL_DONE){
      data.winnerList=[];
    }
    activeChallenge.participants.forEach((participant, index) =>{
        let itsMe = participant.userId===user.id;        
        let row = { 
          cell:[
            { value: activeChallenge.status===ChallengeStatus.NOT_STARTED ? index+1+'' :  '-'},
            { value: (itsMe ? user.nickname : (participant.nickname!==undefined ? participant.nickname : `Player ${index+1}`)), src: 'https://i.pravatar.cc/28'},
            { value: getFortniteStatus(activeChallenge.status,participant), color: getFortniteStatusColor(activeChallenge.status,participant) },
            { value: getFortniteKills(participant) },
            { value: getFortniteScore(participant) },
            { value: participant.feeRefunded ? 'Yes' : 'No'},
            { value: 'r'}
          ],
          noPlayers: false,
          playerid: participant.userId,
        };
        
        
        if(activeChallenge.status===ChallengeStatus.FINAL_DONE){
          if(participant.placement && participant.payout && participant.payout>0){
            let winner ={              
                name: participant.nickname as string,
                place: participant.placement,
                amount: `+ ${formatTokenToString(participant.payout,activeChallenge.tokenType)}`,
                isVertical: false,
                avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
              
              }
              
            data.winnerList?.push(winner);
          }
          
          //For finishers we set placement
          if(participant.placement)
            row.cell[0].value=participant.placement+'';

        }
        
        data?.table.data.bodyRow.push(row);
    })
    //Add spot/s for empty 
    data.table.data.bodyRow = [...data.table.data.bodyRow, ...(Array.apply(null,  Array(activeChallenge.maxParticipans-data.table.data.bodyRow.length)).map((e, i) => (
      { cell: null,
        noPlayers: activeChallenge.status===ChallengeStatus.NOT_STARTED ? false : true,
        playerid: 0,
      }
    )))];
   
    //Cut of trophies to number of winners
    if(data.winnerList){
      data.table.trophyLimit=data.winnerList.length
    }

    data.challengeDetailsCardProps = LobbyCardMapper(activeChallenge, breakpoint);

  }
  return data;
}

function secondsToDone(activeChallenge : ActiveChallengeType) : number{
  let waitTimeInSec = ((activeChallenge.preCooldownTime as number) + 
  (activeChallenge.matchTime as number) + (activeChallenge.resultCollectionTime as number)) * 60

  var date = new Date(activeChallenge.started as Date);
  date = createDateAsUTC(date);
  date.setSeconds(date.getSeconds() + waitTimeInSec);
  let now = new Date()
  var difference_s = (date.getTime() - now.getTime())/1000;
  return difference_s;
 
}

function createDateAsUTC(date : Date) {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}

export function liveEventMapper(logEvents : LogEventType[], user: UserType|undefined, challengeStatus: ChallengeStatus , description: string) : LiveEventType[]{

  let events:LiveEventType[] = logEvents.map((logEvent) => {   
      return { 
          date: logEvent.created, 
          message: logEventText(logEvent,user?.id, user?.nickname) as string, 
          highlighted: !isNotHighlighted(logEvent),
          coinImageWithAmountProps: {
            amount: logEvent.payout!==undefined ? formatTokenToString(logEvent.payout,logEvent.tokenType as TokenType): undefined,
        },
      }
  })
  //When players are suposed to start playing we set a information message in event log. 
  if(challengeStatus===ChallengeStatus.PRE_COOLDOWN_DONE){
    events.unshift(
      {
        date: new Date(),
        message: `Its time! Play match of Fortnite ${description}, our system will detect if you try to do anything else, it will result disqualification. When you are done, our system will detect how it went and compare it to the other players in the lobby.`,
        highlighted: true,
        coinImageWithAmountProps: {
          amount: undefined,
        },
      }
    );
  }
  return events;
}

export function globalLiveEventTableMapper(logEvents : LogEventType[]) : TableDataT {
    return {      
        headRow: {
          cell: [{ value: 'Date' }, { value: 'Challenge' }, { value: 'User (s)' }, { value: 'Result' }],
        },
        bodyRow: logEvents.map((logEvent) => {   
          return { 
            cell: [
              { 
                value: ()=>(<Typography variant="paragraphSmall">{/*<TimeAgo formatter={formatter} date={logEvent.created}/>*/}</Typography>) 
              },
              {
                value: () => (
                  <ImageWithTitleWrapper 
                    variant="game" 
                    icon={logEvent.intGameId===GameId.FRONTLAND ? PNG_ICONS.frontland : PNG_ICONS.fortnite} 
                    text={logEvent.challengeName as string} />
                ),
              },
              { 
                value: liveEventUsers(logEvent), 
              },
              {
                value: liveEventResult(logEvent),
              }
            ]
          }
        })
     };
}
function formatter( value:number, unit:string, suffix:string, epoc:any, nextFormatter:any ) {
  if (suffix==='from now' || (value < 60 && unit === 'second')) {
      return 'Just now'
  }
  else if(unit==='minute'){
    return `${value} min ${suffix}`;
  }
  else
      return nextFormatter();
}

function liveEventUsers(logEvent:LogEventType) : string | (() => JSX.Element){
  switch (logEvent.challengeEventType) {
      case ChallengeEventType.JOINED:
      case ChallengeEventType.CHALLENGE_PAYOUT:
          return () => (
            <ImageWithTitleWrapper
              variant="avatar"
              icon="https://i.pravatar.cc/28"
              text={logEvent.nickname !== undefined ? `${logEvent.nickname}` :  'Player'}
            />
          ); 
      case ChallengeEventType.GAME_STARTED:
          return () => <Typography variant="paragraphSmall">{logEvent.numberOfParticipants} Players</Typography>;     
      default:
          return "";
  }
}

function liveEventResult(logEvent:LogEventType) : string | (() => JSX.Element){
  switch (logEvent.challengeEventType) {
      case ChallengeEventType.JOINED:
        return "Joined"
      case ChallengeEventType.CHALLENGE_PAYOUT:
          return () => <CoinImageWithAmount amount={`+ ${formatTokenToString(logEvent.payout,logEvent.tokenType as TokenType)}`} />;
      case ChallengeEventType.GAME_STARTED:
          return "Started";     
      default:
          return "";
  }
}


function showTimer(challengeStatus: ChallengeStatus) : boolean{
  switch(challengeStatus){
    case ChallengeStatus.PRE_COOLDOWN_DONE:
    case ChallengeStatus.PLAY_TIME_OVER:
      return true
    default:
      return false;
  }
}

function isNotHighlighted( event: LogEventType)   {
  return event.challengeEventType === ChallengeEventType.JOINED || event.challengeEventType === ChallengeEventType.LEFT ||
    event.challengeEventType === ChallengeEventType.GAME_ROUND_COMPLETE || event.challengeEventType === ChallengeEventType.QUEUED_FOR_START || event.challengeEventType === ChallengeEventType.CHALLENGE_PAYOUT || event.challengeEventType === ChallengeEventType.REFUNDED_CHALLENGE_FEE || event.challengeEventType === ChallengeEventType.PLAYER_ERROR || event.challengeEventType === ChallengeEventType.GAME_ERROR
}

function getFrontlandStatus(challengeStatus: ChallengeStatus,participant: ParticipantType) : string {
  if(participant.error)
    return participant.errorDescription as string
  
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
      return 'In game';
    case ChallengeStatus.NOT_STARTED:
    case ChallengeStatus.READY_TO_START:
      return (participant.startedMatchmaking!==undefined && participant.startedMatchmaking) ? 'In matchmaking' : 'In lobby'
    default:
      return 'In lobby';
  }
}

function getFrontlandStatusColor(challengeStatus: ChallengeStatus,participant: ParticipantType) :  string|undefined {
  if(participant.error)
    return '#EEA40D';
  
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
      return '#56E612';
    case ChallengeStatus.NOT_STARTED:
    case ChallengeStatus.READY_TO_START:
      return (participant.startedMatchmaking!==undefined && participant.startedMatchmaking) ? '#56E612' : undefined
    default:
      return undefined;
  }
}

function getFortniteStatus(challengeStatus: ChallengeStatus,participant: ParticipantType) : string {
  if(participant.error)
    return participant.errorDescription as string
  
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
    case ChallengeStatus.STARTING_SCORE_COLLECTED:
    case ChallengeStatus.PRE_COOLDOWN_DONE:
      return 'In game';
    case ChallengeStatus.PLAY_TIME_OVER:
    case ChallengeStatus.FINAL_DONE:
      return showFortniteFinishedTop(participant);
    default:
      return 'In lobby';
  }
}

function getFortniteStatusColor(challengeStatus: ChallengeStatus,participant: ParticipantType) : string|undefined {
  if(participant.error)
    return '#EEA40D';
  
  switch(challengeStatus){
    case ChallengeStatus.NOT_STARTED:
    case ChallengeStatus.READY_TO_START:
    case ChallengeStatus.RUNNING:
    case ChallengeStatus.STARTING_SCORE_COLLECTED:
    case ChallengeStatus.PRE_COOLDOWN_DONE:
    case ChallengeStatus.CANCELLED:
      return undefined;
    case ChallengeStatus.PLAY_TIME_OVER:
    case ChallengeStatus.FINAL_DONE:
      return getFortniteFinishedTopColor(participant);
    default:
      return '#56E612';
  }
}

function showFortniteFinishedTop(participant: ParticipantType) : string {

  if (participant.finishedTop1 !== undefined && participant.finishedTop1) {
      return 'Won game!';
  }
  else if (participant.finishedTop10 !== undefined && participant.finishedTop10) {
      return 'Finished top 10!';
  }
  else if (participant.finishedTop25 !== undefined && participant.finishedTop25) {
      return 'Finished top 25!';
  }
  else if (participant.finishedTop1 !== undefined &&  participant.finishedTop10 !== undefined &&  participant.finishedTop25 !== undefined && !participant.finishedTop1 && !participant.finishedTop10 && !participant.finishedTop25) {
      return 'Died early';
  }
  else {
      return 'In game';
  }
    
}

function getFortniteFinishedTopColor(participant: ParticipantType) : string|undefined {

  if (participant.finishedTop1 !== undefined && participant.finishedTop1) {
      return '#56E612';
  }
  else if (participant.finishedTop10 !== undefined && participant.finishedTop10) {
      return '#56E612';
  }
  else if (participant.finishedTop25 !== undefined && participant.finishedTop25) {
      return '#56E612';
  }
  else if (participant.finishedTop1 !== undefined &&  participant.finishedTop10 !== undefined &&  participant.finishedTop25 !== undefined && !participant.finishedTop1 && !participant.finishedTop10 && !participant.finishedTop25) {
      return '#56E612';
  }
  else {
      return undefined;
  }
    
}


function getFrontlandScore(challengeStatus: ChallengeStatus, participant: ParticipantType) : string {
  switch(challengeStatus){
    case ChallengeStatus.RUNNING:
    case ChallengeStatus.FINAL_DONE:
      return participant.roundsWon !== undefined ? participant.roundsWon+'' : '0';
    case  ChallengeStatus.CANCELLED:
      return '';
    default:
      return '-';
  }
}

function getFortniteScore(participant: ParticipantType) : string {
  return (participant.score !== undefined && participant.score !== null ? participant.score +' p.' : '');
}

function getFortniteKills(participant: ParticipantType) : string{
  return (participant.kills !== undefined && participant.kills !== null ? participant.kills+'' : '');
}

function getPlacement(challengeStatus: ChallengeStatus, participant: ParticipantType) : number {
   switch(challengeStatus){
    case ChallengeStatus.FINAL_DONE:
      return participant.placement !== undefined ? participant.placement : 0;
    default:
      return 0;
  }
}


function selectGameRoundsText(intGameId: GameId) {

  if (intGameId === GameId.FORTNITE) {
      return '1 Game';
  }
  else if (intGameId === GameId.FRONTLAND) {
      return 'First to 5';
  }
  else {
      return 'Unknown';
  }
}

export function LobbyCardMapper(activeChallenge : ActiveChallengeType,  breakpoint: Breakpoint = 'sm') : ChallengeDetailsCardProps {
  return  activeChallenge.intGameId === GameId.FRONTLAND ?  {
    gameBoxProps: {
      imageProps: {
        src: breakpoint === 'sm'
        ? PNG_ICONS.tabletLobbyFrontlandDetails
        : PNG_ICONS.lobbyFrontlandDetails,
      },
      imageWithTitleProps: {
        imageProps: {
          src: PNG_ICONS.frontland,
        },
        title: 'Frontland',
      },
    },
    list: [
      {
        avatarProps: {
          src: PNG_ICONS.lobbyFrontlandDetailsMap,
        },
        label: 'Map',
        value: activeChallenge.name,
      },
      { icon: 'user-group-2-fill', label: 'Game mode', value: translateGameType(activeChallenge.gameType) },
      { icon: 'repeat-line', label: 'Rounds', value: selectGameRoundsText(activeChallenge.intGameId) },
      { icon: 'trophy-fill', label: 'Prize', value:  formatTokenToStringWithSymbol(activeChallenge.pricePotAfterCut,activeChallenge.tokenType) },
    ],
    
  } :
  {
    gameBoxProps: {
      imageProps: {
        src: breakpoint === 'sm' ? PNG_ICONS.tabletLobbyFortniteDetails : PNG_ICONS.lobbyFortniteDetails,
      },
      imageWithTitleProps: {
        imageProps: {
          src: PNG_ICONS.fortnite,
        },
        title: 'Fortnite',
      },
    },
    list: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: activeChallenge.description },
      { icon: 'repeat-line', label: 'Rounds', value: '1' },
      { icon: 'team-fill', label: 'Players', value: activeChallenge.maxParticipans+'' },
      {
        icon: 'trophy-fill',
        label: prizeCountLabel(activeChallenge.prices),
        value: formatPrizePool(activeChallenge.prices,activeChallenge.tokenType),
        showAllPrizes: true,
      },
    ],
    
  }

}

export const EmptyTemplate: 
    ChallengeCardProps & {
    iconSrc: ImageProps['src']
    tableData?: EnterChallengePopupProps['tableData']
    gameTemplate?: GameTemplateType
  }
 = 
  {
    title: '',
    bgSrc: ChallengeCardFrontland,
    iconSrc: PNG_ICONS.frontland,
    dataSource: [
      { icon: 'user-group-2-fill', label: 'Game mode', value: '' },
      { icon: 'repeat-line', label: 'Rounds', value: '' },
      { icon: 'trophy-fill', label: 'Prize', value: '' },
    ],
    btnProps:{
        label: '',
        subLabel: '',
    },
    tableData: [
      [
        'Map',
        {
          picture: ChallengeCardFrontland,
          text: 'Name of the Map',
        },
      ],
      ['Rounds', ''],
      ['Game mode', ''],
      [
        'Prize  pool',

        {
          picture: PNG_ICONS.coins,
          text: '',
          imageSx: {
            width: '18px',
            height: '18px',
          },
        },
      ],
    ],
  }

export const availableGamesList = [
    {
      id: GameId.FRONTLAND,
      icon: PNG_ICONS.frontland,
      name: 'Frontland',
    },
    {
      id: GameId.FORTNITE,
      icon: PNG_ICONS.fortnite,
      name: 'Fortnite',
    },
    {
      id: 'battlefield2042',
      icon: PNG_ICONS.voteCard,
      name: 'Battlefield 2042',
      isComingSoon: true,
    },
  ]

  export const gameSortFilterHeaderOptions: Options = [
    {
      // icon: 'home-heart-line',
      value:  SortOrder.FEE_HIGH,
      label: 'High Fee',
    },
    {
      value: SortOrder.FEE_LOW,
      label: 'Low fee',
    }    
  ]


  export const challengesData = {
    FRONTLAND: {
      bgImage: bgChallengesFrontland,
      gameSortFilterHeaderProps: {
        title: 'Frontland',
        src: PNG_ICONS.frontland,
      },
      list: undefined,
      totalGames: 0,
      winGamesCount: 0,
      loseGamesCount: 0,
      fiveKillRow: 0,
      treeKillRow: 0,
      gameDownloadBanner: {
        photoImgSrc: bannerFrontlandPhoto,
        iconSrc: PNG_ICONS.frontlandBig,
        title: 'Frontland',
        subtitle: 'Challenge originals',
        longText:
          "Frontland is a multiplayer online game that allows players to engage in fast-paced and intense battles in a third-person perspective. The game is built on Unreal Engine 5",
        link: undefined//Will set this from loaded game
        },
    },
    FORTNITE: {
      bgImage: bgChallengesFortnite,
      gameSortFilterHeaderProps: {
        title: 'Fortnite',
        src: PNG_ICONS.fortnite,
      },
      list: undefined,
      totalGames: 0,
      winGamesCount: 0,
      loseGamesCount: 0,
      fiveKillRow: 0,
      treeKillRow: 0,
      gameDownloadBanner: {
        photoImgSrc: bannerFortnitePhoto,
        iconSrc: PNG_ICONS.fortniteBig,
        title: 'Fortnite',
        subtitle: 'Challenge extended',
        longText: "Fortnite is a wildly popular online video game developed by Epic Games that has taken the gaming world by storm since its release in 2017. It is a battle royale game where players compete against each other to be the last person or team standing on a shrinking island. You can play Challenges in Fortnite with or without building",
        link: 'https://www.fortnite.com/download',
        target:'_blank'
      },
    },
  }

  const prizeCountLabel = (prizes:any[])=>{
    const count =  prizes?.filter((x:any) => x.payout>0).length;
    if(count>1){
      return `${count} prizes`
    }
    else{
      return 'Prize'
    }
  }

   const formatPrizePool = (prizes:any[], tokenType:TokenType)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = [];
    for (let i = 0; i < prizes?.length; i++) {
        if(prizes[i].payout>0){
            result.push(formatTokenToStringWithSymbol(prizes[i].payout,tokenType))
        }
    }
    return result;

    }

   export const defaultData = {
        open: false,
        challengeCard: EmptyTemplate,
    } as DataHolderType

   const defaultLobbyTable = {
      variant: 'commands',
      count: 1,
      text: 'opposite side',
      withTrophy: false,
      placement: 0,
      playerid: 0,
      data: {
        headRow: {
          cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }, { value: 'Refunded' }],
        },
        bodyRow: [
          {
            cell: [
              { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
              { value: 'In lobby' },
              { value: '' },
              { value: '' },
            ],
          },
        ],
      },
    }
    function getEmptyLobbyTable(noPlayers: boolean){
       return (
          {
            variant: 'commands',
            count: 0,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
                noPlayers: noPlayers,
              },
              bodyRow: [{ cell: null }],
            },
          }
       )
    } 

    const frontlandLobbyData = {
     
        NOT_STARTED: {
          status: 'waiting',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbySolo,
            },
          },
          loading: true,
          title: 'Waiting for an opponent',
          subtitle: undefined as unknown,
          winner: undefined,   
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },          
          rightTable: getEmptyLobbyTable(false),
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        READY_TO_START: {
          status: 'commencing',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge is commencing',
          subtitle: undefined as unknown,
          winner: undefined,      
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        RUNNING: {
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          winner: undefined,   
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In Game', color: '#FFD200' },
                    { value: '0' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: 'In Game', color: '#FFD200' },
                    { value: '0' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        calculating: {
          status: 'calculating',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: true,
          title: 'Challenge is completed Calculating winner',
          subtitle: undefined as unknown,
          winner: undefined,     
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '5' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '3' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        FINAL_DONE: {
          status: 'completed',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge is completed!',
          subtitle: undefined as unknown,
          winner : { 
            nickname: '',
            prize: '',
          },          
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            withTrophy: true,
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: '' },
                    { value: '5' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: '' },
                    { value: '3' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        CANCELLED: {
          status: 'cancelled',
          pageMediaWithShadowBoxProps: {
            videoProps: {
              src: assets.video.lobbyFilled,
            },
          },
          loading: false,
          title: 'Challenge has been cancelled',
          subtitle: undefined as unknown,
          winner: undefined,   
          leftTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          rightTable: {
            variant: 'commands',
            count:1,
            text: 'opposite side',
            data: {
              headRow: {
                cell: [{ value: 'Player' }, { value: 'Status' }, { value: 'Kills' }],
              },
              bodyRow: [
                {
                  cell: [
                    { value: 'Nickname', src: 'https://i.pravatar.cc/28' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false,
                },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        STARTING_SCORE_COLLECTED: undefined,
        PRE_COOLDOWN_DONE:undefined,
        PLAY_TIME_OVER: undefined,
           
    }


    const fortniteLobbyData = {
     
        NOT_STARTED:{
          status: 'waiting',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: true,
          title: 'Waiting for opponents',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 1,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false , playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false , playerid: 0},
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: [],
        },
        READY_TO_START:{
          status: 'commencing',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: true,
          title: 'Challenge is commencing',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In lobby' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false,
                  playerid: 0,
                },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: [],
        },
        RUNNING:{
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false, playerid: 0},
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        STARTING_SCORE_COLLECTED:{
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false , playerid: 0},
                { cell: null, noPlayers: false , playerid: 0 },
                { cell: null, noPlayers: false , playerid: 0 },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        PRE_COOLDOWN_DONE:{
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: undefined as unknown,
          timerExpirySeconds: 0,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        PLAY_TIME_OVER: {
          status: 'progress',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is in progress',
          subtitle: 'Getting final results',
          timerExpirySeconds: 0,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0 },
                { cell: null, noPlayers: false, playerid: 0 },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        FINAL_DONE:{
          status: 'completed',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge is completed!',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          
          winnerList: [
            {
              name: 'MasterChief',
              place: 1,
              amount: '+ 1.8',
              isVertical: false,
              avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
            },
            {
              name: 'Nikman',
              place: 2,
              amount: '+ 1.0',
              isVertical: false,
              avatarSrc: 'https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg',
            },
          ],
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            withTrophy: true,
            trophyLimit: 1,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'Top 25', color: '#56E612' },
                    { value: '4' },
                    { value: '51 p.' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
        CANCELLED: {
          status: 'cancelled',
          pageMediaWithShadowBoxProps: {
            imageProps: {
              src: assets.png.fortnitePageImg,
            },
          },
          loading: false,
          title: 'Challenge has been cancelled',
          subtitle: undefined as unknown,
          timerExpirySeconds: undefined,
          winnerList: undefined,
          players: {
            ready: 5,
            total: 5,
          },
          table: {
            trophyLimit: undefined,
            data: {
              headRow: {
                cell: [
                  { value: '#' },
                  { value: 'Player' },
                  { value: 'Placement' },
                  { value: 'Kills' },
                  { value: 'Score' },
                  { value: 'Refunded' },
                  { value: 'Action' },
                ],
              },
              bodyRow: [
                {
                  cell: [
                    { value: ''},
                    { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                    { value: 'In game' },
                    { value: '0' },
                    { value: '0' },
                    { value: '' },
                    { value: '' },
                  ],
                  noPlayers: false, playerid: 0
                },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
                { cell: null, noPlayers: false, playerid: 0  },
              ],
            },
          },
          challengeDetailsCardProps: undefined as any,
          liveUpdateList: []
        },
      
    }

    export const leaderboard2 = {
      hotStreak: {
        title:"Hot streak",
        subtitle:"Longest Kills in a Streak",
        width:"410px",
        height:"auto",
        withTrophy: true,
        rowCount: 8,
        data: {
          headRow: {
            cell: [{ value: 'Player' }, { value: 'Kills' }, { value: 'Date' }, { value: '' }],
          },
          bodyRow: [
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '8' },
                { value: '01.03.23' },
                {
                  value: () => (
                    <CoinImageWithAmount amount="+0.12" amountTypographyProps={{ color: 'gray.0' }} />
                  ),
                },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '7' },
                { value: '01.03.23' },
                {
                  value: () => (
                    <CoinImageWithAmount amount="+0.12" amountTypographyProps={{ color: 'gray.0' }} />
                  ),
                },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '4' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
            {
              cell: [
                { value: 'MasterChief', src: 'https://i.pravatar.cc/28' },
                { value: '3' },
                { value: '01.03.23' },
                { value: '' },
              ],
            },
          ],
        },
      },    
      
    }
