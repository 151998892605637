import React from 'react'
import { Box, BoxProps } from '@mui/material'

type GradientBorderProps = BoxProps & {
  thickness?: number
  degrees?: number
  offsetLeft?: number
  offsetRight?: number
  leftColorRgba?: string
  rightColorRgba?: string
  children?: React.ReactNode
  place?: number
  childrenBoxProps?: BoxProps
}

function GradientBorder({
  thickness = 1,
  degrees = 60,
  offsetLeft = 0,
  offsetRight = 62,
  leftColorRgba = '30,40,58,1',
  rightColorRgba = '30,40,58,1',
  place = 10,
  children,
  childrenBoxProps,
  ...restProps
}: GradientBorderProps) {
  if (!place) {
    return <>{children}</>
  }
  // Color change if there is Place
  switch (place) {
    case 1:
      leftColorRgba = '238,164,13,0.5'
      rightColorRgba = '30,40,58,1'
      break
    case 2:
      leftColorRgba = '169,175,190,0.5'
      rightColorRgba = '30,40,58,1'
      break
    case 3:
      leftColorRgba = '198,128,64,0.5'
      rightColorRgba = '30,40,58,1'
      break
  }

  return (
    <Box
      position="relative"
      zIndex={0}
      sx={{
        background: `linear-gradient(${degrees}deg, rgba(${leftColorRgba}) ${offsetLeft}%, rgba(${rightColorRgba})${offsetRight}%)`,
        '&:before': {
          content: '" "',
          position: 'absolute',
          top: thickness,
          bottom: thickness,
          left: thickness,
          right: thickness,
          bgcolor: 'gray.900',
          zIndex: 0,
        },
      }}
      p={`${thickness}px`}
      {...restProps}
    >
      <Box position="relative" zIndex={1} {...childrenBoxProps}>
        {children}
      </Box>
    </Box>
  )
}

export default GradientBorder
