import React from 'react'
import HorizontalWinnerBox from 'src/components/HorizontalWinnerBox/HorizontalWinnerBox'
import VerticalWinnerBox from 'src/components/VerticalWinnerBox/VerticalWinnerBox'

export type WinnerLobbyBoxProps = {
  isVertical: boolean
  avatarSrc?: string
  name: string
  place?: number
  amount: string
}

export default function WinnerLobbyBox({
  isVertical,
  avatarSrc,
  name,
  place = 1,
  amount,
}: WinnerLobbyBoxProps) {
  return isVertical ? (
    <VerticalWinnerBox avatarSrc={avatarSrc} name={name} place={place} amount={amount} />
  ) : (
    <HorizontalWinnerBox avatarSrc={avatarSrc} name={name} place={place} amount={amount} />
  )
}
