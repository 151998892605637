import React from 'react'
import Button, { ButtonProps } from '../Button/Button'
import ChallengeCardButton, {
  ChallengeCardButtonProps,
} from '../ChallengeCardButton/ChallengeCardButton'

export type MainButtonProps = ChallengeCardButtonProps & ButtonProps

const MainButton = (props: MainButtonProps) => {
  if (props.subLabel) {
    const { subLabel, label, children, ...restProps } = props
    return <ChallengeCardButton label={children || label} subLabel={subLabel} {...restProps} />
  }
  return <Button {...props} />
}

export default MainButton
