import React from 'react';
import { ChallengeType, LeaderboardWinnerType, GameType,  ChallengeEventType, TournamentType } from './const';
import { GameId, LogEventType, TokenType } from 'src/helpers/types';

export function translateChallengeType(type:string) {
    switch (type) {
        case ChallengeType.FREE:
            return "Mini Mash";
        case ChallengeType.BIG_BUCKS:
            return "Big Bucks";
        case ChallengeType.MAJOR_MAYHEM:
            return "Major Mayhem";
        case ChallengeType.QUALIFIER:
            return "Fridayplay Challenge Qualifier";
        case ChallengeType.FINAL:
            return "Fridayplay Challenge Final";
        default:
            return "Unknown";
    }
}

export function translateLeaderboardWinnerType(type:string) {
    switch (type) {
        case LeaderboardWinnerType.DAILY_SCORE:
            return "Daily";
        case LeaderboardWinnerType.WEEKLY_SCORE:
            return "Weekly";
        case LeaderboardWinnerType.MONTHLY_SCORE:
            return "Monthly";
        default:
            return "Unknown";
    }
}

export const translateGameType= (gameType:string) => {
    switch (gameType) {
        case GameType.SOLO:
            return "Solo";
        case GameType.DUO:
            return "Duo";
        case GameType.ONE_VS_ONE:
            return "1v1";
        default:
            return "Unknown";
    }
}


export const translateTournamentType= (tournamenType:string) => {
    switch (tournamenType) {
        case TournamentType.SINGLE_ELIMINATION:
            return "Single Elimination";
        default:
            return "Unknown";
    }
}

//nickname will only be undefined when we use this to display historic challenges, then we do not need nickname
export function logEventText(logEvent:LogEventType, userId:number|undefined, nickname:string|undefined) : string | JSX.Element {
    switch (logEvent.challengeEventType) {
        case ChallengeEventType.CREATED:
            return `Challenge created`;
        case ChallengeEventType.JOINED:
            return logEvent.nickname !== undefined ? `${logEvent.nickname} joined challenge` : (userId === logEvent.participantId ? `${nickname} joined challenge` : 'Player joined challenge');
        case ChallengeEventType.LEFT:
            return logEvent.nickname !== undefined ? `${logEvent.nickname} left challenge` : (userId === logEvent.participantId ? `${nickname} left challenge` : 'Player left challenge');
        case ChallengeEventType.GAME_ROUND_COMPLETE:
            return `${logEvent.nickname} won a round ${logEvent.description}`;
        case ChallengeEventType.QUEUED_FOR_START:
            return `Challenge is queued to start`;
        case ChallengeEventType.GAME_STARTED:
            return `Challenge has started`;
        case ChallengeEventType.PLAYER_ERROR:
            return logEvent.nickname !== undefined ? `${logEvent.nickname} has an error. ${logEvent.description}` : (userId === logEvent.participantId ? `${nickname} has an error. ${logEvent.description}` : `Player has an error. ${logEvent.description}`);
        case ChallengeEventType.GAME_ERROR:
            return logEvent.nickname !== undefined ? `Challenge has an error, ${logEvent.nickname} ${logEvent.description}` : `Challenge has an error. ${logEvent.description}`;
        case ChallengeEventType.PLAY_TIME_OVER:
            return `Collecting scores`;
        case ChallengeEventType.GAME_FINISHED:
            return logEvent.nickname !== undefined ? `Challenge has finished! Winner ${logEvent.nickname}` : `Challenge has finished!`;
        case ChallengeEventType.CHALLENGE_PAYOUT:
            return <><span>{logEvent.nickname !== undefined ? logEvent.nickname + ' recieved' : 'Player recieved'} </span></>;
        case ChallengeEventType.REFUNDED_CHALLENGE_FEE:
            return <><span>{logEvent.nickname !== undefined ? logEvent.nickname +' was refunded' : 'Player was refunded' } </span></>;
        default:
            return "Unknown";
    }
 
}

export function liveEventAction(logEvent:LogEventType) : string{
    switch (logEvent.challengeEventType) {
        case ChallengeEventType.JOINED:
            return logEvent.nickname !== undefined ? `${logEvent.nickname}` :  'Player';
        case ChallengeEventType.GAME_STARTED:
            return logEvent.intGameId===GameId.FRONTLAND ? 'Challenge':'Challenge'
        case ChallengeEventType.CHALLENGE_PAYOUT:
            return logEvent.nickname !== undefined ? logEvent.nickname + ' recieved' : 'Player recieved';
        default:
            return "";
    }
}
