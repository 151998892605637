import { Avatar, AvatarProps as MuiAvatarProps, Stack, StackProps, useTheme } from '@mui/material'
import React from 'react'
import PatternLines from '../PatternLines/PatternLines'
import SvgIcon from '../SvgIcon/SvgIcon'
import Typography, { TypographyProps } from '../Typography/Typography'
import { DrawWaiting } from 'src/utils/drawWaiting'
// List of Style Variants
export enum Variants {
  Ready = 'ready',
  Searching = 'searching',
  NoPlayers = 'noPlayers',
}
type VariantsT = `${Variants}` // Co "Enum Variants" values to input options

type PlayerStatusProps = StackProps & {
  text: string
  src?: string
  variant?: VariantsT
  textTypographyProps?: TypographyProps
  AvatarProps?: MuiAvatarProps
}

function PlayerStatus({
  text,
  src,
  variant = Variants.Ready,
  textTypographyProps,
  AvatarProps = {},
  ...restProps
}: PlayerStatusProps) {
  const theme = useTheme()
  const { sx: sxAvatarProps, ...restAvatarProps } = AvatarProps
  let stackVariantProps: StackProps = {}
  let textVariantProps: TypographyProps = {}
  // Changing the type of text for the variant 'noPlayers'
  const typographyVariant = variant === Variants.NoPlayers ? 'buttonMedium' : 'paragraphSmall'
  // Styles for different component variant
  switch (variant) {
    case Variants.Ready:
      stackVariantProps = { gap: '4px' }
      textVariantProps = {
        noWrap: true,
        sx: {
          maxWidth: '150px',
          [theme.breakpoints.down('md')]: {
            maxWidth: '60px',
          },
        },
      }
      break
    case Variants.Searching:
      stackVariantProps = { px: '10px', gap: '7px' }
      break
    case Variants.NoPlayers:
      stackVariantProps = { bgcolor: 'gray.700', px: '13px' }
      textVariantProps = { color: 'gray.500' }
      break
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      position="relative"
      height="100%"
      width="100%"
      {...stackVariantProps}
      {...restProps}
    >
      {variant !== Variants.Ready && <PatternLines ml={0} left={0} width="100%" height="100%" />}
      {variant === Variants.Ready && (
        <Avatar
          variant="square"
          alt={text}
          src={src}
          sx={{ width: 28, height: 28, ...sxAvatarProps }}
          {...restAvatarProps}
        />
      )}
      {variant === Variants.Searching && (
        <SvgIcon name="user-search-line" fontSize="small" color="primary" sx={{ zIndex: 1 }} />
      )}
      <Typography
        variant={typographyVariant}
        zIndex={1}
        {...textVariantProps}
        {...textTypographyProps}
      >
        {text}{variant === Variants.Searching && <DrawWaiting char='.'/>}
      </Typography>
    </Stack>
  )
}

export default PlayerStatus
