import React, { useState, useCallback, useMemo } from 'react'
import { createCtx } from './helpers'

type BlockchainContextType = {
  isNetworkMismatch?: boolean
  setNetworkMismatch: (mismatch:boolean|undefined) => void
  isWalletConnected: boolean
  setWalletConnected : (connected:boolean) => void
}

const [useBlockchainContext, BlockchainContextProvider] = createCtx<BlockchainContextType>()

export { useBlockchainContext }

export default function BlockchainProvider({ children }: React.PropsWithChildren<unknown>) {
  const [isNetworkMismatch, setIsNetworkMismatch] = useState<boolean|undefined>(undefined)
  const [isWalletConnected, setIsWalletConnected] = useState(false)

  const setNetworkMismatch = useCallback((mismatch:boolean|undefined) => {
    setIsNetworkMismatch(mismatch)
  }, [])

  const setWalletConnected = useCallback((connected:boolean) => {
    setIsWalletConnected(connected)
  }, [])

  const authValue = useMemo(
    () => ({
      isNetworkMismatch,
      setNetworkMismatch,
      isWalletConnected,
      setWalletConnected
    }),
    [isNetworkMismatch, setNetworkMismatch, isWalletConnected, setWalletConnected]
  )
  return <BlockchainContextProvider value={authValue}>{children}</BlockchainContextProvider>
}
