import React from 'react'
import AuthProvider, { useAuthContext } from './auth-context'
import ChallengeProvider, { useChallengeContext } from './challenge-context'
import LobbyProvider, { useLobbyContext } from './lobby-context'
import BlockchainProvider, { useBlockchainContext } from './blockchain-context'
import {
  NotificationToastContainer,
  ServiceMessageToastContainer,
} from 'src/components/Notification/Notification'


export function ContextProvider({ children }: React.PropsWithChildren<unknown>) {
  return (
    <AuthProvider>
      <BlockchainProvider>
        <LobbyProvider>
          <ChallengeProvider>{children}</ChallengeProvider>
        </LobbyProvider>
      </BlockchainProvider>
      <NotificationToastContainer />
      <ServiceMessageToastContainer />
    </AuthProvider>
  )
}

const ctx = {
  useAuth: useAuthContext,
  useLobby: useLobbyContext,
  useChallenge: useChallengeContext,
  useBlockchain: useBlockchainContext,
} as const

export default ctx
