import React from 'react'
import { Box, Stack } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import Image from 'src/components/Image/Image'
import Typography from 'src/components/Typography/Typography'
import Link from 'src/components/Link/Link'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import CustomHorizontalDivider from 'src/components/CustomHorizontalDivider/CustomHorizontalDivider'
import { PNG_ICONS } from 'src/assets/icons/icons'

type DownloadBannerProps = {
  title?: string
  imageProps?: {
    src: string
    alt: string
  }
  description?: string
  linkProps?: {
    href: string
    startIcon?: React.ReactNode
    children?: React.ReactNode
  }
}

export default function DownloadBanner({
  title,
  imageProps,
  description,
  linkProps,
}: DownloadBannerProps) {
  return (
    <Box
      width="412px"
      minHeight="391px"
      position="relative"
      p="94px 43px 33px 43px"
      bgcolor="gray.800"
      border="1px solid"
      borderColor="gray.700"
    >
      {imageProps?.src && imageProps?.alt && (
        <Box
          component={Image}
          src={imageProps.src}
          alt={imageProps.alt}
          fill
          // priority
        />
      )}
      <Box sx={{ position: 'absolute', top: '82px', left: '0' }}>
        <Box component={Image} src={PNG_ICONS.patternLinesInfo} alt="pattern-lines-info" />
      </Box>

      <Box position="relative" zIndex={1}>
        <Stack direction="column" spacing="8px" position="relative" height="84px">
          <Typography variant="labelMedium" color="primary.light">
            Install
          </Typography>
          <TitleWithDot
            rootStackProps={{ spacing: '10px' }}
            typographyProps={{ variant: 'h3' }}
            dotProps={{ bottom: '12px' }}
          >
            {title}
          </TitleWithDot>
        </Stack>

        <Box pl="3px" py="18px">
          <CustomHorizontalDivider
            thickLineColor="gray.200"
            thinLineColor="common.white"
            thinLineOpacity={0.1}
          />
        </Box>

        <Box position="relative" mb="31px" height="89px" display="flex" alignItems="center">
          <Typography variant="bodyMedium" color="gray.25">
            {description}
          </Typography>
        </Box>
        <Box position="relative" display="flex">
          {linkProps && (
            <Link
              href={linkProps?.href || '/'}
              startIcon={linkProps?.startIcon || <SvgIcon name="windows-fill" />}
            >
              {linkProps?.children || 'Download for free'}
            </Link>
          )}
        </Box>
      </Box>

      <Box
        position="absolute"
        left={0}
        bottom={0}
        height="240px"
        width="100%"
        sx={{ background: 'linear-gradient(0deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)' }}
      />
    </Box>
  )
}
