import React from 'react'
import { Box, Stack, StackProps as MuiStackProps } from '@mui/material'
import Typography from '../Typography/Typography'
import Switch, { SwitchProps } from 'src/components/Switch/Switch'

type NotificationBlockProps = {
  /** [The root element of this component uses the Stack component from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  title: string
  description: string
  SwitchProps?: SwitchProps
}

export default function NotificationBlock({
  title,
  description,
  StackProps,
  SwitchProps,
}: NotificationBlockProps) {
  const { sx = {}, ...rootStackProps } = StackProps || {}
  return (
    <Stack
      direction="row"
      bgcolor="gray.800"
      border="1px solid"
      borderColor="gray.700"
      padding="14px 13px 13px 14px"
      gap="24px"
      sx={{
        transition: 'border-color 0.3s ease-in-out',
        '&:hover': {
          borderColor: 'gray.500',
        },
        ...sx,
      }}
      {...rootStackProps}
    >
      <Box flexGrow={1}>
        <Typography variant="labelSmall" mb="8px" display="block">
          {title}
        </Typography>
        <Typography variant="bodySmall" color="gray.100">
          {description}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Switch {...SwitchProps} />
      </Box>
    </Stack>
  )
}
