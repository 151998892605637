import React, { useMemo } from 'react'
import { Box, BoxProps } from '@mui/material'
import { motion, useCycle } from 'framer-motion'

export enum Sizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type SizeType = `${Sizes}`

type LoaderProps = BoxProps & {
  size?: SizeType
  outerBoxBorderColor?: string
  innerBoxBgColor?: string
}

type LoaderSizesStyle = {
  outerBox: object
  innerBox: {
    animation1: object
    animation2: object
  }
}

const loaderSizesStyle: { [key: string]: LoaderSizesStyle } = {
  [Sizes.Small]: {
    outerBox: {
      width: '18px',
      height: '18px',
    },
    innerBox: {
      animation1: {
        height: '10px',
        width: '10px',
      },
      animation2: {
        height: '5px',
        width: '5px',
      },
    },
  },
  [Sizes.Medium]: {
    outerBox: {
      width: '26px',
      height: '26px',
    },
    innerBox: {
      animation1: {
        height: '15px',
        width: '15px',
      },
      animation2: {
        height: '10px',
        width: '10px',
      },
    },
  },
  [Sizes.Large]: {
    outerBox: {
      width: '38px',
      height: '38px',
    },
    innerBox: {
      animation1: {
        height: '22.5px',
        width: '22.5px',
      },
      animation2: {
        height: '15px',
        width: '15px',
      },
    },
  },
}

export default function Loader({
  size = Sizes.Medium,
  outerBoxBorderColor = '#4B5873',
  innerBoxBgColor = '#EEA40D',
  ...restRootBoxProps
}: LoaderProps) {
  const [innerBoxAnimation, cycleInnerBoxAnimation] = useCycle(
    'animation1',
    'animation2',
    'animation3'
  )

  const onInnerBoxAnimationComplete = () => {
    cycleInnerBoxAnimation()
  }

  const { outerBox, innerBox } = loaderSizesStyle[size]

  const outerBoxVariants = useMemo(
    () => ({
      initial: {
        inset: 0,
        border: `1px solid`,
        borderColor: outerBoxBorderColor,
      },
      animate: {
        rotate: -90,
        transition: {
          repeat: Infinity,
          delay: 0.8,
          repeatDelay: 0.83,
          duration: 0.3,
          ease: 'easeInOut',
        },
      },
    }),
    [outerBoxBorderColor]
  )

  const innerBoxVariants = useMemo(
    () => ({
      animation1: {
        ...innerBox?.animation1,
        backgroundColor: innerBoxBgColor,
        transition: { ease: 'linear', duration: 0.3 },
      },
      animation2: {
        ...innerBox?.animation2,
        backgroundColor: innerBoxBgColor,
        transition: { delay: 0.2, ease: 'easeInOut', duration: 0.3 },
      },
      animation3: {
        ...innerBox?.animation1,
        backgroundColor: innerBoxBgColor,
        transition: { delay: 0.2, type: 'spring', mass: 1, stiffness: 1897, damping: 26.67 },
      },
    }),
    [innerBox, innerBoxBgColor]
  )

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...outerBox}
      {...restRootBoxProps}
    >
      <Box
        component={motion.div}
        position="absolute"
        variants={outerBoxVariants}
        initial="initial"
        animate="animate"
      />
      <Box
        component={motion.div}
        position="absolute"
        variants={innerBoxVariants}
        initial={innerBoxAnimation}
        animate={innerBoxAnimation}
        onAnimationComplete={onInnerBoxAnimationComplete}
      />
    </Box>
  )
}
