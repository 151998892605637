import { ComponentsVariants, ComponentsOverrides, Theme } from '@mui/material'

const MuiInputLabel: {
  variants: ComponentsVariants['MuiInputLabel']
  styleOverrides: ComponentsOverrides<Theme>['MuiInputLabel']
} = {
  variants: [
    {
      props: { color: 'warning', error: false },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.warning.main,
        '&.Mui-focused': {
          color: theme.palette.warning.main,
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.bodySmall,
      transform: 'scale(1)',
      color: theme.palette.gray[200],
      display: 'flex',
      alignItems: 'flex-end',
      gap: '2px',
      '&::after': {
        content: '""',
        width: '2px',
        height: '2px',
        transform: 'translateY(10px)',
        transition: theme.transitions.create(['transform']),
        backgroundColor: theme.palette.primary.main,
      },
      '&.Mui-focused': {
        color: theme.palette.gray[200],
        '&::after': {
          transform: 'translateY(-3px)',
        },
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
        '&::after': {
          backgroundColor: theme.palette.error.main,
        },
      },
      '&.Mui-warning': {
        color: theme.palette.warning.main,
      },
    }),
  },
}

export default MuiInputLabel
