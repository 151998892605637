import { Box } from "@mui/material"
import { useEffect } from "react"
import PageContainer from "src/components/PageContainer/PageContainer"
import PatternLines from "src/components/PatternLines/PatternLines"
import TitleWithDot from "src/components/TitleWithDot/TitleWithDot"
import { ethers } from 'ethers'
//import { EthersAdapter } from '@safe-global/protocol-kit'

export default function Safe() {

 /*   useEffect(() => {
        console.log("Safe page")
        const web3Provider = (window as any).ethereum;
        const provider = new ethers.BrowserProvider(web3Provider);
        const safeOwner = provider.getSigner(0);

        const ethAdapter = new EthersAdapter({
            ethers: ethers as any, // Import the missing properties from the ethers module
            signerOrProvider: safeOwner as any
        })

    }, [])*/

    return (
        <Box bgcolor="gray.900" position="relative">
            <PageContainer sx={{ pt: '17px' }}
                innerContainerProps={{
                    sx: { position: 'relative' },
                }}
            >
                <PatternLines height="262px" top="9px" />
                <TitleWithDot
                    typographyProps={{ variant: 'h2' }}
                    dotProps={{ bottom: '16px' }}
                    rootStackProps={{ spacing: '10px', mb: '23px' }}
                >
                    Safe
                </TitleWithDot>
            </PageContainer>
        </Box>
    )


}