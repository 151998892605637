import React, { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import { DialogProps, Box, BoxProps } from '@mui/material'
import DialogWrapper from 'src/components/DialogWrapper/DialogWrapper'
import Loader from 'src/components/Loader/Loader'
import Typography from 'src/components/Typography/Typography'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import Button, { ButtonProps } from 'src/components/Button/Button'

enum Status {
  Success = 'success',
  Error = 'error',
}

type StatusType = `${Status}`

type Reason = 'backdropClick' | 'escapeKeyDown' | 'btnClick'

export type JoinLobbyDialogProps = DialogProps & JoinLobbyDialogContentProps

export default function JoinLobbyDialog({
  loading,
  status,
  title,
  subtitle,
  onJoinLobby,
  onClose,
  closable = false,
  showJoinAction = false,
  dialogContentBoxProps,
  dialogContentInnerBoxProps,
  dialogActionBoxProps,
  ...restDialogProps
}: JoinLobbyDialogProps) {
  const theme = useTheme()

  const handleClose = useCallback(
    (event: object, reason: Reason) => {
      if (reason !== 'btnClick' && !closable) {
        return
      }
      if (onClose) {
        onClose(event, reason)
      }
    },
    [closable, onClose]
  )

  return (
    <DialogWrapper
      {...restDialogProps}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          [theme.breakpoints.down('sm')]: {
            margin: '16px',
          },
        },
      }}
    >
      <JoinLobbyDialogContent
        loading={loading}
        status={status}
        title={title}
        subtitle={subtitle}
        showJoinAction={showJoinAction}
        onJoinLobby={onJoinLobby}
        onClose={handleClose}
        dialogContentBoxProps={dialogContentBoxProps}
        dialogContentInnerBoxProps={dialogContentInnerBoxProps}
        dialogActionBoxProps={dialogActionBoxProps}
      />
    </DialogWrapper>
  )
}

type JoinLobbyDialogContentProps = {
  loading?: boolean
  status?: StatusType
  title: string
  subtitle: string
  onJoinLobby?: () => void
  onClose?: (event: object, reason: Reason) => void
  closable?: boolean
  showJoinAction?: boolean
  dialogContentBoxProps?: BoxProps
  dialogContentInnerBoxProps?: BoxProps
  dialogActionBoxProps?: BoxProps
}

export function JoinLobbyDialogContent({
  loading,
  status,
  title,
  subtitle,
  onJoinLobby,
  onClose,
  showJoinAction,
  dialogContentBoxProps,
  dialogContentInnerBoxProps,
  dialogActionBoxProps,
}: JoinLobbyDialogContentProps) {
  const theme = useTheme()
  return (
    <Box
      p="1px"
      border="1px solid"
      borderColor="gray.700"
      minWidth="410px"
      minHeight="238px"
      display="flex"
      flexDirection="column"
      {...dialogContentBoxProps}
      sx={{
        [theme.breakpoints.down('sm')]: {
          minWidth: '343px',
        },
        ...dialogContentBoxProps?.sx,
      }}
    >
      <Box
        minHeight="234px"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        {...dialogContentInnerBoxProps}
      >
        <Box
          p="24px"
          bgcolor="gray.800"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {loading && (
            <Box mb="35px">
              <Loader size="large" />
            </Box>
          )}
          {status === Status.Success && (
            <Box fontSize="44px" mb="28px" display="flex">
              <SvgIcon name="alert-success" color="success" fontSize="inherit" />
            </Box>
          )}
          {status === Status.Error && (
            <Box fontSize="44px" mb="28px" display="flex">
              <SvgIcon name="alert-error" color="error" fontSize="inherit" />
            </Box>
          )}
          <Typography variant="h5" color="gray.0">
            {title}
          </Typography>
          <Typography mt="10px" variant="bodySmall" color="gray.25" textAlign="center">
            {subtitle}
          </Typography>
        </Box>
      </Box>
      {status === Status.Success && showJoinAction && (
        <JoinLobbyDialogAction
          iconName="arrow-right-line"
          label="Enter lobby"
          {...dialogActionBoxProps}
          onClick={onJoinLobby}
        />
      )}
      {status === Status.Error && (
        <JoinLobbyDialogAction
          iconName="close-circle-line"
          label="Close window"
          {...dialogActionBoxProps}
          onClick={onClose ? (event) => onClose(event, 'btnClick') : () => {}}
        />
      )}
    </Box>
  )
}

type JoinLobbyDialogActionProps = {
  iconName: SvgIconProps['name']
  label: string
  onClick: ButtonProps['onClick']
}

function JoinLobbyDialogAction({
  iconName,
  label,
  onClick,
  ...restRootBoxProps
}: JoinLobbyDialogActionProps) {
  return (
    <Box mt="2px" {...restRootBoxProps}>
      <Button
        fullWidth
        size="large"
        variant="spaceBetween"
        endIcon={<SvgIcon name={iconName} />}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  )
}
