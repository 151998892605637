import { ComponentsOverrides, Theme } from '@mui/material'
const MuiAlertTitle: {
  styleOverrides: ComponentsOverrides<Theme>['MuiAlertTitle']
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.paragraphMedium,
      color: theme.palette.gray[25],
    }),
  },
}

export default MuiAlertTitle
