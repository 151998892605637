import React, { useState, useMemo } from 'react'
import assets from 'src/assets'
import { createCtx } from './helpers'

const STATUS = {
  waitingOpponent: 'waitingOpponent',
  isCommenting: 'isCommenting',
  inProgress: 'inProgress',
  calculatingWinner: 'calculatingWinner',
  isCompleted: 'isCompleted',
  isCancelled: 'isCancelled',
} as const

const STATUS_TITLE: {
  [key in keyof typeof STATUS]: string
} = {
  [STATUS.waitingOpponent]: 'Waiting for an opponent',
  [STATUS.isCommenting]: 'Challenge is commencing',
  [STATUS.inProgress]: 'Challenge is in progress',
  [STATUS.calculatingWinner]: 'Challenge is completed Calculating winner',
  [STATUS.isCompleted]: 'Challenge is completed!',
  [STATUS.isCancelled]: 'Challenge has been cancelled',
}

const STATUS_BACKGROUND: {
  [key in keyof typeof STATUS]: string
} = {
  [STATUS.waitingOpponent]: assets.video.lobbySolo,
  [STATUS.isCommenting]: assets.video.lobbyFilled,
  [STATUS.inProgress]: assets.video.lobbyFilled,
  [STATUS.calculatingWinner]: assets.video.lobbyFilled,
  [STATUS.isCompleted]: assets.video.lobbyFilled,
  [STATUS.isCancelled]: assets.video.lobbyFilled,
}

const STATUS_LOADER: {
  [key in keyof typeof STATUS]: boolean
} = {
  [STATUS.waitingOpponent]: true,
  [STATUS.isCommenting]: false,
  [STATUS.inProgress]: false,
  [STATUS.calculatingWinner]: true,
  [STATUS.isCompleted]: false,
  [STATUS.isCancelled]: false,
}

type LobbyContextType = {
  status: keyof typeof STATUS
  statuses: typeof STATUS
  title: string
  background: string
  loader: boolean
  changeStatus: (status: LobbyContextType['status']) => void
}

const [useLobbyContext, LobbyContextProvider] = createCtx<LobbyContextType>()

export { useLobbyContext }

export default function LobbyProvider({ children }: React.PropsWithChildren<unknown>) {
  const [currentStatus, setCurrentStatus] = useState<LobbyContextType['status']>(
    STATUS.waitingOpponent
  )
  const changeStatus = (status: LobbyContextType['status']) => {
    setCurrentStatus(status)
  }
  const LobbyValue = useMemo(
    () => ({
      status: currentStatus,
      statuses: STATUS,
      changeStatus,
      title: STATUS_TITLE[currentStatus],
      background: STATUS_BACKGROUND[currentStatus],
      loader: STATUS_LOADER[currentStatus],
    }),
    [currentStatus]
  )
  return <LobbyContextProvider value={LobbyValue}>{children}</LobbyContextProvider>
}
