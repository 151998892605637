
export function localeDateTimeFormat(date: Date|undefined) {
    if(date===undefined) return "";
    
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(played)

    return formated;
}

export function localeDateFormat(date: Date|undefined) {
    if(date===undefined) return "";
    
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(played)

    return formated;
}

export function formatUnixTimestampToDateTime(unixTimestamp: number|string): string {
    if(unixTimestamp===undefined) return "";
    // Convert the Unix timestamp to a number if it's a string
    const timestampNumber = Number(unixTimestamp);

    // Create a Date object from the Unix timestamp (multiplied by 1000 to convert seconds to milliseconds)
    const date = new Date(timestampNumber * 1000);
  
    // Use Intl.DateTimeFormat to format the date and time in a local format
    // You can customize the 'options' object to change the format as needed
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format, set to true for AM/PM format
    };
  
    // Format the date using the user's locale and the options defined above
    const formattedDateTime = new Intl.DateTimeFormat('default', options).format(date);
  
    return formattedDateTime;
  }

export function getTimeBetweenDatesText(startTime:Date, endTime:Date){ 

    const diffInMilliseconds = endTime.getTime() - startTime.getTime();
    const diffInHours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);
    const diffInMinutes = Math.floor((diffInMilliseconds / 1000 / 60) % 60);
  
    return ` ${diffInHours} hours and ${diffInMinutes} minutes `;
}

export function getTimeFromSeconds(runningTime:number){
    const diffInMilliseconds = runningTime * 1000;
    const diffInHours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);
    const diffInMinutes = Math.floor((diffInMilliseconds / 1000 / 60) % 60);
  
    return ` ${diffInHours} hours and ${diffInMinutes} minutes `;
}

export function isUpdateTime(timestampLastUpdated: number|undefined, requiredDifferenceInSec: number): boolean {

    if(timestampLastUpdated===undefined) return true;

    // Get the current time in milliseconds
    const now = Date.now();
    
    // Calculate the time for requiredDifferenceInSec seconds ago in milliseconds
    const secondsAgo = now - (requiredDifferenceInSec * 1000);
    
    // Check if the given timestamp is less than the time for requiredDifferenceInSec seconds ago
    return timestampLastUpdated < secondsAgo;
  }

 export function formatDuration(durationInSeconds: number): string {
    const days = Math.floor(durationInSeconds / (3600 * 24));
    const hours = Math.floor((durationInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
  
    // Build an array of the components that are not zero
    const parts = [
      days ? `${days} day${days > 1 ? 's' : ''}` : '',
      hours ? `${hours} hour${hours > 1 ? 's' : ''}` : '',
      minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '',
      seconds ? `${seconds} second${seconds > 1 ? 's' : ''}` : '',
    ].filter(part => part !== '');
  
    // Join the components into a string
    return parts.length > 0 ? parts.join(', ') : '0 seconds';
  }

  export function isTimestampGreaterThanCurrentLocalTime(unixTimestampInSeconds: number|string|undefined): boolean {
    if(unixTimestampInSeconds===undefined) return false;
    //check if unixTimestampInSeconds is number or string
    if(typeof unixTimestampInSeconds === 'string') {
      unixTimestampInSeconds = parseInt(unixTimestampInSeconds);
    }    
    if(unixTimestampInSeconds===0) 
      return false;

    // Get the current local time in seconds
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    // Compare the given Unix timestamp with the current local time
    return unixTimestampInSeconds > currentTimeInSeconds;
  }