import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { FormikHelpers } from 'formik'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import assets from 'src/assets'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Comment from 'src/components/Comment/Comment'
import ProfileMenu from 'src/components/ProfileMenu/ProfileMenu'
import LoginInfoForm from 'src/components/LoginInfoForm/LoginInfoForm'
import WalletInfo from 'src/components/WalletInfo/WalletInfo'
import LinkedAccounts, {
  AccountOptions,
  InitialValues as LinkedAccountsInitialValues,
} from 'src/components/LinkedAccounts/LinkedAccounts'
import DownloadBanner from 'src/components/DownloadBanner/DownloadBanner'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import SettingsNotifications from 'src/components/SettingsNotifications/SettingsNotifications'
import TableHistoryProfile from 'src/components/TableHistoryProfile/TableHistoryProfile'
import ProfileChallengesBox from 'src/components/ProfileChallengesBox/ProfileChallengesBox'
import ProfileHotStreakBox from 'src/components/ProfileHotStreakBox/ProfileHotStreakBox'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import { showErrorToast} from "src/components/Toast/Toasts";
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { filteredStreaks } from "src/selectors/userStreakSelector";
import { accountMapper, gamesPlayedMapper } from 'src/helpers/data';
import { GameId } from 'src/utils/const';
import { StreakType, TokenType } from 'src/helpers/types'
import { getUser,getMatchesPlayed } from 'src/actions/adminActions';
import { formatTokenToString } from 'src/utils/tokenUtil';

export default function Profile() {
  const dispatch =  useAppDispatch();
  const { userId }  = useParams<{ userId: string }>()
 
  const [selectedProfileMenuItemId, setSelectedProfileMenuItemId] = useState('statistics');
  const [pwd, setPwd] = useState('');

  


 
  const user = useAppSelector(state => state.adminReducer.user.user);
  

  const filteredStreaksMemo = useMemo(() => filteredStreaks, []);
  const streaks = useAppSelector(state => filteredStreaksMemo(state, GameId.FRONTLAND, StreakType.ALLTIME));

  const gamesPlayed = useAppSelector(state => state.adminReducer.user.matchesPlayed);
  const profileHistoryTable =  gamesPlayedMapper(gamesPlayed);


  const accounts = accountMapper(user?.connectedGames,false);
  const linkedAccountOptions: AccountOptions = useMemo(
    () => accounts.notConnected,
    [accounts]
  );
  const linkedAccountsInitialValues: LinkedAccountsInitialValues = useMemo(
    () => ({
      accounts: accounts.connected,
    }),
    [accounts]
  )
 

  const profileHotStreakBoxData = [
    { title: 'Latest streak', value: streaks.streak, width: '50%' },
    { title: 'Best streak', value: streaks.bestStreak, width: '50%' },
    //{ title: 'Win streak', value: 21 },
  ]

  useEffect(() => {
    dispatch(getUser(userId as string))

  },[dispatch])

  useEffect(() => {    
    dispatch(getMatchesPlayed(userId))    
},[dispatch])


  const onMenuItemClick = useCallback((id: string) => {
    setSelectedProfileMenuItemId(id)
  }, [])

  const onLogout = () => {
  
  }

  const loginInfoFormInitialValues = useMemo(
    () => ({
      username: user?.nickname,
      password: pwd,
    }),
    [user, pwd]
  )

  const loginInfoFormOnSubmit = useCallback(
    async (
      values: typeof loginInfoFormInitialValues,
      formikHelpers: FormikHelpers<typeof loginInfoFormInitialValues>
    ) => {
      // console.log(values, formikHelpers)
      // some async logic to await and then save
      formikHelpers.setSubmitting(false)
    },
    []
  )
  

  const linkedAccountsOnSubmit = useCallback(
    async (
      values: LinkedAccountsInitialValues,
      formikHelpers: FormikHelpers<LinkedAccountsInitialValues>
    ) => {
      // console.log(values, formikHelpers)
      // some async logic to await and then save
      formikHelpers.setSubmitting(false)
    },
    []
  )

  const onConnect = (options:AccountOptions[0]) => {
    switch(options.id){
      case 'epicgames':
        break;
    }
  }


  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box bgcolor="gray.900" position="relative">
      <PageContainer
        sx={{
          pt: '17px',
          [theme.breakpoints.down('sm')]: {
            pt: '37px',
          },
        }}
        innerContainerProps={{
          sx: { position: 'relative' },
        }}
      >
        <PatternLines
          sx={{
            height: '262px',
            top: '9px',
            [theme.breakpoints.down('lg4')]: {
              ml: 'calc(-100vw - 12px)',
              height: '238px',
            },
            [theme.breakpoints.down('sm')]: {
              height: '66px',
              ml: 'calc(-100vw - 8px)',
              top: -10,
            },
          }}
        />
        <TitleWithDot
          typographyProps={{ variant: isMobileView ? 'h3' : 'h2' }}
          dotProps={{ bottom: { xs: '12px', sm: '16px' } }}
          rootStackProps={{ spacing: { xs: '6px', sm: '10px' }, mb: { xs: '20px', sm: '24px' } }}
        >
          User profile
        </TitleWithDot>
        <Box mb={{ xs: '31px', sm: '38px', md: '68px' }}>
          <Grid
            container
            direction={{ xs: 'column', sm2: 'row' }}
            spacing={{ xs: '20px', sm: '28px' }}
          >
            <Grid item>
              <Box mt="1px">
                <Comment>profile menu</Comment>
                <ProfileMenu
                  UploadAvatarProps={{
                    name: user?.nickname,
                  }}
                  MenuItemButtonListProps={{
                    selectedId: selectedProfileMenuItemId,
                    onMenuItemClick,
                  }}
                  onLogout={onLogout}
                />
              </Box>
            </Grid>
            {selectedProfileMenuItemId === 'statistics' && (
              <Grid item xs md2={6} minWidth={{ xs: 'auto', md2: '658px' }}>
                <Box width="100%">
                  <Comment rootStackProps={{ mb: '1px' }}>Your stats</Comment>
                  <ProfileChallengesBox data={{ title: "Games",  
                          subTitle:"Total challenges",
                          totalGames: user?.statistics.overal.totalGamesPlayed,
                          winGames: user?.statistics.overal.totalGamesWon,
                          rows: [
                            {
                              title: 'This month',
                              value: user?.statistics.currentMonth.totalGamesPlayed,
                            },
                       
                        ],
                      }}
                  />
                  <ProfileHotStreakBox title="Your hot streaks" data={profileHotStreakBoxData} />

                  <TableWrapper
                    title="Match history"
                    width="100%"
                    height="auto"
                    minHeight={{ xs: '520px', sm2: '522px', md: '527px' }}
                    mt={{ xs: '16px', sm: '18px', md: '28px' }}
                    p="1px 9px 0"
                    bodyStackProps={{
                      paddingTop: '17px',
                      paddingLeft: '8px',
                      paddingRight: '9px',
                      paddingBottom: '17px',
                    }}
                    TitleDotProps={{ bottom: { xs: '7px', sm: '8px' } }}
                  >
                    <TableHistoryProfile {...profileHistoryTable} /> 
                  </TableWrapper>
                </Box>
              </Grid>
            )}
            {(selectedProfileMenuItemId === 'loginInfo' || selectedProfileMenuItemId === 'myassets') && (
              <Grid item xs>
                <Box maxWidth={{ xs: 'auto', sm: '100%', md: '576px', lg: '629px' }}>
                  {selectedProfileMenuItemId === 'loginInfo' &&
                    <Comment>account info</Comment>
                  }
                  {selectedProfileMenuItemId === 'myassets' &&
                    <Comment>assets</Comment>
                  }
                  <Box width="100%" mt="1.8px" border="1px solid" borderColor="gray.700">
                    {selectedProfileMenuItemId === 'loginInfo' &&
                      <LoginInfoForm
                        initialValues={loginInfoFormInitialValues}
                        onSubmit={loginInfoFormOnSubmit}
                      />
                    }                    
                    <WalletInfo
                      address={user?.username}
                      isEmbedded={user.loginType === 'embedded'}
                      tokenInfo={[
                        {
                          total: formatTokenToString(user?.coins?.eth, TokenType.ETH),
                          label: TokenType.ETH,
                          icon: assets.png.eth,
                          isWithDrawDisabled: false,
                          isBuyDisabled: true,
                        },
                        {
                          total: formatTokenToString(user?.coins?.weth, TokenType.WETH),
                          label: TokenType.WETH,
                          icon: assets.png.weth,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                        },
                        {
                          total: formatTokenToString(user?.coins?.challengeCoins, TokenType.CT),
                          label: TokenType.CT,
                          icon: assets.png.coin,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                        },
                      ]} 
                      setIsRevealOpen={function (value: boolean): void {
                        throw new Error('Function not implemented.');
                      } }                      
                 

                    />
                    {selectedProfileMenuItemId === 'loginInfo' &&
                      <LinkedAccounts
                        accountOptions={linkedAccountOptions}
                        initialValues={linkedAccountsInitialValues}
                        onSubmit={linkedAccountsOnSubmit}
                        onAccountOptionClick={(option)=> onConnect(option)}
                      /> 
                    }
                  </Box>
                </Box>
              </Grid>
            )}
            {selectedProfileMenuItemId === 'other' && (
              <>
                <Grid item xs display="flex" flexDirection="column">
                  <Comment>Settings</Comment>
                  <SettingsNotifications />
                </Grid>
                <Grid item display={{ xs: 'none', lg: 'block' }}>
                  <Box mt="17.2px">
                    <DownloadBanner
                      description="Description of Game, some presentation text for it and some more, bla bla bla Description of Game, some presentation text for it and some more, bla bla bla"
                      imageProps={{
                        alt: 'Game banner',
                        src: assets.png.bgAvailable,
                      }}
                      linkProps={{
                        children: 'Download for free',
                        href: '/',
                        startIcon: <SvgIcon name="windows-fill" />,
                      }}
                      title="Frontland"
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </PageContainer>
    </Box>
  )
}

