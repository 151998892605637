import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { FormikHelpers } from 'formik'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import assets from 'src/assets'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import Comment from 'src/components/Comment/Comment'
import ProfileMenu from 'src/components/ProfileMenu/ProfileMenu'
import LoginInfoForm from 'src/components/LoginInfoForm/LoginInfoForm'
import WalletInfo from 'src/components/WalletInfo/WalletInfo'
import DownloadBanner from 'src/components/DownloadBanner/DownloadBanner'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import SettingsNotifications from 'src/components/SettingsNotifications/SettingsNotifications'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { logout} from "src/actions/userActions";
import { getUser } from 'src/actions/adminActions'
import { TokenType } from 'src/helpers/types'
import { formatTokenToString } from 'src/utils/tokenUtil'
import { Token } from '@mui/icons-material'


export default function Profile() {
  const dispatch =  useAppDispatch();

  const [selectedProfileMenuItemId, setSelectedProfileMenuItemId] = useState('loginInfo' );
  const [pwd, setPwd] = useState('');
 
  const me = useAppSelector(state => state.userReducer.userInfo);

  useEffect(() => {
    dispatch(getUser(me.id as string))

  },[dispatch])
  
  const user = useAppSelector(state => state.adminReducer.user.user);


  const onMenuItemClick = useCallback((id: string) => {
    setSelectedProfileMenuItemId(id)
  }, [])

  const onLogout = () => {
    dispatch(logout());
  }

  const loginInfoFormInitialValues = useMemo(
    () => ({
      username: user?.nickname,
      password: pwd,
    }),
    [user,pwd]
  )

  const loginInfoFormOnSubmit = useCallback(
    async (
      values: typeof loginInfoFormInitialValues,
      formikHelpers: FormikHelpers<typeof loginInfoFormInitialValues>
    ) => {
      // console.log(values, formikHelpers)
      // some async logic to await and then save
      formikHelpers.setSubmitting(false)
    },
    []
  )

  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box bgcolor="gray.900" position="relative">
      <PageContainer
        sx={{
          pt: '17px',
          [theme.breakpoints.down('sm')]: {
            pt: '37px',
          },
        }}
        innerContainerProps={{
          sx: { position: 'relative' },
        }}
      >
        <PatternLines
          sx={{
            height: '262px',
            top: '9px',
            [theme.breakpoints.down('lg4')]: {
              ml: 'calc(-100vw - 12px)',
              height: '238px',
            },
            [theme.breakpoints.down('sm')]: {
              height: '66px',
              ml: 'calc(-100vw - 8px)',
              top: -10,
            },
          }}
        />
        <TitleWithDot
          typographyProps={{ variant: isMobileView ? 'h3' : 'h2' }}
          dotProps={{ bottom: { xs: '12px', sm: '16px' } }}
          rootStackProps={{ spacing: { xs: '6px', sm: '10px' }, mb: { xs: '20px', sm: '24px' } }}
        >
          your profile
        </TitleWithDot>
        <Box mb={{ xs: '31px', sm: '38px', md: '68px' }}>
          <Grid
            container
            direction={{ xs: 'column', sm2: 'row' }}
            spacing={{ xs: '20px', sm: '28px' }}
          >
            <Grid item>
              <Box mt="1px">
                <Comment>profile menu</Comment>
                <ProfileMenu
                  UploadAvatarProps={{
                    name: user?.nickname,
                  }}
                  MenuItemButtonListProps={{
                    selectedId: selectedProfileMenuItemId,
                    onMenuItemClick,
                  }}
                  onLogout={onLogout}
                />
              </Box>
            </Grid>
           
            {selectedProfileMenuItemId === 'loginInfo' && (
              <Grid item xs>
                <Box maxWidth={{ xs: 'auto', sm: '100%', md: '576px', lg: '629px' }}>
                  <Comment>account info</Comment>
                  <Box width="100%" mt="1.8px" border="1px solid" borderColor="gray.700">
                    <LoginInfoForm
                      initialValues={loginInfoFormInitialValues}
                      onSubmit={loginInfoFormOnSubmit}
                    />
                    <WalletInfo
                      address={user?.username}
                      tokenInfo={[
                        {
                          total: formatTokenToString(user?.coins?.eth, TokenType.ETH),
                          label: TokenType.ETH,
                          icon: assets.png.eth,
                          isWithDrawDisabled: false,
                          isBuyDisabled: true,
                        },
                        {
                          total: formatTokenToString(user?.coins?.weth, TokenType.WETH),
                          label: TokenType.WETH,
                          icon: assets.png.weth,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                        },
                        {
                          total: formatTokenToString(user?.coins?.challengeCoins, TokenType.CT),
                          label: TokenType.CT,
                          icon: assets.png.coin,
                          isWithDrawDisabled: true,
                          isBuyDisabled: true,
                        },
                      ]}  isEmbedded={false} setIsRevealOpen={function (value: boolean): void {
                        throw new Error('Function not implemented.')
                      } }                      
                 

                    />
                  
                  </Box>
                </Box>
              </Grid>
            )}
            {selectedProfileMenuItemId === 'other' && (
              <>
                <Grid item xs display="flex" flexDirection="column">
                  <Comment>Settings</Comment>
                  <SettingsNotifications />
                </Grid>
                <Grid item display={{ xs: 'none', lg: 'block' }}>
                  <Box mt="17.2px">
                    <DownloadBanner
                      description="Description of Game, some presentation text for it and some more, bla bla bla Description of Game, some presentation text for it and some more, bla bla bla"
                      imageProps={{
                        alt: 'Game banner',
                        src: assets.png.bgAvailable,
                      }}
                      linkProps={{
                        children: 'Download for free',
                        href: '/',
                        startIcon: <SvgIcon name="windows-fill" />,
                      }}
                      title="Frontland"
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </PageContainer>
    </Box>
  )
}

