import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from 'src/components/PageContainer/PageContainer';
import Box from '@mui/material/Box';
import PatternLines from 'src/components/PatternLines/PatternLines';
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot';
import { Container, Grid, Stack, useTheme } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'src/hooks';
import { getUsers, filterUsers } from 'src/actions/adminActions';
import { DataGrid, GridActionsCellItem, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridPaginationModel, GridRenderCellParams, GridRenderEditCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from 'src/components/TextField/TextField';
import Typography from 'src/components/Typography/Typography';
import { UserAdminType, UserType } from 'src/helpers/types';
import { useNavigate } from 'react-router';
import { Filter } from './filter';


function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
  
    return <TextField style={{marginTop:'14px'}} size="small" value={value} onChange={handleValueChange} />;

  }
  

export default function Users() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
  

    const users = useAppSelector(state => state.adminReducer.users);
    

    const userRows = useMemo(() => {
      return users.users.results!==undefined ? users.users.results.map((user: UserAdminType) => {
        return {
          id: user.id,
          nickname: user.nickname,
          publicAddress: user.publicAddress,
          created: user.created,
          epicDisplayName: user.epicDisplayName,
          playFabDisplayName: user.playFabDisplayName,


        }
  
      } ) : []
    }, [users])

    const isLoading = useMemo(() => {
        return users.isLoading;
    }, [users.isLoading])

    const [filter, setFilter] = React.useState<undefined|string>(undefined);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
      });
    
    useEffect(() => {
        if(users.users?.totalNumberOfRecords!==undefined)
            setRowCountState(users.users.totalNumberOfRecords);
    }, [users]);

    useEffect(() => {
        dispatch(filterUsers(filter, 1 , 10));
    }, [dispatch])

    

    const columns: GridColDef[] = [
        {
            field: 'nickname',
            headerName: 'Nickname',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Nickname</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'publicAddress',
            headerName: 'Address',
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Address</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'epicDisplayName',
            headerName: 'Epic Name',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Epic Name</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'playFabDisplayName',
            headerName: 'PlayFab Name',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">PlayFab Name</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'created',
            headerName: 'Joined',
            type: 'string',   
            width: 150,
            valueGetter: (params) => {
                if (!params.value) {
                  return params.value;
                }
                // Convert to date
                const dateAsString = params.value;
                const result = new Date(dateAsString).toLocaleDateString();
                return result;
              },
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Created</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            renderHeader: (params: GridColumnHeaderParams) => {
                return <Typography variant="labelSmall">Actions</Typography>
              },
            getActions: ({ id }) => {
             
                function handleEditClick(id: GridRowId): void {
                    navigate(`/users/${id}`);
                }

              return [
                <GridActionsCellItem
                  icon={<ArrowForwardIcon />}
                  label="View"
                  className="textPrimary"
                  onClick={() => handleEditClick(id)}
                  color="inherit"
                />,
               
              ];
            }
          }
    ]

    function fetchData(model: GridPaginationModel, details: GridCallbackDetails<any>): void {
        dispatch(filterUsers(filter, model.page+1 , model.pageSize));
        setPaginationModel(model);
    }

    return (
        <Box bgcolor="gray.900" position="relative" marginBottom="10px">
            <PageContainer sx={{ pt: '17px' }}
                innerContainerProps={{
                    sx: { position: 'relative' },
                }}
            >
                <PatternLines height="262px" top="9px" />
                <TitleWithDot
                    typographyProps={{ variant: 'h2' }}
                    dotProps={{ bottom: '16px' }}
                    rootStackProps={{ spacing: '10px', mb: '23px' }}
                >
                    Users
                </TitleWithDot>
               <Filter onFilterChange={(filter:string) => {
                        setFilter(filter); 
                        dispatch(filterUsers(filter,paginationModel.page+1,paginationModel.pageSize))
                        }} />
                <DataGrid
                    disableColumnMenu rows={userRows}
                    columns={columns}
                    loading={isLoading}
                    pageSizeOptions={[10, 25, 50]}
                    paginationMode="server"
                    rowCount={rowCountState}
                    paginationModel={paginationModel}
                    onPaginationModelChange={fetchData}
                    sx={{
                        height: '650px',
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                        },
                    }}
            
        />
            </PageContainer>
        </Box>
    );
}