import { alpha, PaletteMode } from '@mui/material'
import { blueGrey, cyan } from '@mui/material/colors'

const orange = '#EEA40D'
const yellow = '#FFD200'
const error = '#FD4438'

const success = '#56E612'
const success200 = 'rgba(86, 230, 18, 0.2)'

const gray0 = '#FDFDFD'
const gray25 = '#DDE1E8'
const gray50 = '#C2C7D1'
const gray100 = '#A9AFBE'
const gray200 = '#8D96A8'
const gray300 = '#768095'
const gray400 = '#62708D'
const gray500 = '#4B5873'
const gray600 = '#313D54'
const gray700 = '#1E283A'
const gray800 = '#121A2A'
const gray900 = '#040811'

const palette = {
  mode: 'dark' as PaletteMode,
  primary: {
    main: orange,
    light: yellow,
    contrastText: gray900,
  },
  secondary: {
    main: cyan['A400'],
  },
  success: {
    main: success,
    200: success200,
  },
  error: {
    main: error,
  },
  warning: {
    main: orange,
    contrastText: gray900,
  },
  gray: {
    0: gray0,
    25: gray25,
    50: gray50,
    100: gray100,
    200: gray200,
    300: gray300,
    400: gray400,
    500: gray500,
    600: gray600,
    700: gray700,
    800: gray800,
    900: gray900,
  },
  background: {
    default: blueGrey['100'],
    // paper: blueGrey['700'],
    notificationError: `radial-gradient(15.63% 67.42% at 7.37% 35.96%, ${alpha(
      error,
      0.15
    )} 0%, ${alpha(error, 0)} 100%), ${gray800}`,
    notificationWarning: `radial-gradient(15.63% 67.42% at 7.37% 35.96%, ${alpha(
      orange,
      0.15
    )} 0%, ${alpha(orange, 0)} 100%), ${gray800}`,
    notificationSuccess: `radial-gradient(15.63% 67.42% at 7.37% 35.96%, ${alpha(
      success,
      0.15
    )} 0%, ${alpha(success, 0)} 100%), ${gray800}`,
    lines: `repeating-linear-gradient(40deg, transparent, transparent 4px, ${gray800} 4px, ${gray800} 8px)`,
    linesFooter: `repeating-linear-gradient(30deg, transparent, transparent 2px, ${gray800} 3px, ${gray800} 5px)`,
    gameBanner: `linear-gradient(270deg, ${gray900} 0%, rgba(4, 8, 17, 0) 100%)`,
    gameBanner2: 'linear-gradient(270deg, rgba(4, 8, 17, 0.5) 0%, rgba(4, 8, 17, 0) 100%)',
    winner1stPlace: `radial-gradient(78.16% 51.09% at 50.36% 97.28%, rgba(238, 164, 13, 0.15) 0%, rgba(238, 164, 13, 0) 100%), ${gray800}`,
    winner2ndPlace: `radial-gradient(78.16% 51.09% at 50.36% 97.28%, rgba(169, 175, 190, 0.15) 0%, rgba(169, 175, 190, 0) 100%), ${gray800}`,
    winner3rdPlace: `radial-gradient(78.16% 51.09% at 50.36% 97.28%, rgba(198, 128, 64, 0.15) 0%, rgba(198, 128, 64, 0) 100%), ${gray800}`,
  },
}

export default palette
