import { History } from "history";
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { reducer as formReducer } from 'redux-form'
import { adminReducer } from './adminReducer';
import { challengeReducer } from './challengeReducer';
import { userReducer } from './userReducer';
import { dialogReducer } from './dialogReducer';
import { infoPanelReducer } from './infoPanelReducer';
import { apiStakingSlice } from "../api/stakingApi";

export const rootReducer = (history : History) => ({
    
    router: createRouterReducer(history),
    userReducer,
    challengeReducer,
    adminReducer,
    dialogReducer,
    form: formReducer,
    infoPanelReducer,
    [apiStakingSlice.reducerPath]: apiStakingSlice.reducer, 

       
});

