import React from 'react'
import { Outlet } from 'react-router-dom'
import ThemeProvider from 'src/components/ThemeProvider/ThemeProvider'
import { ContextProvider } from 'src/context/context'
import 'react-toastify/dist/ReactToastify.min.css';

export default function RootLayout() {
  return (
    <ThemeProvider>
      <ContextProvider>
        <Outlet />
      </ContextProvider>
    </ThemeProvider>
  )
}
