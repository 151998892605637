import React, { useCallback, useMemo, useState } from 'react'
import { Box, Collapse, BoxProps } from '@mui/material'
// import ClickAwayListener from '@mui/base/ClickAwayListener'
import MenuItemButton from 'src/components/MenuItemButton/MenuItemButton'
import Image from 'src/components/Image/Image'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { GameSidebarProps } from 'src/components/GameSidebar/GameSidebar'

type MobileGameSidebarProps = GameSidebarProps & {
  onToggle?: (open: boolean) => void
  shadowBoxProps?: BoxProps
}

export default function MobileGameSidebar({
  list,
  selectedId,
  onSelect,
  onToggle,
  shadowBoxProps,
}: MobileGameSidebarProps) {
  const [open, setOpen] = useState(false)

  const onMenuToggle = () => {
    setOpen((prevState) => {
      if (onToggle) {
        onToggle(!prevState)
      }
      return !prevState
    })
  }

  const onMenuClose = useCallback(() => {
    setOpen(false)
    if (onToggle) {
      onToggle(false)
    }
  }, [onToggle])

  const onMenuItemClick = useCallback(
    (id: string) => () => {
      if (onSelect) {
        onSelect(id)
      }
      if (open) {
        onMenuClose()
      }
    },
    [onMenuClose, onSelect, open]
  )

  const selectedMenuItem = useMemo(
    () => list.find((item) => item.id === selectedId),
    [list, selectedId]
  )

  const restMenuList = useMemo(
    () => list.filter((item) => item.id !== selectedId),
    [list, selectedId]
  )

  return (
    <>
      <Box display="flex" position="relative" minHeight="50px">
        <Box
          bgcolor="gray.900"
          border="1px solid"
          borderColor="gray.700"
          display="flex"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          //zIndex={(theme) => theme.zIndex.fab}
          zIndex={10}
        >
          <Box width="100%">
            {selectedMenuItem && (
              <MenuItemButton
                selected={selectedMenuItem.id === selectedId}
                startIcon={
                  selectedMenuItem?.startIcon ?? (
                    <Image src={selectedMenuItem.icon} alt={selectedMenuItem.name} />
                  )
                }
                isComingSoon={selectedMenuItem.isComingSoon}
                onClick={(event) => event.stopPropagation()}
              >
                {selectedMenuItem.name || selectedMenuItem.label}
              </MenuItemButton>
            )}
            {/*<ClickAwayListener onClickAway={onMenuClose}>*/}
            <Collapse in={open} role="presentation">
              {restMenuList.map(({ id, name, label, startIcon, icon, isComingSoon, disabled }) => (
                <MenuItemButton
                  key={id}
                  selected={id === selectedId}
                  startIcon={startIcon ?? <Image src={icon} alt={name} />}
                  isComingSoon={isComingSoon}
                  onClick={onMenuItemClick(id)}
                  disabled={disabled}
                >
                  {name || label}
                </MenuItemButton>
              ))}
            </Collapse>
            {/*</ClickAwayListener>*/}
          </Box>
          <Box p="2px">
            <ButtonIcon variant="outlined" size="small" onClick={onMenuToggle}>
              <SvgIcon name={open ? 'close-fill' : 'menu-4-line'} />
            </ButtonIcon>
          </Box>
        </Box>
      </Box>

      {open && (
        <Box
          bgcolor="gray.900"
          {...shadowBoxProps}
          sx={{
            opacity: '0.84',
            position: 'absolute',
            top: 145, // 190
            left: -16,
            right: -16,
            bottom: 0,
            zIndex: 2,
            ...shadowBoxProps?.sx,
          }}
        />
      )}
    </>
  )
}
