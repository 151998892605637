import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps as MuiBoxProps } from '@mui/material'
import Typography from 'src/components/Typography/Typography'

export type OnlineBlockProps = {
  /** [The root element of this component uses the Box component from Material UI](https://mui.com/material-ui/react-box/) */
  BoxProps?: MuiBoxProps
  isOnline: boolean
}

const OnlineBlock = ({ isOnline, BoxProps }: OnlineBlockProps) => {
  const theme = useTheme()
  const isLessThan720 = useMediaQuery(theme.breakpoints.down('md720'))

  return (
    <Box
      {...BoxProps}
      sx={{
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        p: '1px 7px',
        width: 'min-content',
        height: '20px',
        bgcolor: 'gray.800',
        border: '1px solid',
        borderColor: 'gray.700',
        [theme.breakpoints.down('md720')]: {
          p: '1px 5px',
          height: '16px',
        },
        ...BoxProps?.sx,
      }}
    >
      <Box
        sx={{
          width: '6px',
          height: '6px',
          bgcolor: isOnline ? 'gray.500' : 'primary.main',
          order: 1,
          [theme.breakpoints.down('md720')]: {
            order: 3,
          },
        }}
      />
      <Box
        sx={{
          width: '1px',
          height: '12px',
          bgcolor: 'gray.600',
          order: 2,
        }}
      />
      <Typography
        sx={{
          width: 'max-content',
          color: isOnline ? 'gray.300' : 'primary.light',
          order: 3,
          [theme.breakpoints.down('md720')]: {
            order: 1,
          },
        }}
        variant={isLessThan720 ? 'description' : 'paragraphSmall'}
      >
        {isOnline ? 'Online' : 'In lobby'}
      </Typography>
    </Box>
  )
}

export default OnlineBlock
