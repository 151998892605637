import React from 'react'
import { Box, Stack, BoxProps as MuiBoxProps } from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import NotificationBlock from 'src/components/NotificationBlock/NotificationBlock'

type SettingsNotificationsProps = {
  /** [The root element of this component uses the Box component from Material UI](https://mui.com/material-ui/react-box/)  */
  BoxProps?: MuiBoxProps
}

export default function SettingsNotifications({ BoxProps }: SettingsNotificationsProps) {
  return (
    <Box
      maxWidth={{ xs: '100%', sm2: '629px' }}
      mt="1.8px"
      border="1px solid"
      flexGrow={1}
      bgcolor="gray.800"
      borderColor="gray.700"
      {...BoxProps}
    >
      <Box
        m="1px"
        bgcolor="gray.800"
        p={{ xs: '16px', sm: '24px' }}
        pb={{ xs: '20px', sm: '24px' }}
      >
        <Typography variant="h7" color="common.white" mb="18px">
          Notifications
        </Typography>
        <Stack direction="column" spacing="12px">
          <NotificationBlock
            title="Notifications"
            description="Select the kinds of notifications you’d like receive to your email and in-app notifications center."
            SwitchProps={{ checked: true }}
          />
          <NotificationBlock
            title="Notifications"
            description="Select the kinds of notifications you’d like receive to your email and in-app notifications center."
            SwitchProps={{ checked: true }}
          />
          <NotificationBlock
            title="Notifications"
            description="Select the kinds of notifications you’d like receive to your email and in-app notifications center."
            SwitchProps={{ checked: true }}
          />
          <NotificationBlock
            title="Notifications"
            description="Select the kinds of notifications you’d like receive to your email and in-app notifications center."
            SwitchProps={{ checked: false }}
          />
        </Stack>
      </Box>
    </Box>
  )
}
