import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const MuiInputBase: {
  variants: ComponentsVariants['MuiInputBase']
  styleOverrides: ComponentsOverrides<Theme>['MuiInputBase']
} = {
  variants: [
    {
      props: { size: 'large' },
      style: ({ theme }: { theme: any }) => ({
        ...theme.typography.paragraphMedium,
        height: '48px',
        '& .MuiInputBase-input': {
          height: '20px',
        },
        [theme.breakpoints.down('sm')]: {
          ...theme.typography.paragraphSmall,
          height: '44px',
        },
      }),
    },
    {
      props: { color: 'warning', error: false },
      style: ({ theme }: { theme: any }) => ({
        borderColor: theme.palette.warning.main,
        '&:hover, &.Mui-focused': {
          borderColor: theme.palette.warning.main,
        },
        '& .MuiInputBase-input': {
          color: theme.palette.gray[300],
          '&::placeholder': {
            color: theme.palette.gray[300],
          },
          '&:hover': {
            borderColor: theme.palette.warning.main,
            color: theme.palette.gray[300],
          },
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.paragraphSmall,
      'label + &': {
        marginTop: theme.spacing(2.3),
      },
      backgroundColor: theme.palette.gray[900],
      border: `1px solid ${theme.palette.gray[700]}`,
      color: theme.palette.gray[200],
      padding: '13px',
      height: '44px',
      '&.MuiInputBase-adornedEnd': {
        '& .MuiIconButton-sizeMedium': {
          marginRight: '-8px',
          '& .MuiSvgIcon-fontSizeMedium': {
            width: '18px',
            height: '18px',
            color: theme.palette.gray[25],
          },
        },
      },
      '&.MuiInputBase-sizeSmall': {
        padding: '9px 11px',
        height: '36px',
      },
      '& .MuiInputBase-input': {
        padding: 0,
        height: '16px',
        '&::placeholder': {
          color: theme.palette.gray[200],
          opacity: 1,
        },
        '&[type="password"]': {
          letterSpacing: '4px',
          '&::placeholder': {
            letterSpacing: 'initial',
          },
        },
      },
      '&:not(.Mui-disabled, .Mui-error, .MuiInputBase-colorWarning):hover': {
        borderColor: theme.palette.gray[400],
        color: theme.palette.gray[50],
        '& .MuiSelect-icon.MuiSelect-iconOutlined': {
          color: theme.palette.gray[0],
        },
      },
      '&.Mui-focused': {
        backgroundColor: theme.palette.gray[800],
        borderColor: theme.palette.gray[400],
        color: theme.palette.gray[50],
      },
      '&.Mui-warning': {
        borderColor: theme.palette.warning.main,
        color: theme.palette.gray[300],
      },
      '&.Mui-error': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
      '&.Mui-disabled': {
        borderColor: theme.palette.gray[700],
        backgroundColor: theme.palette.gray[700],
        '& .MuiInputBase-input.Mui-disabled': {
          'WebkitTextFillColor': 'unset',
          color: theme.palette.gray[600],
        },
      },
      transition: theme.transitions.create(['border-color']),

      '&:has(.MuiSelect-select)': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      },
    }),
  },
}

export default MuiInputBase
