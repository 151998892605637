import { Box, Stack, useTheme } from "@mui/material";
import Comment from 'src/components/Comment/Comment'
import { useFormik } from "formik";
import * as yup from 'yup';
import * as schemas from 'src/helpers/schemas'
import { useAppDispatch } from "src/hooks";
import Typography from "src/components/Typography/Typography";
import TextField from "src/components/TextField/TextField";
import Slider from '@mui/material/Slider';
import Button from "src/components/Button/Button";
import SvgIcon from "src/components/SvgIcon/SvgIcon";
import { stakeFor } from "src/actions/stakingActions";
import { StakeActionType, StakeType, TokenType, VestingType } from "src/helpers/types";
import { useEffect, useState } from "react";
import Select from "src/components/Select/Select";
import { updateBalance } from "src/actions/userActions";
import { formatTokenToString } from "src/utils/tokenUtil";

const ValidationSchema = (externalValue: number) => yup.object().shape({
    amount: schemas.amount(externalValue),
    wallet: schemas.validateEthereumWallet()
    
});

export default function StakeForm({type, actionType, ccCoins, fee}: {type: StakeType, actionType: StakeActionType, ccCoins: BigInt, fee?:number|undefined}) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const stakeText = 'Stake For';
    const stakeSmallText = 'stake';
    
    const [sliderValue, setSliderValue] = useState(0);

    const vestingTypeOptions = Object.entries(VestingType).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    useEffect(() => {

        console.log('Updating balance...'); 
        dispatch(updateBalance());
        
    }, []);

    const formik = useFormik({
        initialValues: {
            wallet: '',
            stakingType: VestingType.PRIVATE_PRESALE,
            amount: "" as string,
        },
        validateOnChange: true,
        validateOnMount: true,
        validationSchema: ValidationSchema(Number(formatTokenToString(ccCoins.toString(),TokenType.CT))),
        onSubmit: (values) => {
          formik.setStatus('');  
          dispatch(stakeFor(values.wallet,values.stakingType,values.amount));
          formik.resetForm();
          setSliderValue(0);
          formik.setSubmitting(false);
        }
    });
  
    const handleNumericChange = (e: { target: { value: any; name: string; }; }) => {
        const { value, name } = e.target;
        // Allow numbers and dot, but prevent multiple consecutive dots
        const filteredValue = value.replace(/[^0-9.]+/g, '').replace(/(\..*)\./g, '$1');
        // Update formik's state with the filtered value
        formik.setFieldValue(name, filteredValue);

         // Calculate the slider value based on the entered amount
        const totalCoins = Number(formatTokenToString(ccCoins.toString(), TokenType.CT));
        const enteredAmount = parseFloat(filteredValue) || 0;
        const percentage = (enteredAmount / totalCoins) * 100;

        // Update the slider value, clamping it between 0 and 100
        setSliderValue(Math.min(Math.max(percentage, 0), 100));
    };



    function handleSliderChange(event: Event, value: number | number[], activeThumb: number): void {
        // Update the slider state
        setSliderValue(value as number);
        
        // Calculate the new amount based on the slider value
        const newAmount = (Number(formatTokenToString(ccCoins.toString(), TokenType.CT)) * (value as number)) / 100;
        // Update the formik's amount field with the new amount, rounded if necessary
        formik.setFieldValue('amount', newAmount.toFixed(2).toString());
    }

    return (
        <Box>
          <Comment>{type} {stakeText}</Comment>
          <Stack
              direction="column"
              sx={{
                border: `1px solid ${theme.palette.gray[700]}`,
                padding: '1px',            
              }}
            >   
             <form onSubmit={formik.handleSubmit}>
                <Stack
                    direction="column"
                    gap="5px"
                    sx={{
                        background: theme.palette.gray[800],
                        padding: { xs: '18px 16px 28px', sm: '25px 23px 35px' },
                        marginBottom: '2px',
                    }}
                    >
                    <Typography
                        variant="bodySmall"
                        align="center"
                        color={theme.palette.gray[25]}
                        sx={{
                        m: '0 auto 22px auto',
                        width: '358px',
                        [theme.breakpoints.down('sm')]: {
                            m: '0 auto 18px auto',
                            width: '100%',
                        },
                        }}
                    >
                        Enter amount to {stakeSmallText}, you have {formatTokenToString(ccCoins.toString(),TokenType.CT,4,true,true,true)}
                    </Typography>
                    <TextField id="wallet" name="wallet" placeholder={`Enter wallet to stake for`} label={`Enter wallet to stake for`} size="large"
                          value={formik.values.wallet}
                          onChange={formik.handleChange}  
                          error={(formik.touched.wallet && Boolean(formik.errors.wallet))}
                          helperText={formik.touched.wallet && formik.errors.wallet !== undefined ? formik.errors.wallet : undefined }
                      />
                    <Select id="stakingType" name="stakingType" label="Vesting type" size="large" 
                        value={formik.values.stakingType} 
                        onChange={formik.handleChange}   
                        slotProps={{
                            input: {
                                id: 'stakingType',
                                name: 'stakingType',
                            }
                        }}                         
                        options={vestingTypeOptions}
                        />
                     <TextField id="amount" name="amount" placeholder={`Enter amount to ${stakeSmallText}`} label={`Amount to ${stakeSmallText}`} size="large"
                          value={formik.values.amount}
                          onChange={handleNumericChange}
                          error={(formik.touched.amount && Boolean(formik.errors.amount))}
                          helperText={formik.touched.amount && formik.errors.amount !== undefined ? formik.errors.amount : undefined }
                      />
                      <Slider
                            aria-label="Stake Percentage"
                            defaultValue={0}
                            step={null}
                            value={sliderValue}
                            marks={[
                                { value: 0, label: '0%' },
                                { value: 25, label: '25%' },
                                { value: 50, label: '50%' },
                                { value: 75, label: '75%' },
                                { value: 100, label: '100%' },
                            ]}
                            valueLabelDisplay="auto"
                            onChange={handleSliderChange}
                            sx={{
                                mt: 2, // Add margin top for spacing
                                color: theme.palette.primary.main, // Use theme color
                            }}
                        />
                </Stack>
                <Button variant="spaceBetween" size="large" endIcon={<SvgIcon name="arrow-right-line" sx={{ mr: '4px' }} />} type="submit" disabled={!formik.dirty || formik.isSubmitting } >
                   <Typography variant="buttonMassive">{stakeText}</Typography>
                </Button>
             </form>
          </Stack>
        </Box>
    )
}