import { ChallengeCardProps } from 'src/components/ChallengeCard/ChallengeCard'
import { EnterChallengePopupProps } from 'src/components/Popup/EnterChallengePopup/EnterChallengePopup'
import { ImageProps } from 'src/components/Image/Image'
export enum StakeType {
    FLEXI= 'FLEXI',
    VESTED= 'VESTED',
}

export enum StakeActionType {
    STAKE= 'STAKE',
    UNSTAKE= 'UNSTAKE',
}

export enum VestingType{
    PRE_SEED= 'PRE_SEED',
    PRIVATE_PRESALE= 'PRIVATE_PRESALE', 
    TEAM_AND_ADVISORS= 'TEAM_AND_ADVISORS',
    PUBLIC='PUBLIC',
}

export type AccountType = {
    intGameId: GameId,
    nickname?: string,
    connected: boolean
}

export enum TokenType {
    CT= 'CT',
    WETH= 'WETH',
    ETH= 'ETH'
}

export type GameTemplateType = {
    id:number,
    name: string,
    description: string,
    gameId: number,
    intGameId: string,
    gameType: GameType,
    gameMode: GameMode,
    maxParticipans: number,
    fee: number,
    disabled: boolean,
    highlighted: boolean,
    hidden: boolean,
    pricePot: number,
    pricePotAfterCut: number,
    prices: PrizeType[],
    tokenType: TokenType
    prizeFund: number,
    prizeFundTokenType: TokenType,
}

export type PrizeType = {
    placement: number,
    payoutType: string,
    payout: number,
}

export type DataHolderType = {
    open: boolean,
    challengeCard :  ChallengeCardProps & {
        iconSrc?: ImageProps['src']
        tableData?: EnterChallengePopupProps['tableData']
        gameTemplate?: GameTemplateType
        }
}

export const TournamentType = {
    SINGLE_ELIMINATION: 'SINGLE_ELIMINATION',
}

export enum GameId  {
    FORTNITE= 'FORTNITE',
    FRONTLAND= 'FRONTLAND'
}

export enum GameType  {
    SOLO= 'SOLO',
    DUO= 'DUO',
    ONE_VS_ONE= 'ONE_VS_ONE',
}

export enum GameMode {       
        GENERAL = 'GENERAL',
        NO_BUILD = 'NO_BUILD',
        BUILD = 'BUILD',
        ROOF_TOP = 'ROOF_TOP',
        ROOF_TOP_2 = 'ROOF_TOP_2',
        BRIDGE = 'BRIDGE',
        HACIENDA = 'HACIENDA',
        MAP_3 =  'MAP_3',
        MAP_4 = 'MAP_4',  
        MAP_5 = 'MAP_5',
        MAP_6 = 'MAP_6',    
}

export enum GameModeFortnite {         
    NO_BUILD = 'NO_BUILD',
    BUILD = 'BUILD', 
}

export enum GameModeFrontland {          
    ROOF_TOP = 'ROOF_TOP',
    ROOF_TOP_2 = 'ROOF_TOP_2',
    BRIDGE = 'BRIDGE',
    HACIENDA = 'HACIENDA',

}

export enum SortOrder  {
    FEE_LOW= 'FEE_LOW',
    FEE_HIGH= 'FEE_HIGH'
}

export enum ChallengeStatus {
    NOT_STARTED= 'NOT_STARTED',
    READY_TO_START= 'READY_TO_START',
    RUNNING= 'RUNNING',
    STARTING_SCORE_COLLECTED= 'STARTING_SCORE_COLLECTED',
    PRE_COOLDOWN_DONE= 'PRE_COOLDOWN_DONE',
    PLAY_TIME_OVER= 'PLAY_TIME_OVER',
    FINAL_DONE= 'FINAL_DONE',
    CANCELLED= 'CANCELLED',
}

export enum TournamentStatus {
    NOT_STARTED= 'NOT_STARTED',
    READY_TO_START= 'READY_TO_START',
    WAITING_FOR_NEXT_ROUND= 'WAITING_FOR_NEXT_ROUND',
    RUNNING= 'RUNNING',
    FINAL_DONE= 'FINAL_DONE',
    CANCELLED= 'CANCELLED',
}


export enum ParticipantStatus  {
    NOT_PARTICIPATING='NOT_PARTICIPATING',
    JOINED= 'JOINED',
    PLAYING= 'PLAYING',
    DONE= 'DONE',
    EMPTY= 'EMPTY',
}

export enum ChallengeType
{
    FREE = 'FREE',
    PAYED='PAYED', 
    SPECIAL_1='SPECIAL_1', 
    SPECIAL_2='SPECIAL_3', 
    QUALIFIER='QUALIFIER', 
    FINAL='FINAL'
}

export enum TournamnetTypes{
    SINGLE_ELIMINATION='SINGLE_ELIMINATION',
}

export enum ChallengeEventType  
{
    CREATED= 'CREATED',
    JOINED= 'JOINED',
    LEFT= 'LEFT',
    QUEUED_FOR_START= 'QUEUED_FOR_START',
    GAME_ROUND_COMPLETE= 'GAME_ROUND_COMPLETE',
    GAME_STARTED= 'GAME_STARTED',
    GAME_FINISHED= 'GAME_FINISHED',
    GAME_ERROR= 'GAME_ERROR',
    PLAYER_ERROR= 'PLAYER_ERROR',
    PLAY_TIME_OVER= 'PLAY_TIME_OVER',
    CHALLENGE_PAYOUT= 'CHALLENGE_PAYOUT',
    REFUNDED_CHALLENGE_FEE= 'REFUNDED_CHALLENGE_FEE'
}

export enum EventType
{
    CHALLENGE_EVENT='CHALLENGE_EVENT',
    USER_CHALLENGE_EVENT='USER_CHALLENGE_EVENT'
}

export enum ErrorType{
    COULD_NOT_PAY_FEE='COULD_NOT_PAY_FEE', 
    ACCOUNT_NOT_FOUND='ACCOUNT_NOT_FOUND', 
    AUTH_FAILED='AUTH_FAILED', 
    STATISTICS_RETRIEVAL_FAILED = 'STATISTICS_RETRIEVAL_FAILED', 
    TIMEOUT='TIMEOUT',
    UNKNOWN='UNKNOWN', 
    EPIC_ERROR='EPIC_ERROR', 
    JOB_CREATION_FAILED='JOB_CREATION_FAILED', 
    FEE_PAYMENT_FAILED='FEE_PAYMENT_FAILED',
    PRICE_DISTRIBUTION_FAILED='PRICE_DISTRIBUTION_FAILED', 
    FEE_REFUND_FAILED='FEE_REFUND_FAILED', 
    EPIC_PRIVACY_ERROR='EPIC_PRIVACY_ERROR', 
    PLAYFAB_ERROR='PLAYFAB_ERROR', 
    JOIN_CHECK_FAILED='JOIN_CHECK_FAILED'
}

export enum TopList_Type{
    MOST_WINS='MOST_WINS', 
    MOST_PLAYED_GAMES='MOST_PLAYED_GAMES', 
    HIGEST_WIN_PERCENTAGE='HIGEST_WIN_PERCENTAGE', 
    BEST_ALLTIME_STREAK='BEST_ALLTIME_STREAK', 
    BEST_WEEKLY_STREAK='BEST_WEEKLY_STREAK',
}

export enum StreakType{
    WEEKLY='WEEKLY',
    ALLTIME='ALLTIME'
}


export enum RoundType{
    QUALIFIER='QUALIFIER', 
    QUARTER_FINAL='QUARTER_FINAL', 
    SEMI_FINAL='SEMI_FINAL', 
    FINAL='FINAL'
}

export type UserType = {
    id: number,
    username: string,
    nickname: string,
    coins: CoinsType,
    statistics: any
}  

export type CoinsType = {
    weth: string,
    challengeCoins: string
}
 

export type UserAdminType = {
    id: number,
    publicAddress: string,
    nickname: string,
    epicDisplayName: string,
    playFabDisplayName: string,
    challengeCoins: number,
    created: Date,
    statistics: any
} 
  

export type ParticipantType = {
    userId: number,
    joined: Date,
    status: ParticipantStatus,   
    nickname?: string,
    score?: number,
    kills?: number,
    startedMatchmaking?: boolean,
    roundsWon?: number,
    placement?: number,
    payout?: number,
    error: boolean,
    errorType? :ErrorType,
    errorDescription?: string
    finishedTop1?: boolean,
    finishedTop10?: boolean,
    finishedTop25?: boolean,
    feePaid: boolean,
    feeRefunded: boolean,
}  

export type LogEventType = {
    id: number,
    intGameId: GameId,
    challengeName?: string
    gameMode: string,
    challengeId: number,
    numberOfParticipants?: number
    participantId?: number,
    nickname?: string
    type:ChallengeType,
    eventType: EventType,
    challengeEventType: ChallengeEventType,
    challengeStatus: ChallengeStatus,
    fee?: number,
    payout?: number,
    tokenType?: TokenType,
    position?: number,
    description: string,
    error: boolean,
    created: Date, 
}
  
export type ActiveChallengeType = {
    id: number,
    blockchainChallengeId: number,
    intGameId: GameId,
    name: string, 
    description: string, 
    type: string,
    gameName: string,
    gameType: string,
    gameMode: string,
    gameMap: string,
    status: ChallengeStatus,
    disabled: boolean,
    maxParticipans: number,
    participantCount: number,
    isParticipating: boolean,
    participants: ParticipantType[],
    error: string|undefined,
    errorDescription?: string|undefined,
    fee: number,
    tokenType: TokenType,
    priceType: string,
    pricePot: number,
    pricePotAfterCut: number,
    prices: PrizeType[],
    pricesDistributed: boolean,
    playersRefunded: false,
    logEvents: any[],
    preCooldownTime?: number,
    matchTime?: number,
    resultCollectionTime?: number
    started?: Date,    
    startTime?: Date,
    tournamentId?: number,
    isTournamentBase: boolean
}

export type TournamentType = ActiveChallengeType & {
    challengeId: number,   
    tournamentType: TournamnetTypes,
    tournamentStatus: TournamentStatus
    tournamentError?: boolean,
    tournamentName: string,
    tournamentDescription: string,
    currentRound: number,
    numberOfRounds: number,
    playersLeft: number,
    roundStartTime?: Date,
    nextRoundStarts?: Date,
    currentRoundFinishes: Date,
    tournamentFinishes: Date,
    brackets: BracketType[],
    roundIsStarting?: boolean,
    roundIsFinishing?: boolean,
}

export type BracketType = {
    round: number,
    roundType: RoundType
    matches: MatchType[]
}

export type MatchType = {
    id: number,
    players: PlayerType[],
    error?: boolean,
    status: ChallengeStatus,
    isThirdPlaceMatch?: boolean,
}

export type PlayerType = {
    id?: number,
    nickname: string,
    profilePictureUrl: string,
    status: ParticipantStatus,
    score?: number,
    error?: boolean,
    placement?: number,
}

export type LiveEventType = {
    date : Date,
    message: string,
    highlighted: boolean,
    coinImageWithAmountProps: {
        amount?: string,
    },
}

export type GlobalLiveEventType= {
    action: string,
    game: string,
    date: Date,
    status: string,
}


export type TopListType= {
    intGameId: GameId,
    topListType: TopList_Type,
    gameType: string,
    gameMode?: string,
    title: string,
    description: string
    topList: TopListItemType[]
}

export type TopListItemType= {
    userId: number,
    nickname: string,
    value: number,
    formattedDecimalValue: string,
    longValue:number,
    longExtraValue1?:number,
    longExtraValue2?:number
}

export type GameHistoryType = {
    intGameId: GameId,
    challengeId: number,
    tournamentId?: number,
    participantCount: number,
    challengeStatus: ChallengeStatus,
    challengeError: boolean,
    datePlayed: Date,
    participantStatus: ParticipantStatus,
    participantError: boolean
    finalScore?: number
    placement?: number
    payout?: number
    tokenType?: TokenType
}
