import React from 'react'
import { Box, BoxProps } from '@mui/material'

type GradientBoxProps = BoxProps & {
  startColor?: string
  startPosition?: number
  endColor?: string
  endPosition?: number
  degrees?: number
  children?: React.ReactNode
}

function GradientBox({
  startColor = '59, 77, 107,0.2',
  startPosition = 20,
  endColor = '59, 77, 107,0.1',
  endPosition = 100,
  children,
  degrees = 0,
  ...restProps
}: GradientBoxProps) {
  return (
    <Box
      position="relative"
      zIndex={0}
      sx={{
        background: `linear-gradient(${degrees}deg, rgba(${startColor}) ${startPosition}%, rgba(${endColor}) ${endPosition}%)`,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default GradientBox
