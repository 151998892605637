import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from 'src/components/PageContainer/PageContainer';
import Box from '@mui/material/Box';
import PatternLines from 'src/components/PatternLines/PatternLines';
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot';
import { useTheme } from '@mui/material';
import { useAppDispatch } from 'src/hooks';
import { DataGrid, GridActionsCellItem, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridPaginationModel, GridRenderCellParams, GridRenderEditCellParams, GridRowId, useGridApiContext } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from 'src/components/TextField/TextField';
import Typography from 'src/components/Typography/Typography';
import { useNavigate } from 'react-router';
import Button from 'src/components/Button/Button';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { useFetchHistoricStakingsQuery } from 'src/api/stakingApi';
import { localeDateTimeFormat } from 'src/utils/datetime';
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount';
import { getTokenImgSrc } from 'src/helpers/commonData';
import { formatTokenToString } from 'src/utils/tokenUtil';


function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
  
    return <TextField style={{marginTop:'14px'}} size="small" value={value} onChange={handleValueChange} />;

  }
  

export default function HistoricStaking() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
  
    const { data:history, error:historyError, isLoading:historyIsLoading } = useFetchHistoricStakingsQuery(undefined, {}); 


    const isLoading = useMemo(() => {
        return historyIsLoading;
    }, [historyIsLoading])

    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 25,
      });
    
    useEffect(() => {
        if(history?.stakings!==undefined)
            setRowCountState(history?.stakings.length);
    }, [history]);

    

    const columns: GridColDef[] = [
        {
            field: 'nickname',
            headerName: 'Nickname',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Nickname</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'publicAddress',
            headerName: 'Address',
            width: 350,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Address</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return <CoinImageWithAmount  avatarProps={{ src: getTokenImgSrc(params.row.tokenType)}} amount={`${formatTokenToString(params.value,params.row.tokenType)}`} />
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Amount</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'stakeType',
            headerName: 'Type',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Type</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
        {
          field: 'stakeAction',
          headerName: 'Action',
          width: 150,
          renderCell: (params: GridRenderCellParams) => {
            return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
          },
          renderHeader: (params: GridColumnHeaderParams) => {
            return <Typography variant="labelSmall">Action</Typography>
          },
          renderEditCell: (params: GridRenderEditCellParams) => (
            <CustomEditComponent {...params} />
          ),
        },
        {
            field: 'created',
            headerName: 'Created',
            type: 'string',   
            width: 150,
            valueGetter: (params) => {
                if (!params.value) {
                  return params.value;
                }
                // Convert to date
                const dateAsString = params.value;
                const result = localeDateTimeFormat(new Date(dateAsString));
                return result;
              },
            renderCell: (params: GridRenderCellParams) => {
              return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
            },
            renderHeader: (params: GridColumnHeaderParams) => {
              return <Typography variant="labelSmall">Created</Typography>
            },
            renderEditCell: (params: GridRenderEditCellParams) => (
              <CustomEditComponent {...params} />
            ),
        },
       
    ]

    function fetchData(model: GridPaginationModel, details: GridCallbackDetails<any>): void {
        setPaginationModel(model);
    }

    return (
        <Box bgcolor="gray.900" position="relative" marginBottom="10px">
            <PageContainer sx={{ pt: '17px' }}
                innerContainerProps={{
                    sx: { position: 'relative' },
                }}
            >
                <PatternLines height="262px" top="9px" />
                <TitleWithDot
                    typographyProps={{ variant: 'h2' }}
                    dotProps={{ bottom: '16px' }}
                    rootStackProps={{ spacing: '10px', mb: '23px' }}
                >
                    Stakings
                </TitleWithDot>          
                <Button
                  endIcon={<SvgIcon name="edit-line" sx={{ marginRight: '4px' }} />}
                  sx={{ minWidth: '100px' , marginBottom: '6px'}}
                  variant="contained"
                  size={ 'small'}
                  onClick={() => navigate('/staking/create')}
                  >
                  Create new
              </Button>    
                <DataGrid
                    disableColumnMenu rows={history?.stakings || []}
                    columns={columns}
                    loading={isLoading}
                    pageSizeOptions={[25, 50, 100]}
                    paginationMode="server"
                    rowCount={rowCountState}
                    paginationModel={paginationModel}
                    onPaginationModelChange={fetchData}
                    sx={{
                        height: '650px',
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                        },
                    }}
            
        />
            </PageContainer>
        </Box>
    );
}