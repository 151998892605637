import { formatTokenToString } from "src/utils/tokenUtil";
import { PrizeType, TokenType } from "./types";
import { PNG_ICONS } from "src/assets/icons/icons";



export const formatPrizePool = (prizes:PrizeType[], tokenType:TokenType)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = [];
    for (let i = 0; i < prizes?.length; i++) {
        if(prizes[i].payout>0){
            result.push(formatTokenToString(prizes[i].payout, tokenType))
        }
    }
    return result;
 }

 export const formatPrizePoolWithMax = (prizes:PrizeType[], tokenType:TokenType, max:number)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = [];
    const to = max < prizes.length ? max : prizes.length;
    for (let i = 0; i < to; i++) {
        if(prizes[i].payout>0){
            result.push(formatTokenToString(prizes[i].payout,tokenType))
        }
    }
    return result;
 }

 //function to create string from prizes:PrizeType[] and a min index
export const formatPrizePoolFrom = (prizes:PrizeType[], tokenType:TokenType,from:number)=>{
    //['1.8 CC', '1.0 CC', '0.5 CC']
    let result = "";
    for (let i = from; i < prizes?.length; i++) {
        if(prizes[i].payout>0){
            result= `${result} ${addOrdinalSuffix(i+1)} ${formatTokenToString(prizes[i].payout,tokenType)}${i+1<prizes.length?',':''}`
        }
    }
    return result;
    }


export function addOrdinalSuffix(i:number) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

function createDateAsUTC(date : Date) {
    console.log("createDateAsUTC", date);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}


export function secondsLeft(date: Date, waitTimeInSec:number) : number {
    var date = new Date(date as Date);
    const utcDate = createDateAsUTC(date);
    date.setSeconds(date.getSeconds() + waitTimeInSec);
    let now = new Date()
    var difference_s = (utcDate.getTime() - now.getTime())/1000;
    return difference_s;
}


export function getTokenImgSrc(token: TokenType) : string{

    switch (token) {
        case TokenType.CT:
            return PNG_ICONS.coins;
        case TokenType.WETH:
            return PNG_ICONS.weth;
        default:
            return "";
    }
}

