import { takeEvery } from 'redux-saga/effects';
import { getBlockchain } from "../utils/ethersUtil";
import { showErrorToast } from "../components/Toast/Toasts";
import { put, call, all, select } from 'redux-saga/effects';
import { TokenType } from 'src/helpers/types';
import { internalUpdateBalanceWorker } from './apiSagas';
import { apiStakingSlice, Tags } from 'src/api/stakingApi';
import { compareStrings } from 'src/utils/stringUtils';
import { VestingType } from 'src/helpers/types';
import { toBigInt,subtract,lessThan } from 'src/utils/numbers';
import { toToken,formatTokenToString } from 'src/utils/tokenUtil';

export default function* stakingSagas() {
    yield takeEvery("STAKE_FOR", stakeForWorker);

}

function* stakeForWorker(action) {
    try {
        
        const {  provider, signer, challengeCoin,vestedStakingExtendedContract } =yield call(getBlockchain);
        const accounts =  yield provider.listAccounts();
        const walletAccount = accounts[0].address;

        
        const account = yield select(state => state.userReducer.userInfo.username);
        console.log("walletAccount:" + walletAccount);
        if(compareStrings(walletAccount,account) !== 0){
             showErrorToast('Incorrect account', "Please switch account in your wallet to the same as you have logged in with");
             return;
        }

        const amount = action.amount;
        const stakeForWallet = action.wallet;
        const vestingType = action.vestingType;  

        let intVestingType = 0;
        switch(vestingType){
            case VestingType.PRE_SEED:
                intVestingType = 1;
                break;
            case VestingType.PRIVATE_PRESALE:
                intVestingType = 2;
                break;
            case VestingType.TEAM_AND_ADVISORS:
                intVestingType = 3;
                break;
            case VestingType.PUBLIC:
                intVestingType = 4;
                break;
            default:
                intVestingType = 0;
        }
        
      
        let amountInWei = toToken(amount);
        console.log("amountInWei:" + amountInWei);

        yield put({ type: "SHOW_WAIT_DIALOG", title: `Getting ready to stake ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}`, subtitle: 'Checking balance'});

        
        const balance = yield call(challengeCoin.balanceOf,walletAccount);
        const balanceBN = toBigInt(balance);
        console.log("balance:" + balanceBN);
        const newBalance = subtract(balanceBN,amountInWei);              
       
        if (lessThan(balanceBN,amountInWei)) {
            yield put({ type: "UPDATE_WAIT_DIALOG", title:'Staking failed',  subtitle: 'You do not have enough tokens', status: 'error', timeout: 3000 });
            return;
        }
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Checking allowance' });

        const contractAddress =  yield vestedStakingExtendedContract.getAddress();
        const allowance = yield call(challengeCoin.allowance, walletAccount, contractAddress);
        if (allowance < amountInWei) {
            yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Fee is bigger than allowance, please approve allowance in Wallet' });
            const tx = yield call(challengeCoin.approve, contractAddress, amountInWei);
            yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
            const txdone = yield tx.wait();
        }
        yield put({ type: "UPDATE_WAIT_DIALOG",  subtitle: `Please sign transaction to stake ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}` });
        const tx2 = yield call(vestedStakingExtendedContract.stakeFor, amountInWei, stakeForWallet, intVestingType);
        yield put({ type: "UPDATE_WAIT_DIALOG", subtitle: 'Waiting for transaction to complete' });
        const tx2done = yield tx2.wait();
        yield put({ type: "UPDATE_WAIT_DIALOG", title:'Staking completed',  subtitle: `You have staked ${formatTokenToString(amountInWei,TokenType.CT,6,false,true,true)}`, status: 'success', timeout: 3000 });

        yield internalUpdateBalanceWorker(newBalance, TokenType.CT); //update balance in store

        // Then invalidate RTK Query cache
        yield put(apiStakingSlice.util.invalidateTags([Tags.BlockchainVestedStaking]));
    }
    catch (e) {
        const erorMessage = e.message;
        console.log("stakeWorker failed," + erorMessage)
        yield put({ type: "SHOW_WAIT_DIALOG", showWait: true, title:'Stake failed', subtitle: erorMessage, status: 'error', timeout: 0 });;
              
    }   
    
}


