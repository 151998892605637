import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axiosRetry from 'axios-retry';
import { config } from 'src/config/config';
import { authToken } from 'src/utils/authHeader';
import JSONbig  from 'json-bigint';

// Initialize axios instance with TypeScript
export const axiosInstance = axios.create({
  baseURL: config.url.BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
    const token = authToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

//To handle large numbers like 1e21
axiosInstance.defaults.transformResponse = [(data) => {
    if (typeof data === 'string') {
      try {
        data = JSONbig.parse(data);
      } catch (e) { /* Ignore */ } // Added this Ignore as it's the same in the Axios
    }
    return data;
}];
  
// Apply retry configuration with TypeScript
axiosRetry(axiosInstance, {
  retries: 3, // Retry request 3 times
  retryDelay: (retryCount: number) => {
    return axiosRetry.exponentialDelay(retryCount);
  },
  // This function determines if a retry should be attempted
  retryCondition: (error: AxiosError<unknown|any>) : boolean | Promise<boolean> => {
    // Retry on network errors or 5xx status codes
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || (error.response && error.response.status >= 500) || false;
  },
});


export function handleAxiosError(error: unknown): never {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.message);
      throw new Error(error.message);
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }

  // Define a custom baseQuery using axiosInstance
export const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<{
  url: string,
  method?: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig['data'],
  params?: AxiosRequestConfig['params'],
}, unknown, unknown> => async ({ url, method = 'get', data, params }) => {
  try {
    const result: AxiosResponse = await axiosInstance({ url: baseUrl + url, method, data, params });
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};