import React from 'react'
import { ParticipantStatus, ChallengeStatus } from '../utils/const'

const LogEntry = ({ id, challengeId, created, type, eventType, challengeEventType, challengeStatus, participantStatus, description, nickname, error, errorType, errorDescription }) => (
    <tr>
        <td>
            {challengeId}
        </td>
        <td>
            {dateFormat(created)}
        </td>
        <td>
            {type}
        </td>
        <td>
            {nickname}
        </td>
        <td>
            {description}
        </td>              
        <td>
            {challengeStatus}
        </td>
        <td>
            {participantStatus}
        </td>
        <td>
            {error}
        </td>
        <td>
            {errorType}
        </td>
        <td>
            {errorDescription}
        </td>
    </tr>
)

function dateFormat(date) {
    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(played)

    return formated;
}

function placementFormat(challengeStatus, challengeError, participantStatus, participantError, placement) {
    if (!challengeError && !participantError && challengeStatus === ChallengeStatus.FINAL_DONE && participantStatus === ParticipantStatus.DONE) {
        return "#" + placement;
    }
    else {
        return "-";
    }
}

function challengeStatus(challengeStatus, challengeError, participantStatus, participantError, placement) {
    if (!challengeError) {
        if (challengeStatus == ChallengeStatus.FINAL_DONE) {
            return "HEj";
        }
        else {
            return "Nej";
        }
    }
    else {
        return "ump";
    }
}

export default LogEntry