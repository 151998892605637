import React from 'react'
import { Box, BoxProps, useTheme } from '@mui/material'

export type ShadowBoxProps = BoxProps

export default function ShadowBox(props: ShadowBoxProps) {
  const theme = useTheme()
  return (
    <Box
      position="absolute"
      top={props?.bottom || props?.bottom === 0 ? 'unset' : 0}
      left={props?.right || props?.right === 0 ? 'unset' : 0}
      maxWidth="732px"
      width="100%"
      height="666px"
      zIndex={1}
      {...props}
      sx={{
        background: 'linear-gradient(270deg, #040811 0%, rgba(4, 8, 17, 0) 100%)',
        [theme.breakpoints.down('md')]: {
          maxWidth: '399px',
        },
        [theme.breakpoints.down('sm2')]: {
          maxWidth: '106px',
        },
        ...props?.sx,
      }}
    />
  )
}
