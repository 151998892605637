import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const getMuiButtonOutlinedPrimary = (theme: Theme) => ({
  backgroundColor: theme.palette.gray[800],
  border: 0,
  boxShadow: `inset 0px 0px 0px 1px ${theme.palette.gray[700]}`,
  color: theme.palette.gray[25],
  '&:hover': {
    backgroundColor: theme.palette.gray[900],
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.gray[500]}`,
    color: theme.palette.gray[0],
    '& .MuiButton-startIcon': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.gray[25],
      },
    },
  },
})

const MuiButton: {
  variants?: ComponentsVariants['MuiButton']
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton']
} = {
  variants: [
    {
      props: { variant: 'menu', color: 'primary' },
      style: ({ theme }: { theme: any }) => ({
        overflow: 'hidden',
        justifyContent: 'flex-start',
        '& .MuiButton-startIcon': {
          '&:not(:has(svg))': {
            border: `1px solid ${theme.palette.gray[700]}`,
            backgroundColor: `${theme.palette.gray[800]}`,
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 0,
          },
          '&.MuiButton-iconSizeSmall, &.MuiButton-iconSizeMedium': {
            '& .MuiSvgIcon-root': {
              fontSize: '18px',
              color: theme.palette.gray[200],
            },
            img: {
              width: '18.67px',
              height: '18.67px',
            },
          },
          '&.MuiButton-iconSizeLarge': {
            width: '30px',
            height: '30px',
          },
        },
        ...getMuiButtonOutlinedPrimary(theme),
        '&.MuiButton-menuSelected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.gray[900],
          boxShadow: 'none',
          '& .MuiButton-startIcon': {
            '&.MuiButton-iconSizeSmall, &.MuiButton-iconSizeMedium': {
              '& .MuiSvgIcon-root': {
                color: theme.palette.gray[900],
              },
            },
          },
        },
        '&.Mui-disabled': {
          background: `repeating-linear-gradient(30deg, transparent, transparent 2px, ${theme.palette.gray[700]} 2px, ${theme.palette.gray[700]} 6px)`,
          bgcolor: 'gray.800',
          boxShadow: 'none',
          '& .MuiButton-startIcon': {
            '&:not(:has(svg))': {
              opacity: 0.6,
              img: {
                opacity: 0.6,
              },
            },
            '&.MuiButton-iconSizeSmall, &.MuiButton-iconSizeMedium': {
              '& .MuiSvgIcon-root': {
                color: theme.palette.gray[600],
              },
            },
          },
        },
      }),
    },
    {
      props: { size: 'big' },
      style: ({ theme }: { theme: any }) => ({
        ...theme.typography.buttonBig,
        padding: '0px 18px',
        minHeight: '64px',
      }),
    },
    {
      props: { variant: 'spaceBetween', color: 'primary' },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.gray[900],
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'space-between',
      }),
    },
    {
      props: { variant: 'spaceBetween', color: 'primary', size: 'large' },
      style: ({ theme }: { theme: any }) => ({
        [theme.breakpoints.down('sm')]: {
          ...theme.typography.buttonBig,
          padding: '0px 18px',
          minHeight: '64px',
        },
      }),
    },
    {
      props: { variant: 'game', color: 'primary' },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.gray[900],
        backgroundColor: theme.palette.primary.main,
        minWidth: '106px',
        width: '106px',
        padding: '14px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textAlign: 'left',
        minHeight: '112px',
        maxHeight: '150px',
        height: '100%',
        pb: '13px',
        '& .MuiButton-startIcon': {
          margin: '3px 0px 0px 3px',
        },
        '& svg': {
          ml: '0 !important',
          fontSize: '18px !important',
        },
      }),
    },
    {
      props: { variant: 'challenge', color: 'primary' },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.gray[900],
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'space-between',
        padding: '12px',
        height: '54px',
        '&.Mui-disabled': {
          background: theme.palette.background.lines,
          backgroundColor: theme.palette.gray[700],
          '& .MuiTypography-root': {
            color: theme.palette.gray[600],
          },
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.buttonMedium,
      borderRadius: 0,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&.MuiButton-containedPrimary, &.MuiButton-containedWarning, &.MuiButton-spaceBetween, &.MuiButton-gamePrimary, &.MuiButton-challengePrimary':
        {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
          },
        },
      '&.MuiButton-outlinedPrimary': {
        ...getMuiButtonOutlinedPrimary(theme),
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.gray[700],
        color: theme.palette.gray[600],
        border: 0,
      },
      '&.MuiSvgIcon-root': {
        fontSize: '24px',
      },
      '&.MuiButton-sizeSmall, &.MuiButton-sizeMedium': {
        '& .MuiSvgIcon-root': {
          fontSize: '24px',
        },
        img: {
          width: '24px',
          height: '24px',
        },
      },
      '&.MuiButton-sizeLarge, &.MuiButton-sizeBig': {
        '& .MuiSvgIcon-root': {
          fontSize: '28px',
        },
        img: {
          width: '28px',
          height: '28px',
        },
      },
    }),
    sizeSmall: ({ ownerState }) => ({
      padding: '0 16px',
      lineHeight: '17px',
      minHeight: '44px',
      ...((ownerState.startIcon || ownerState.endIcon) && {
        padding: '10px',
      }),
    }),
    sizeMedium: ({ ownerState }) => ({
      padding: '0 24px',
      lineHeight: '17px',
      minHeight: '54px',
      ...((ownerState.startIcon || ownerState.endIcon) && {
        padding: '15px',
      }),
    }),
    sizeLarge: ({ theme }) => ({
      ...theme.typography.buttonMassive,
      padding: '0px 24px',
      minHeight: '80px',
    }),
    startIcon: {
      '&.MuiButton-iconSizeSmall, &.MuiButton-iconSizeMedium, &.MuiButton-iconSizeLarge': {
        '&:has(img)': {
          marginLeft: 0,
        },
      },
      '&.MuiButton-iconSizeSmall': {
        marginRight: '10px',
      },
      '&.MuiButton-iconSizeMedium, &.MuiButton-iconSizeLarge': {
        marginRight: '14px',
      },
    },
    endIcon: {
      '&.MuiButton-iconSizeSmall': {
        marginLeft: '10px',
      },
      '&.MuiButton-iconSizeMedium, &.MuiButton-iconSizeLarge, &.MuiButton-iconSizeBig': {
        marginLeft: '14px',
      },
    },
  },
}

export default MuiButton
