import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChallenge } from '../../actions/adminActions';

export const Challenge = () => {
    let params = useParams();
    const dispatch = useDispatch();
    const challenge = useSelector(state => state.adminReducer.challenge);

    useEffect(() => {
        dispatch(getChallenge(params.challengeId));
    });

    if (challenge == null) {
		return (<div><h2 className="panel-title">Loading...</h2></div>);
    }

    return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-xl-6">
					<h2 className="panel-title">Challenge data - #{challenge.id}</h2>
					<div className="panel">
						<div className="container-fluid">
							<table className="table lobby-table table-hover table-striped">
								<tbody>
									{challenge.participants.map((participant, index) => (
										<tr key={participant.userId}>
											<td className="count">
												{showMedalOrIndex({ index, payout: participant.payout, error: participant.error, challengeError: challenge.error })}
											</td>
											<td className="challenger">
												<img className="profile-picture" src={typeof participant.profilePictureUrl === 'undefined' ? '/images/profile-picture.png' : participant.profilePictureUrl} alt="Profile" />
												{participant.isInfluenser ?
													<div className="twitch-partner">
														<img src="/images/twitch-icon.svg" alt="Twitch Partner" />
														{participant.nickname}
													</div> : participant.nickname
												}

											</td>
											<td className="stats">
												{participant.error === false ? showFinishedTop({ finishedTop25: participant.finishedTop25, finishedTop10: participant.finishedTop10, finishedTop1: participant.finishedTop1 }) : participant.errorDescription}
											</td>
											<td className="stats">
												{showKills({ kills: participant.kills })}
											</td>
											<td className="stats">
												{showScore({ score:  participant.score })}
											</td>
											<td className="stats">
												{showPayout({ payout: participant.payout })}
											</td>
										</tr>	
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<h2 className="panel-title">&nbsp;</h2>
					<div className="panel description">
						<p>{challenge.error ? challenge.errorDescription : 'No issues!'}</p>
					</div>
				</div>
			</div>
		</div>
    );
}

const showMedalOrIndex = ({ index, payout, error, challengeError }) => {
	if (typeof payout !== 'undefined' && payout !== null && !error && index < 3) {
		//Since we have payout and no error challenge is done 
		if (index === 0) {
			return <img className="medal" src="/images/gold-medal.png" alt="" />
		}
		else if (index === 1) {
			return <img className="medal" src="/images/silver-medal.png" alt="" />
		}
		else {
			return <img className="medal" src="/images/bronze-medal.png" alt="" />
		}
	}
	else if (error || challengeError) {
		return '-';
	}
	else {
		return '#' + (index + 1);
	}

}

const showFinishedTop = ({ finishedTop25, finishedTop10, finishedTop1 }) => {

	if (typeof finishedTop1 !== 'undefined' && finishedTop1) {
		return ' Won game!';
	}
	else if (typeof finishedTop10 !== 'undefined' && finishedTop10) {
		return ' Finished top 10!';
	}
	else if (typeof finishedTop25 !== 'undefined' && finishedTop25) {
		return ' Finished top 25!';
	}
	else if (typeof finishedTop1 !== 'undefined' && typeof finishedTop10 !== 'undefined' && typeof finishedTop25 !== 'undefined' && !finishedTop1 && !finishedTop10 && !finishedTop25) {
		return ' Died early';
	}
	else {
		return '';
	}

}
const showScore = ({ score }) => {
	return (typeof score !== 'undefined' && score !== null ? ' ' + score + ' points' : '');
}

const showKills = ({ kills }) => {
	return (typeof kills !== 'undefined' && kills !== null ? ' ' + kills + ' kills' : '');
}

const showPayout = ({ payout }) => {
	return (typeof payout !== 'undefined' && payout !== null && payout > 0 ? <div className="winnings">${payout}</div> : '');
}
