import React from 'react'
import { Box } from '@mui/material'
import Button, { ButtonProps } from '../Button/Button'
import Typography from 'src/components/Typography/Typography'

export type MenuItemButtonProps = Pick<
  ButtonProps,
  'children' | 'sx' | 'disabled' | 'onClick' | 'fullWidth' | 'startIcon'
> & {
  size?: 'small'
  /** If true, the component is highlighted with the primary color. */
  selected?: boolean
  /** If true, the component is disabled. */
  isComingSoon?: boolean
  align?: 'center'
}

export default function MenuItemButton({
  children,
  size = 'small',
  selected,
  fullWidth = true,
  disabled,
  isComingSoon,
  align,
  ...restProps
}: MenuItemButtonProps) {
  return (
    <Box p="2px" flex={fullWidth ? 1 : 'unset'}>
      <Button
        className={selected ? 'MuiButton-menuSelected' : ''}
        variant="menu"
        size={size}
        fullWidth={fullWidth}
        disabled={disabled || isComingSoon}
        sx={{
          ...(align === 'center' && {
            justifyContent: 'center',
          }),
          ...restProps?.sx,
        }}
        {...restProps}
      >
        {children}
        {isComingSoon && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 1,
              top: '12px',
              right: '-31px',
              width: '126px',
              height: '16.81px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              bgcolor: 'primary.main',
              transform: 'rotate(30deg)',
            }}
          >
            <Typography variant="uiElement" color="gray.900" letterSpacing="initial">
              Coming soon
            </Typography>
          </Box>
        )}
      </Button>
    </Box>
  )
}
