
export function showErrorPanel(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "ERROR", displayType: "STICKY", reconnect: false };
}

export function showErrorPanelWithReconnect(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "ERROR", displayType: "STICKY", reconnect: true };
}

export function showInfoPanel(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "INFO", displayType: "STICKY", reconnect: false };
}

export function flashInfoPanel(message) {
    return { type: "SHOW_PANEL", message: message, messageType: "INFO", displayType: "FLASH", reconnect: false };
}

export function hidePanel() {
    return { type: "HIDE_PANEL" };
}

export function changedVisibility(visible) {
    return { type: "CHANGED_VISIBILITY", visible: visible };
}


export function showChangeChainPanel(chainId, chainSlug, message) {
    return { type: "SHOW_CHANGE_CHAIN_PANEL", chainId, chainSlug, message };
}
export function hideChangeChainPanel() {
    return { type: "HIDE_CHANGE_CHAIN_PANEL" };
}