import React, { useCallback, useMemo } from 'react'
import { Formik, Form, FieldArray, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Box, BoxProps as MuiBoxProps, Stack, ButtonBase, Avatar, AvatarProps } from '@mui/material'
import * as schemas from 'src/helpers/schemas'
import Typography from 'src/components/Typography/Typography'
import ImageBox from 'src/components/ImageBox/ImageBox'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import Link, { LinkProps } from 'src/components/Link/Link'
import AccountFieldArrayItem, {
  AccountItem,
} from 'src/components/AccountFieldArrayItem/AccountFieldArrayItem'

export enum Statuses {
  PROCESS = 'process',
  CONNECTED = 'connected',
}

export type Status = `${Statuses}`

export type AccountOptions = {
  id: string
  status?: Status
  SvgIconProps?: SvgIconProps
  AvatarProps?: AvatarProps
  LinkProps: LinkProps
}[]

const defaultValidationSchema = Yup.object().shape({
  accounts: Yup.array(
    Yup.object({
      value: schemas.username(),
    })
  ),
})

export type InitialValues = {
  accounts: AccountItem[]
  changedIndex?: number
}

type LinkedAccountsProps = {
  /** [The root element of this component uses the BoxProps from Material UI](https://mui.com/material-ui/react-box/)  */
  BoxProps?: MuiBoxProps
  accountOptions?: AccountOptions
  onAccountOptionClick?: (option: AccountOptions[0]) => void
  initialValues: InitialValues
  validationSchema?: typeof defaultValidationSchema
  onSubmit: (values: InitialValues, formikHelpers: FormikHelpers<InitialValues>) => void
}

export default function LinkedAccounts({
  BoxProps,
  accountOptions = [],
  onAccountOptionClick,
  initialValues = { accounts: [], changedIndex: 1 },
  validationSchema = defaultValidationSchema,
  onSubmit,
}: LinkedAccountsProps) {
  const initValues = useMemo(
    () => ({
      ...initialValues,
    }),
    [initialValues]
  )

  const validSchema = useMemo(() => validationSchema, [validationSchema])

  const handleAccountOptionClick = useCallback(
    (option: AccountOptions[0]) => () => {
      if (onAccountOptionClick) {
        onAccountOptionClick(option)
      }
    },
    [onAccountOptionClick]
  )

  return (
    <Box
      pt={{ xs: '16px', sm: '24px' }}
      px={{ xs: '16px', sm: '24px' }}
      pb={{ xs: '24px', sm: '36px' }}
      bgcolor="gray.800"
      m="1px"
      mt="2px"
      maxWidth={{ xs: '100%', sm2: '625px' }}
      {...BoxProps}
    >
      <Stack direction="column" spacing="18px">
        <Typography variant="h7" color="common.white">
          Linked accounts
        </Typography>
        <Box bgcolor="gray.700" p="14px" maxWidth="577px">
          <Stack direction="column" spacing="18px">
            <Typography variant="bodyMedium" color="gray.100">
              {
                'To add new accounts to your profile, simply click on the Button below and follow the prompts to input the necessary information.'
              }
            </Typography>
            <Stack
              direction="row"
              spacing="12px"
              minHeight="54px"
              sx={{ overflowX: 'auto', overflowY: 'unset' }}
            >
              {accountOptions.map((option, index) => {
                const { id, status, SvgIconProps, AvatarProps, LinkProps } = option
                return (
                  <Link
                    key={id || index}             
                    color="secondary"
                    style={{ display: 'block' }}
                    disabled={!!status}
                    onClick={handleAccountOptionClick(option)}
                    {...LinkProps}
                  >
                    <ButtonBase disabled={!!status}>
                      <ImageBox
                        width="54px"
                        height="54px"
                        fontSize="24px"
                        bgcolor={status ? 'gray.700' : 'gray.800'}
                        color={status ? 'gray.600' : 'common.white'}
                      >
                        {SvgIconProps && SvgIconProps?.name && !AvatarProps && (
                          <SvgIcon
                            color="inherit"
                            sx={{ width: '24px', height: '24px' }}
                            {...SvgIconProps}
                          />
                        )}
                        {AvatarProps && AvatarProps?.src && !SvgIconProps && (
                          <Avatar
                            variant="square"
                            sx={{ width: '24px', height: '24px' }}
                            {...AvatarProps}
                          />
                        )}
                      </ImageBox>
                    </ButtonBase>
                  </Link>
                )
              })}
            </Stack>
            <Typography variant="bodySmall" color="gray.300">
              {
                "If you don't see any accounts that interest you abowe, don't worry! We update our selection regularly, so be sure to check back soon for new additions."
              }
            </Typography>
          </Stack>
        </Box>
        <Formik enableReinitialize initialValues={initValues} validationSchema={validSchema} onSubmit={onSubmit}>
          {({ values }) => (
            <Form>
              <Stack direction="column" spacing="18px">
                <FieldArray
                  name="accounts"
                  render={({ form }) => {
                    return values.accounts && values.accounts.length > 0
                      ? values.accounts.map((account, index) => {
                          let error = false
                          if (
                            Array.isArray(form?.errors?.accounts) &&
                            form.errors.accounts.length
                          ) {
                            // @ts-ignore
                            error = !!form.errors.accounts[index]?.value
                          }
                          return (
                            <div key={index}>
                              <AccountFieldArrayItem
                                name={`accounts.${index}.value`}
                                error={error}
                                onChangeClick={() => {
                                  form.setFieldValue('changedIndex', index)
                                  form.handleSubmit()
                                }}
                                {...account}
                              />
                              {index === 0 && (
                                <Typography
                                  variant="description"
                                  color="gray.500"
                                  ml="28px"
                                  mt="-6px"
                                >
                                  This is mandatory Linked Account, PlayFab account is used to
                                  authenticate the player and store their game data, and it is
                                  required for players to log into the Frontland game
                                </Typography>
                              )}
                            </div>
                          )
                        })
                      : null
                  }}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Box>
  )
}
