import React from 'react'
import { Box, BoxProps } from '@mui/material'
import MenuItemButton from 'src/components/MenuItemButton/MenuItemButton'

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export type TabsProps = {
  BoxProps?: BoxProps
  list: string[]
  value: number
  onChange: (value: number) => void
  showBorder?: boolean
}

export default function SimpleTabs({ list, value, onChange, showBorder, ...restProps }: TabsProps) {
  return (
    <Box
      {...restProps?.BoxProps}
      sx={{
        display: 'flex',
        ...(showBorder && {
          bgcolor: 'gray.900',
          border: '1px solid',
          borderColor: 'gray.700',
        }),
        ...restProps?.BoxProps?.sx,
      }}
    >
      {list.map((tab, index) => (
        <MenuItemButton
          key={tab}
          selected={index === value}
          align="center"
          onClick={() => onChange(index)}
          {...a11yProps(index)}
        >
          {tab}
        </MenuItemButton>
      ))}
    </Box>
  )
}

type SimpleTabsWrapperProps = React.PropsWithChildren & {
  BoxProps?: BoxProps
  showBorder?: boolean
}

export function SimpleTabsWrapper({ showBorder, children, ...restProps }: SimpleTabsWrapperProps) {
  return (
    <Box
      {...restProps?.BoxProps}
      sx={{
        ...(showBorder && {
          bgcolor: 'gray.900',
          border: '1px solid',
          borderColor: 'gray.700',
        }),
        ...restProps?.BoxProps?.sx,
      }}
    >
      {children}
    </Box>
  )
}

export type TournamentTabPanelProps = {
  BoxProps?: BoxProps
  children?: React.ReactNode
  index: number
  value: number
  transparent?: boolean
}

export function SimpleTabPanel({
  children,
  value,
  index,
  transparent,
  ...restProps
}: TournamentTabPanelProps) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...restProps?.BoxProps}
      sx={{
        ...(!transparent && {
          borderTop: '1px solid',
          borderColor: 'gray.700',
        }),
        ...restProps?.BoxProps?.sx,
      }}
    >
      {value === index && (
        <Box
          sx={{
            ...(transparent
              ? {
                  py: '22px',
                }
              : {
                  m: '1px',
                  bgcolor: 'gray.800',
                  p: { xs: '14px', sm: '24px' },
                }),
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}
