import React from 'react'
import { useTimer } from 'react-timer-hook'
import { Box, BoxProps, Stack } from '@mui/material'
import Comment from '../Comment/Comment'
import Typography from '../Typography/Typography'
import TitleWithDot from '../TitleWithDot/TitleWithDot'

type TimerProps = BoxProps & {
  expirySeconds?: number // Timer reference time in seconds
  onExpire?: () => void
}

export default function Timer({ expirySeconds = 1200, onExpire, ...restProps }: TimerProps) {
  const expiryDate: Date = new Date(new Date().setSeconds(new Date().getSeconds() + expirySeconds))

  // Start Function when timer is stopped, if needed
  const handleExpire = () => {
    if (onExpire && typeof onExpire === 'function') {
      onExpire()
    }
  }

  const {
    seconds,
    minutes,
    hours,
    days,
    // isRunning, // isRunning variable is needed if you need to display the text when the counting reaches zero
  } = useTimer({ expiryTimestamp: expiryDate, onExpire: handleExpire })

  return (
    <Box display="inline-block" {...restProps}>
      <Comment rootStackProps={{ mb: '-1px' }}>Timer</Comment>
      <Box
        display="inline-block"
        bgcolor="gray.900"
        border="1px solid"
        borderColor="gray.700"
        position="relative"
        height="82px"
        p="8px 20px 0 15px"
      >
        <Stack direction="row" alignItems="baseline">
          {days > 0 && (
            <>
              <Typography variant="h2" color="primary.light" mr="5px">
                {days >= 10 ? days : `0${days}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                d
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="15px" mr="21px">
                :
              </Typography>
            </>
          )}
          {hours > 0 && (
            <>
              <Typography variant="h2" color="primary.light" mr="5px">
                {hours >= 10 ? hours : `0${hours}`}
              </Typography>
              <TitleWithDot
                rootStackProps={{ spacing: '2px' }}
                typographyProps={{ variant: 'h7', color: 'gray.0' }}
                dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
              >
                hrs
              </TitleWithDot>
              <Typography alignSelf="center" variant="h6" color="gray.0" ml="15px" mr="21px">
                :
              </Typography>
            </>
          )}

          <Typography variant="h2" color="primary.light" mr="5px">
            {minutes >= 10 ? minutes : `0${minutes}`}
          </Typography>
          <TitleWithDot
            rootStackProps={{ spacing: '2px' }}
            typographyProps={{ variant: 'h7', color: 'gray.0' }}
            dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
          >
            min
          </TitleWithDot>
          <Typography alignSelf="center" variant="h6" color="gray.0" ml="15px" mr="21px">
            :
          </Typography>

          <Typography variant="h2" color="primary.light" mr="5px">
            {seconds >= 10 ? seconds : `0${seconds}`}
          </Typography>
          <TitleWithDot
            rootStackProps={{ spacing: '2px' }}
            typographyProps={{ variant: 'h7', color: 'gray.0' }}
            dotProps={{ width: '4px', height: '4px', bottom: '4px' }}
          >
            sec
          </TitleWithDot>
        </Stack>
      </Box>
      {/* {!isRunning && <Typography variant="h7" color="gray.0" mt='8px'>Time is up</Typography>} */}
    </Box>
  )
}
