import React, { useMemo, useState } from 'react'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Box, BoxProps as MuiBoxProps, Stack } from '@mui/material'
import * as schemas from 'src/helpers/schemas'
import Typography from 'src/components/Typography/Typography'
import FormikTextField from 'src/components/FormikFields/FormikTextField'
import FormikPasswordField from 'src/components/FormikFields/FormikPasswordField'
import Button from 'src/components/Button/Button'

type User = {
  username: string
  password: string
  newPassword?: string
  repeatPassword?: string
}

const defaultValidationSchema = Yup.object().shape({
  username: schemas.username(),
  newPassword: schemas.requiredPassword(),
  repeatPassword: schemas.repeatPassword(),
})

export type LoginInfoForm = {
  /** The root element of this component uses the BoxProps from Material UI  */
  BoxProps?: MuiBoxProps
  initialValues: User
  validationSchema?: typeof defaultValidationSchema
  onSubmit: (values: User, formikHelpers: FormikHelpers<User>) => void
}

export default function LoginInfoForm({
  BoxProps,
  initialValues,
  validationSchema = defaultValidationSchema,
  onSubmit,
}: LoginInfoForm) {
  const [isEdit, setIsEdit] = useState(false)

  const initValues: User = useMemo(
    () => ({
      ...initialValues,
      newPassword: '',
      repeatPassword: '',
    }),
    [initialValues]
  )

  const validSchema = useMemo(() => validationSchema, [validationSchema])
{/*
  const onEdit = () => {
    setIsEdit(true)
  }
*/}
  const onCancel = () => {
    setIsEdit(false)
  }

  const onEditCancel = (resetForm: FormikProps<User>['resetForm']) => () => {
    resetForm()
    onCancel()
  }

  const handleSubmit = async (values: User, formikHelpers: FormikHelpers<User>) => {
    const { username, password, newPassword } = values
    await onSubmit(
      {
        username,
        password: newPassword || password,
      },
      formikHelpers
    )
    onCancel()
  }

  return (
    <Box
      pt={{ xs: '16px', sm: '24px' }}
      px={{ xs: '16px', sm: '24px' }}
      pb="22px"
      bgcolor="gray.800"
      m="1px"
      {...BoxProps}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing="10px"
        mb="18px"
      >
        <Typography variant="h7" color="common.white">
          Login info
        </Typography>

        {/*
        {!isEdit && (
          <Link
            href="#"
            disabled={true}
            color="secondary"
            startIcon={<SvgIcon name="edit-line" />}
            onClick={onEdit}
          >
            edit
          </Link>
        )} 
        */}
      </Stack>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={validSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, resetForm }) => (
          <Form>
            <Stack direction="column" spacing="8px" pt="4px">
              <FormikTextField
                name="username"
                label="Visible Nickname"
                fullWidth
                disabled={!isEdit || isSubmitting}
              />
              {isEdit ? (
                <>
                  <FormikPasswordField
                    name="newPassword"
                    label="New Password"
                    fullWidth
                    disabled={isSubmitting}
                  />
                  <FormikPasswordField
                    name="repeatPassword"
                    label="Repeat Password"
                    fullWidth
                    disabled={isSubmitting}
                  />
                  <Box pb="14px">
                    <Stack direction="row" spacing="12px" mt="-4px">
                      <Button type="submit" size="small" disabled={!isValid || isSubmitting}>
                        Save changes
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={onEditCancel(resetForm)}
                        disabled={isSubmitting}
                      >
                        cancel
                      </Button>
                    </Stack>
                  </Box>
                </>
              ) : (
                <FormikPasswordField
                  name="password"
                  label="Password"
                  fullWidth
                  disabled={!isEdit}
                />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
