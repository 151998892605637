import React from 'react'
import { CardMedia, CardMediaProps } from '@mui/material'

export type VideoCardMediaProps = CardMediaProps &
  React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & {
    component?: HTMLVideoElement
    fill?: boolean
  }

export default function VideoCardMedia({ src, fill, sx, ...restProps }: VideoCardMediaProps) {
  return (
    <CardMedia
      component="video"
      src={src}
      autoPlay
      loop
      muted
      preload="auto"
      playsInline
      sx={{
        ...(fill && {
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'bottom',
        }),
        ...sx,
      }}
      {...restProps}
    />
  )
}
