import React, { useRef } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import Comment from 'src/components/Comment/Comment'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { debounce } from "lodash";
import * as schemas from 'src/helpers/schemas'
import { config } from "../../config/config";
import { saveTournament } from "../../sagas/apiSagas";
import {useAppDispatch} from "src/hooks";
//import { registrationResult } from "src/actions/userActions";
import { showErrorToast } from "src/components/Toast/Toasts";
import { showErrorPopup, showSuccessPopup } from "src/actions/dialogActions";
import Typography from 'src/components/Typography/Typography';
import TextField from 'src/components/TextField/TextField';
import Button from 'src/components/Button/Button';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import Select from 'src/components/Select/Select';
import { GameId, GameMode, GameModeFortnite, GameModeFrontland, TokenType, TournamentType } from 'src/helpers/types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { GameType, GameTypeFortnite, GameTypeFrontland } from 'src/utils/const';
import { formatTokenToStringWithSymbol } from 'src/utils/tokenUtil';


const ValidationSchema = yup.object().shape({
  name: schemas.requiredField(),
  description: schemas.requiredField(),
  fee: schemas.positiveNumericOrZeroField(),
  prizeFund: schemas.positiveNumericOrZeroField(),
  roundLength: schemas.positiveNumericField(),
  pauseLength: schemas.positiveNumericField(),
  minParticipants: schemas.positiveNumericField(),
  maxParticipants: schemas.positiveNumericField(),
});



export default function CreateTournamentForm() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    dayjs.extend(utc);

    // Make sure that the default date time is not changed when rerendering the component
    // and gets stuck in loop. To avoid validation errors, we set the default start time 1 minute in the future.
    const startTimeRef = useRef(dayjs.utc().add(1, 'minute'));

    const tournamentTypeOptions = Object.entries(TournamentType).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const gameOptions = Object.entries(GameId).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const gameTypeOptionsFrontland = Object.entries(GameTypeFrontland).map(([key, value]) => ({
      value: key,
      label: value,
    }));

    const gameTypeOptionsFortnite = Object.entries(GameTypeFortnite).map(([key, value]) => ({
      value: key,
      label: value,
    }));


    const gameModesOptionsFrontland = Object.entries(GameModeFrontland).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const gameModesOptionsFortnite= Object.entries(GameModeFortnite).map(([key, value]) => ({
      value: key,
      label: value,
  }));


    const tokenOptions = Object.entries(TokenType).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const gameModesByGame = {
      [GameId.FRONTLAND]: gameModesOptionsFrontland,
      [GameId.FORTNITE]: gameModesOptionsFortnite
      // ... add other games and their modes
    };

    const gameTypesByGame = {
      [GameId.FRONTLAND]: gameTypeOptionsFrontland,
      [GameId.FORTNITE]: gameTypeOptionsFortnite
      // ... add other games and their modes
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            description:'',
            type: TournamentType.SINGLE_ELIMINATION,
            gameId: GameId.FRONTLAND,
            gameType: GameType.ONE_VS_ONE,
            gameMode: GameMode.ROOF_TOP,
            tokenType: TokenType.WETH,
            fee: 10000000000000000,
            prizeFund: 0,
            prizeFundTokenType: TokenType.WETH,
            startTime: startTimeRef.current,
            minParticipants:4,
            maxParticipants:8,
            roundLength: 1200,
            pauseLength: 300,
        },
        validateOnChange: true,
        validateOnMount: true,
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
          //set seonds and miliseconds to 0 for startTime
          values.startTime = values.startTime.set('second', 0).set('millisecond', 0);  
          //console.log('onSubmit', values);
          formik.setStatus('');  
          formik.setSubmitting(true);  
               
          saveTournament(values)         
         
          .then((result: any) => {
            dispatch(showSuccessPopup('Tournament save success', 'You have successfully saved the tournament.'));
          })          
          .catch((error: { message: any; }) => {
            dispatch(showErrorPopup('Tournament save error', error.message));
            //showErrorToast('Sign up error', error.message);
            //formik.setStatus(error.message);
          })
          .finally(() => formik.setSubmitting(false)) 
        }
    });
    const selectedGameModes = gameModesByGame[formik.values.gameId] || [];
    const selectedGameTypes = gameTypesByGame[formik.values.gameId] || [];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box>
      <Comment>Create</Comment>
      <Stack
          direction="column"
          sx={{
            border: `1px solid ${theme.palette.gray[700]}`,
            padding: '1px',            
          }}
        >
         <form onSubmit={formik.handleSubmit}>

        <Stack
          direction="column"
          gap="5px"
          sx={{
            background: theme.palette.gray[800],
            padding: { xs: '18px 16px 28px', sm: '25px 23px 35px' },
            marginBottom: '2px',
          }}
        >
          <Typography
            variant="bodySmall"
            align="center"
            color={theme.palette.gray[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
              [theme.breakpoints.down('sm')]: {
                m: '0 auto 18px auto',
                width: '100%',
              },
            }}
          >
            Create a tournament
          </Typography>
	        <Typography  variant="bodyMedium"
            align="center"
            color={theme.palette.error[25]}
            sx={{
              m: '0 auto 22px auto',
              width: '358px',
            }}
            style={{color:'red'}}>
              
              {formik.status}
          </Typography>

         <Stack direction="row" spacing={8}>
          <Stack direction="column" spacing={2}>
                <TextField id="name" name="name" placeholder="Enter name" label="Name" size="large"
                        value={formik.values.name}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.name && Boolean(formik.errors.name))}
                        helperText={formik.touched.name && formik.errors.name !== undefined ? formik.errors.name : undefined }
                    />

                <TextField id="description" name="description" placeholder="Enter description" label="Description (Enter ${time} to insert running time)" size="large"
                        value={formik.values.description}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.description && Boolean(formik.errors.description))}
                        helperText={formik.touched.description && formik.errors.description !== undefined ? formik.errors.description : undefined }
                    />
                
                <Select id="type" name="type" label="Tournament type" size="large" 
                        value={formik.values.type} 
                        onChange={formik.handleChange} 
                        slotProps={{
                            input: {
                                id: 'type',
                                name: 'type',
                            }
                        }}   
                        options={tournamentTypeOptions} 
                        />
                <Select id="gameId" name="gameId" label="Game" size="large" 
                        value={formik.values.gameId} 
                        onChange={(e) => {
                          formik.handleChange(e);
                          const firstAvailableMode = gameModesByGame[e.target.value  as keyof typeof gameModesByGame] && gameModesByGame[e.target.value as keyof typeof gameModesByGame][0];
                          if (firstAvailableMode) {
                            formik.setFieldValue('gameMode', firstAvailableMode.value); // Set gameMode to the first available mode
                          } else {
                            formik.setFieldValue('gameMode', ''); // Reset gameMode if no modes are available
                          }
                          const firstAvailableType = gameTypesByGame[e.target.value  as keyof typeof gameTypesByGame] && gameTypesByGame[e.target.value as keyof typeof gameTypesByGame][0];
                          if (firstAvailableType) {
                            formik.setFieldValue('gameType', firstAvailableType.value); // Set gameType to the first available mode
                          } else {
                            formik.setFieldValue('gameType', ''); // Reset gameType if no modes are available
                          }
                        }}
                        slotProps={{
                            input: {
                                id: 'gameId',
                                name: 'gameId',
                            }
                        }}                        
                        options={gameOptions}
                        />
                <Select id="gameType" name="gameType" label="Game Type" size="large"
                        value={formik.values.gameType} 
                        onChange={formik.handleChange}  
                        slotProps={{
                            input: {
                                id: 'gameType',
                                name: 'gameType',
                            }
                        }}                        
                        options={selectedGameTypes}    
                        error={(formik.touched.gameType && Boolean(formik.errors.gameType))} 
                        />

                <Select id="gameMode" name="gameMode" label="Game Mode" size="large" 
                        value={formik.values.gameMode} 
                        onChange={formik.handleChange}  
                        inputProps={{
                            id: 'gameMode',
                            name: 'gameMode',
                        }}  
                        slotProps={{
                            input: {
                                id: 'gameMode',
                                name: 'gameMode',
                               
                            }
                        }}                            
                        options={selectedGameModes}    
                        error={(formik.touched.gameMode && Boolean(formik.errors.gameMode))}                   
                        />
                </Stack>
                <Stack direction="column" spacing={2}>
                <Select id="tokenType" name="tokenType" label="Fee Token type" size="large" 
                        value={formik.values.tokenType} 
                        onChange={formik.handleChange}   
                        slotProps={{
                            input: {
                                id: 'tokenType',
                                name: 'tokenType',
                            }
                        }}                         
                        options={tokenOptions}
                        />
                       
                <TextField id="fee" name="fee" placeholder="Enter fee" label={`Tournament fee (${formatTokenToStringWithSymbol(formik.values.fee, formik.values.tokenType)})`} size="large"
                        value={formik.values.fee}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.fee && Boolean(formik.errors.fee))}
                        helperText={formik.touched.fee && formik.errors.fee !== undefined ? formik.errors.fee : undefined }
                    />

                <Select id="prizeFundTokenType" name="prizeFundTokenType" label="Prize fund Token type" size="large" 
                        value={formik.values.prizeFundTokenType} 
                        onChange={formik.handleChange}   
                        slotProps={{
                            input: {
                                id: 'prizeFundTokenType',
                                name: 'prizeFundTokenType',
                            }
                        }}                         
                        options={tokenOptions}
                        />    

                <TextField id="prizeFund" name="prizeFund" placeholder="Prize fund" label={`Extra prize fund (${formatTokenToStringWithSymbol(formik.values.prizeFund, formik.values.prizeFundTokenType)})`} size="large"
                        value={formik.values.prizeFund}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.prizeFund && Boolean(formik.errors.prizeFund))}
                        helperText={formik.touched.prizeFund && formik.errors.prizeFund !== undefined ? formik.errors.prizeFund : undefined }
                    />
                <DateTimePicker ampm={false} disablePast
                        value={formik.values.startTime}
                        onChange={(value) => value && formik.setFieldValue('startTime',value)}  
                        slotProps={{
                            textField: {
                                id: 'startTime',
                                name: 'startTime',
   
                            },
                           
                        }}                       
                        label="StartTime (UTC)" 
                        
                        />   
                        <Box sx={{height:'113x'}}>
                          </Box>
                </Stack>
                <Stack direction="column" spacing={2}>
                <TextField id="minParticipants" name="minParticipants" placeholder="Enter min participants" label="Min participants" size="large"
                        value={formik.values.minParticipants}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.minParticipants && Boolean(formik.errors.minParticipants))}
                        helperText={formik.touched.minParticipants && formik.errors.minParticipants !== undefined ? formik.errors.minParticipants : undefined }
                        />
                <TextField id="maxParticipants" name="maxParticipants" placeholder="Enter max participants" label="Max participants" size="large"
                        value={formik.values.maxParticipants}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.maxParticipants && Boolean(formik.errors.maxParticipants))}
                        helperText={formik.touched.maxParticipants && formik.errors.maxParticipants !== undefined ? formik.errors.maxParticipants : undefined }
                        />
                <TextField id="roundLength" name="roundLength" placeholder="Enter round length" label={`Round length(s) ${formatSecondsToMinutesAndSeconds(formik.values.roundLength)}`} size="large"
                        value={formik.values.roundLength}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.roundLength && Boolean(formik.errors.roundLength))}
                        helperText={formik.touched.roundLength && formik.errors.roundLength !== undefined ? formik.errors.roundLength : undefined }
                        />
                <TextField id="pauseLength" name="pauseLength" placeholder="Enter pause length" label={`Pause length (s) ${formatSecondsToMinutesAndSeconds(formik.values.pauseLength)}`} size="large"
                        value={formik.values.pauseLength}
                        onChange={formik.handleChange}                       
                        error={(formik.touched.pauseLength && Boolean(formik.errors.pauseLength))}
                        helperText={formik.touched.pauseLength && formik.errors.pauseLength !== undefined ? formik.errors.pauseLength : undefined }
                        />
                </Stack>
            </Stack>
                <Typography
                variant="description"
                align="center"
                maxWidth={{ xs: '255px', sm: '270px' }}
                mx="auto"
                color={theme.palette.gray[200]}
                >
               Save tournament
                </Typography>
                </Stack>
                <Button variant="spaceBetween" size="large" endIcon={<SvgIcon name="arrow-right-line" sx={{ mr: '4px' }} />} type="submit" disabled={!formik.dirty || formik.isSubmitting} >
                <Typography variant="buttonMassive">Save</Typography>
            </Button>
            </form>
        </Stack>        
    </Box>
    </LocalizationProvider>
  )
}

function formatSecondsToMinutesAndSeconds(seconds:number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
}