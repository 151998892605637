import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFinishedChallenges } from '../../actions/adminActions';

var panelStyle = { paddingLeft: '20px', paddingRight: '20px' }
var linkStyle = { color: "white" };
var fontStyle = { fontSize: '60%'};

class FinishedChallenges extends React.Component {

    componentDidMount() {
        // calling the new action creator
        this.props.localGetFinishedChallenges();
    }

  

   
    render() {
        const { finishedChallenges } = this.props;

        return (
            <div>                
                <div className="container-fluid">
                    <h2 className="panel-title">Finished challenges ({finishedChallenges.length})</h2>
                    <div className="panel" style={panelStyle}>
                        <table className="table lobby-table table-hover table-striped match-history">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Error</th>
                                    <th>Created</th>
                                    <th>Last Updated</th>
                                    <th>Running Time</th>
                                    <th>Participant Count</th>
                                    <th>Run Count</th>
                                    <th>Timeout Count</th>
                                    <th>Prices Distributed</th>
                                    <th>Fee Collected</th>
                                    <th>Fee redfunded</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {typeof finishedChallenges === 'undefined' ? '' : (finishedChallenges.map((challenge, index) => (
                                    <tr key={challenge.challengeId}>
                                        <td style={fontStyle}>
                                            {challenge.challengeId}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.type}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.status}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.error ? challenge.errorDescription:'No'}
                                        </td>
                                        <td style={fontStyle}>
                                            {dateFormat(challenge.created)}
                                        </td>
                                        <td style={fontStyle}>
                                            {dateFormat(challenge.lastUpdated)}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.runningTime}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.participantCount}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.runCount}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.participantsEndedWithTimeoutCount}
                                        </td>
                                        <td style={fontStyle}> 
                                            {challenge.pricesDistributed ? 'Yes':'No'}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.feeCollected ? 'Yes' : 'No'}
                                        </td>
                                        <td style={fontStyle}>
                                            {challenge.feeRefunded ? 'Yes' : 'No'}
                                        </td>
                                        <td style={fontStyle}>
                                            <Link to={'/challenge/' + challenge.challengeId} style={linkStyle}>
                                                View
                                            </Link>
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

function dateFormat(date) {
    if (typeof date === 'undefined')
        return null;

    var played = new Date(date);

    var formated = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(played)

    return formated;
}



function mapStateToProps(state) {
    return {
        finishedChallenges: state.adminReducer.finishedChallenges,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
     
        localGetFinishedChallenges: () => dispatch(getFinishedChallenges()),
    };
}

const finishedChallenges = connect(mapStateToProps, mapDispatchToProps)(FinishedChallenges);
export { finishedChallenges as FinishedChallenges }; 