import { chain } from "lodash";

const initialState = {
    show: false,
    message: null,
    messageType: "ERROR",    //ERROR, INFO, SUCCESS
    displayType: "STICKY",   //STICKY, FLASH
    reconnect: false,
    showChangeChain: false,
    chainMessage: null,
    chainId: 0,
    chainSlug: null
};

export function infoPanelReducer(state = initialState, action) {
    switch (action.type) {
        case "SHOW_PANEL":
            return {
                ...state,
                show: true,
                message: action.message,
                messageType: action.messageType,
                displayType: action.displayType,
                reconnect: action.reconnect              
            }
        case "SHOW_CHANGE_CHAIN_PANEL":
            return {
                ...state,
                showChangeChain: true,
                chainMessage:  action.message,
                chainId: action.chainId,
                chainSlug: action.chainSlug
            }
        
        case "HIDE_PANEL":
            return {
                ...state,
                show: false,
                message: null,
                messageType: "ERROR",
                displayType: "STICKY",
                reconnect: false,
            }
        case "HIDE_CHANGE_CHAIN_PANEL":
            return {
                ...state,
                showChangeChain: false,
                chainMessage: null,
                chainId: 0,
                chainSlug: null
            }
   
        default:
            return state;
    }
}