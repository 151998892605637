import React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import VerticalDividerWithContent from 'src/components/VerticalDividerWithContent/VerticalDividerWithContent'
import GameLocationsDivider from 'src/components/GameLocationsDivider/GameLocationsDivider'
import CustomHorizontalDivider from 'src/components/CustomHorizontalDivider/CustomHorizontalDivider'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import PatternLines from 'src/components/PatternLines/PatternLines'
import { gameLocationsDataSource } from 'src/helpers/mockData'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '../Button/Button'

type MainBannerProps = {
  label?: string
  icon?: React.ReactNode
}

export default function MainBanner({
  icon = <SvgIcon name="arrow-right-up-line" />,
}: MainBannerProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
   

  return (
    <Box
      position="relative"
      bgcolor="gray.900"
      minHeight="238px"
      sx={{
        [theme.breakpoints.down('lg2')]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down('md')]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
        },
      }}
    >
      <PatternLines
        {...(isLgView && {
          ml: { xs: 'calc(-100vw - 8px)', sm: 'calc(-100vw - 12px)' },
          height: '238px',
        })}
      />
      <Box pb={{ xs: '28px', sm: '30px', lg: '24px' }}>
        <CustomHorizontalDivider />
      </Box>
      <Grid container justifyContent="space-between">
        <Grid
          item
          sx={{
            [theme.breakpoints.down('lg2')]: {
              width: '100%',
            },
          }}
        >
          <Box display="inline-block">
            <Box mb={{ xs: 0, sm: '14px', lg: '13px' }}>
              <Typography
                component="div"
                variant={isSmView ? 'labelMedium' : 'labelBig'}
                mb={{ xs: '1px', sm: '2px', lg: '3px' }}
                height="31px"
                color="primary.light"
              >
                {'Admin login only'}
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  height: '72px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '64px',
                    height: '57px',
                  },
                }}
              >
                {'Challenge Admin'}
              </Typography>
            </Box>
           
          </Box>
        </Grid>     
      </Grid>
    </Box>
  )
}
