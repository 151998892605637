import React, { useMemo } from 'react'
import { Stack, Box, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type VerticalDividerWithContentProps = {
  /** The divider position, if false, shows the divider from the left */
  fromRight?: boolean
  /** The content of the component */
  children?: React.ReactNode | (() => React.ReactNode)
  rootStackProps?: StackProps
  typographyProps?: TypographyProps
  /** The height of the divider */
  dividerHeight?: number | string
}

export default function VerticalDividerWithContent({
  fromRight = false,
  children,
  rootStackProps,
  typographyProps,
  dividerHeight = '24px',
}: VerticalDividerWithContentProps) {
  const verticalDivider = (
    <Stack direction="column" alignItems="center" height={dividerHeight}>
      <Box minWidth="3px" minHeight="3px" bgcolor="gray.500" />
      <Box minWidth="1px" height="100%" bgcolor="gray.600" />
      <Box minWidth="3px" minHeight="3px" bgcolor="gray.500" />
    </Stack>
  )

  const content = useMemo(
    () =>
      typeof children === 'function' ? (
        children()
      ) : children ? (
        <Typography variant="uiElement" color="gray.500" {...typographyProps}>
          {children}
        </Typography>
      ) : null,
    [children, typographyProps]
  )

  return (
    <Stack direction="row" spacing="8px" alignItems="center" {...rootStackProps}>
      {fromRight ? (
        <>
          {content}
          {verticalDivider}
        </>
      ) : (
        <>
          {verticalDivider}
          {content}
        </>
      )}
    </Stack>
  )
}
