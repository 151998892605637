import React from 'react'
import { Box, BoxProps } from '@mui/material'

export type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> &
  BoxProps & {
    ref?: React.RefObject<HTMLImageElement>
    fill?: boolean
  }
export default function Image({ fill, ...props }: ImageProps) {
  let style = {}

  if (fill) {
    style = {
      ...style,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }
  }

  const { sx, ...restProps } = props || {}

  return <Box component="img" {...props} {...restProps} sx={{ ...style, ...sx }} />
}
