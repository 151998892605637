import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import PageContainer from 'src/components/PageContainer/PageContainer'
import ChallengeDetailsCard from 'src/components/ChallengeDetailsCard/ChallengeDetailsCard'
import LiveUpdateList from 'src/components/LiveUpdateList/LiveUpdateList'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import TitleWithLoader from 'src/components/TitleWithLoader/TitleWithLoader'
import WinnerLobbyBox from 'src/components/WinnerLobbyBox/WinnerLobbyBox'
import PageMediaWithShadowBox from 'src/components/PageMediaWithShadowBox/PageMediaWithShadowBox'
import CountTotalWithLabel from 'src/components/CountTotalWithLabel/CountTotalWithLabel'
import Timer from 'src/components/Timer/Timer'
import TableEnemyWithScroll from 'src/components/TableEnemyWithScroll/TableEnemyWithScroll'
import Typography from 'src/components/Typography/Typography'

/**
 * The FortniteLobby props are temporarily here only for the storybook to be able to show the Fortnite page.
 */

export default function FortniteLobby({
  status,
  pageMediaWithShadowBoxProps,
  loading,
  title,
  subtitle,
  timerExpirySeconds,
  winnerList,
  players,
  table,
  challengeDetailsCardProps,
  liveUpdateList,
  refund
}: any) {
  const navigate = useNavigate()
  const theme = useTheme()
  // const params = useParams<{ gameId: string }>()

  return (
    <Box bgcolor="gray.900" position="relative">
      <PageMediaWithShadowBox {...pageMediaWithShadowBoxProps} />
      <PageContainer
        sx={{
          minHeight: '398px',
          pt: '18px',
          display: 'flex',
          [theme.breakpoints.down('sm2')]: {
            pt: '20px',
            position: 'relative',
            top: '8px',
          },
        }}
        innerContainerProps={{
          sx: { display: 'flex', flexDirection: 'column', position: 'relative' },
        }}
      >
        <>
          <Box position="absolute" top={{ xs: '0' }} left={0}>
            <ButtonIcon
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(-1)
              }}
            >
              <SvgIcon name="arrow-left-line" />
            </ButtonIcon>
          </Box>
          <Box pt={{ xs: '8px', sm: '13px', md: '7px' }}>
            <TitleWithLoader
              loading={loading}
              title={title}
              titleTypographyProps={{
                maxWidth: '411px',
                sx: {
                  height: '31px',
                  alignItems: 'center',
                },
              }}
            >
              {subtitle && <Typography variant="bodySmall">{subtitle}</Typography>}
              {(status === 'completed' || winnerList?.length>0) && (
                 <Stack
                  direction="column"
                  spacing={{ xs: '4px', sm: '8px' }}
                  pt={{ xs: '22px', sm: 0 }}
                  pb={{ xs: '35px', sm: '75px', md: '38px' }}
                >
                  {/*@ts-ignore*/}
                  {winnerList.map(({ place, name, amount, isVertical, avatarSrc }) => (
                    <WinnerLobbyBox
                      key={name}
                      place={place}
                      name={name}
                      amount={amount}
                      isVertical={isVertical}
                      avatarSrc={avatarSrc}
                    />
                  ))}
                </Stack>
              )}
              {(status === 'progress' || timerExpirySeconds) && (
                <Timer
                  expirySeconds={timerExpirySeconds}
                  pt={{ xs: '1px', sm: '8px', md: 0 }}
                  pb={{ xs: '42px', sm: '46px', md: '6px' }}
                />
              )}
            </TitleWithLoader>
          </Box>
          <Stack
            direction="column"
            minWidth="630px"
            m="0 auto"
            mt={loading ? '77px' : '0px'}
            sx={{
              [theme.breakpoints.down('md')]: {
                mt: loading ? '125px' : '0px',
              },
              [theme.breakpoints.down('sm2')]: {
                mt: loading ? '122px' : '0px',
                minWidth: 'auto',
                maxWidth: '630px',
                width: '100%',
              },
            }}
          >
            <CountTotalWithLabel
              count={players?.ready}
              total={players?.total}
              label="Players"
              mb={{ xs: '12px', sm2: '23px' }}
            />
            <TableEnemyWithScroll {...table} refund={refund} />
          </Stack>
        </>
      </PageContainer>
      <Box
        pt={{ xs: '44px', sm: '24px', md: status === 'completed' ? '44px' : '63px' }}
        pb={{ xs: '24px', sm: '24px', md: '48px' }}
        position="relative"
        zIndex={1}
      >
        <PageContainer>
          <Grid
            container
            gap="30px"
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: '16px',
              },
            }}
          >
            <Grid
              item
              sx={{
                [theme.breakpoints.down('md')]: {
                  order: 2,
                  maxHeight: '343px',
                },
              }}
            >
              <ChallengeDetailsCard {...challengeDetailsCardProps}  />
            </Grid>
            <Grid
              item
              xs
              sx={{
                [theme.breakpoints.down('md')]: {
                  order: 1,
                },
              }}
            >
              <LiveUpdateList list={liveUpdateList} />
            </Grid>
          </Grid>
        </PageContainer>
      </Box>
    </Box>
  )
}
