import React, { useMemo } from 'react'
import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

type TitleWithDotProps = {
  /** The content of the component */
  children?: React.ReactNode | (() => React.ReactNode)
  rootStackProps?: StackProps
  typographyProps?: TypographyProps
  dotProps?: BoxProps
}

export default function TitleWithDot({
  children,
  rootStackProps,
  typographyProps,
  dotProps,
}: TitleWithDotProps) {
  const content = useMemo(
    () =>
      typeof children === 'function' ? (
        children()
      ) : children ? (
        <Typography variant="h5" color="common.white" {...typographyProps}>
          {children}
        </Typography>
      ) : null,
    [children, typographyProps]
  )

  return (
    <Stack direction="row" spacing="12px" {...rootStackProps}>
      {content}
      {content && (
        <Box
          width="8px"
          height="8px"
          bgcolor="primary.main"
          alignSelf="end"
          position="relative"
          bottom="9px"
          {...dotProps}
        />
      )}
    </Stack>
  )
}
