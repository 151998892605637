export function clear() {
    return { type: "CLEAR_USER" };
}

export function logout() {
    return { type: "LOGOUT_USER" };
}

export function login(username, password) {
    return { type: "LOGIN_USER", username: username, password: password };
}

export function forgotPassword(email) {
    return { type: "FORGOT_PASSWORD", email: email };
}

export function resetPassword(id, code, password) {
    return { type: "RESET_PASSWORD", id: id, code: code, password: password };
}

export function getUser() {
    return { type: "GET_USER", forceRefresh : false};
}

export function refreshUser() {
    return { type: "GET_USER", forceRefresh: true };
}

export function registerUser(firstName, lastName, countryIso, username, password, confirmPassword) {
    return { type: "REGISTER_USER", firstName: firstName, lastName: lastName, countryIso: countryIso, username: username, password: password, confirmPassword: confirmPassword};
}

export function registerUserStepTwo(firstName, lastName, address1, address2, zip, city, state, countryIso) {
    return { type: "REGISTER_USER_STEP_TWO", firstName: firstName, lastName: lastName, address1: address1, address2: address2, zip: zip, city: city, state: state, countryIso: countryIso };
}

export function registerUserStepThree(email, password) {
    return { type: "REGISTER_USER_STEP_THREE", email: email, password: password}
}

export function epicVerification2FA(code) {
    return { type: "EPIC_VERIFICATION_2FA", code: code}
}

export function epicVerificationCaptcha(code) {
    return { type: "EPIC_VERIFICATION_CAPTCHA", code: code }
}

export function verifyEmail(id, code) {
    return { type: "VERIFY_EMAIL", id: id, code: code }
}

export function displayError(e) {
    return { type: "ERROR_CODE", e}
}

export function getTransactions() {
    return { type: "GET_TRANSACTIONS" }
}

export function updateUser(firstName, lastName, address1, address2, zip, city, state, countryIso) {
    return { type: "UPDATE_USER", firstName: firstName, lastName: lastName, address1: address1, address2: address2, zip: zip, city: city, state: state, countryIso: countryIso };
}

export function uploadProfilePicture(file) {
    return { type: "UPLOAD_PROFILE_PICTURE", file: file };
}

export function deposit(amount, transactionFee) {
    return { type: "DEPOSIT", amount: amount, transactionFee: transactionFee };
}

export function verifyDeposit(orderId,payerId) {
    return { type: "VERIFY_DEPOSIT", orderId: orderId, payerId: payerId };
}

export function cancelDeposit(orderId) {
    return { type: "CANCEL_DEPOSIT", orderId: orderId};
}

export function withdraw(amount, email) {
    return { type: "WITHDRAW", amount: amount, email: email };
}

export function loginWallet(from) {
    return { type: "LOGIN_USER_WALLET", from: from };
}

export function updateBalance() {
    return { type: "UPDATE_BALANCE"};
}

