import React from 'react'
import { Box, Stack, StackProps as MuiStackProps } from '@mui/material'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'
import FormikTextField from 'src/components/FormikFields/FormikTextField'
import Button from 'src/components/Button/Button'
import { Statuses, Status } from 'src/components/LinkedAccounts/LinkedAccounts'
import Typography from 'src/components/Typography/Typography'
import Link, { LinkProps } from 'src/components/Link/Link'

export type AccountItem = Omit<AccountFieldArrayItemProps, 'name' | 'onSave'> & {
  value: string
}

export type AccountFieldArrayItemProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  name: string
  error?: boolean
  disabled?: boolean
  status?: Status
  /**
   * [Props applied to the SvgIcon element.](/?path=/docs/components-svgicon--colors)
   * */
  SvgIconProps?: SvgIconProps
  /**
   * [Props applied to the Link element.](/?path=/docs/components-link--primary)
   * */
  LinkProps: LinkProps
  onChangeClick?: () => void
}

export default function AccountFieldArrayItem({
  StackProps,
  name,
  error,
  disabled,
  status = Statuses.CONNECTED,
  SvgIconProps,
  LinkProps,
  onChangeClick,
}: AccountFieldArrayItemProps) {
  const handleChange = () => {
    if (!error && onChangeClick) {
      onChangeClick()
    }
  }
  const isProcess = status === Statuses.PROCESS
  const isConnected = status === Statuses.CONNECTED

  return (
    <Stack direction="row" spacing="4px" alignItems="center" {...StackProps}>
      <Box color="gray.500" display="flex">
        <SvgIcon name="play-fab" color="inherit" sx={{ mb: '11px' }} {...SvgIconProps} />
      </Box>
      <FormikTextField
        name={name}
        disabled
        fullWidth
        endAdornment={
          <Box fontSize="18px" color="gray.100">
            {!error && isConnected && (
              <SvgIcon name="check-fill" fontSize="inherit" color="primary" />
            )}
            {isProcess && (
              <Stack
                direction="row"
                spacing="2px"
                alignItems="center"
                color="inherit"
                minWidth="83px"
              >
                <Typography variant="bodySmall" color="inherit">
                  In process
                </Typography>
                <SvgIcon name="time-line" fontSize="inherit" color="inherit" />
              </Stack>
            )}
          </Box>
        }
      />
      <Box minWidth="86px">
        <Link
          color="secondary"
          style={{ display: 'block' }}
          disabled={disabled || isProcess || error}
          onClick={handleChange}
          {...LinkProps}
        >
          <Button
            variant="outlined"
            size="small"
            disabled={disabled || isProcess || error}
            sx={{ mb: '14px' }}
            fullWidth
          >
            Change
          </Button>
        </Link>
      </Box>
    </Stack>
  )
}
