import { toast } from "src/components/Notification/Notification";
import { ToastOptions } from 'react-toastify'


export function showErrorToast(message:string, description:string, options?: ToastOptions) {
    toast.error({title: message, description});   
} 

export function showSuccessToast(message:string, description:string, options?: ToastOptions) {
    toast.success({title: message, description});
}

export function showInfoToast(message:string, description:string, options?: ToastOptions) {
    toast.warning({title: message, description});
}

export function showServiceToast(message:string) {
    toast.serviceMessageInfo({message: message});
}