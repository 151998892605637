import React from 'react'
import { Box, Grid } from '@mui/material'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PatternLines from 'src/components/PatternLines/PatternLines'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'

const NotFound = ({ }) => (

    <Box bgcolor="gray.900" position="relative">
        <PageContainer sx={{ pt: '17px' }}
        innerContainerProps={{
          sx: { position: 'relative' },
        }}
        >
        <PatternLines height="262px" top="9px" />
        <TitleWithDot
          typographyProps={{ variant: 'h2' }}
          dotProps={{ bottom: '16px' }}
          rootStackProps={{ spacing: '10px', mb: '23px' }}
        >
          Page not found
        </TitleWithDot>
        </PageContainer>
    </Box>       
)

export default NotFound