import { useState, useEffect, SetStateAction } from 'react';
import { ethers } from 'ethers';

const useChainChange = (): bigint | null => {
  const [chainId, setChainId] = useState<bigint | null>(null);

  useEffect(() => {
    const { ethereum } = window as any;

    if (!ethereum) {
      console.error("Ethereum object not found, make sure you have MetaMask installed.");
      return;
    }

    const provider = new ethers.BrowserProvider(ethereum);

    const handleChainChanged = (chainIdHex: string) => {
      const chainId = parseInt(chainIdHex, 16);
      setChainId(BigInt(chainId));
      console.log(`Chain changed to ${chainId}`);
    };

    provider.getNetwork().then((network) => {
      setChainId(network.chainId);
    });

    ethereum.on('chainChanged', handleChainChanged);

    return () => {
      if (ethereum.removeListener) {
        ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  return chainId;
};

export default useChainChange;