import React from 'react'
import { Avatar, Stack } from '@mui/material'
import { WinnerLobbyBoxProps } from 'src/components/WinnerLobbyBox/WinnerLobbyBox'
import Typography from 'src/components/Typography/Typography'
import WinnerBox from 'src/components/WinnerBox/WinnerBox'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import WinnerPlaceNum from 'src/components/WinnerPlaceNum/WinnerPlaceNum'
import GradientBorder from '../GradientBorder/GradientBorder'

type VerticalWinnerBoxProps = Omit<WinnerLobbyBoxProps, 'isVertical'>

export default function VerticalWinnerBox({
  avatarSrc,
  name,
  place = 1,
  amount,
}: VerticalWinnerBoxProps) {
  return (
    <Stack direction="column" width="149px" spacing="2px">
      <GradientBorder place={place}>
        <WinnerBox
          height="172px"
          pt="23px"
          px="2px"
          pb="13px"
          display="flex"
          alignItems="center"
          flexDirection="column"
          place={place}
        >
          <Stack direction="column" alignItems="center" spacing="18px">
            <Avatar
              src={avatarSrc}
              alt="Winner"
              variant="square"
              sx={{ width: '68px', height: '68px', border: '1px solid', borderColor: 'gray.600' }}
            />
            <Typography
              variant="labelSmall"
              color="gray.25"
              minHeight="26px"
              display="flex"
              alignItems="center"
            >
              {name}
            </Typography>
          </Stack>
          <WinnerPlaceNum mt="2px" />
        </WinnerBox>
      </GradientBorder>

      <GradientBorder place={place}>
        <WinnerBox
          height="76px"
          py="7px"
          px="11px"
          borderColor="gray.800"
          display="flex"
          alignItems="center"
          place={place}
        >
          <CoinImageWithAmount
            spacing="18px"
            avatarProps={{ sx: { width: '28px', height: '28px' } }}
            amountTypographyProps={{
              children: (
                <Stack direction="column">
                  <Typography variant="paragraphMedium" color="gray.500">
                    Receive
                  </Typography>
                  <Typography variant="h6" color="gray.0">
                    {amount}
                  </Typography>
                </Stack>
              ),
            }}
          />
        </WinnerBox>
      </GradientBorder>
    </Stack>
  )
}
