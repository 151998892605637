import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from "../reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from 'history'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { config, EnvironmentType } from "../config/config";

import rootSaga from "../sagas/rootSaga";
import { apiStakingSlice } from 'src/api/stakingApi';

export const history = createBrowserHistory();

//To apply enhancers see https://www.npmjs.com/package/@redux-devtools/extension
const initialiseSagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createRouterMiddleware(history), initialiseSagaMiddleware, apiStakingSlice.middleware),
    devTools: config.environment!==EnvironmentType.PRODUCTION,
});

initialiseSagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch