import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Stack,
  Box,
  StackProps as MuiStackProps,
  Avatar,
  useTheme,
  useMediaQuery,
  Breakpoint,
} from '@mui/material'
import Typography from 'src/components/Typography/Typography'
import Link from 'src/components/Link/Link'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export type UploadAvatarValue = {
  url: string
  file: File | null
}

export type UploadAvatarProps = {
  /** The root element of this component uses the StackProps from Material UI  */
  StackProps?: MuiStackProps
  /**
   * User name.
   * */
  name: string
  /**
   * Callback fired when the value is changed.
   *
   * @param {string} value.url - The user src link.
   * @param {(File|null)} value.file - The uploaded file.
   * */
  onUploadChange?: (value: UploadAvatarValue) => void
  breakpoint?: Breakpoint
}

export default function UploadAvatar({
  StackProps,
  name,
  onUploadChange,
  breakpoint = 'sm2',
}: UploadAvatarProps) {
  const [value, setValue] = useState<UploadAvatarValue>({ url: '', file: null })

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]

      if (file) {
        getBase64(file, (url) => {
          const newValue = { url, file }
          setValue(newValue)
          if (onUploadChange) {
            onUploadChange(newValue)
          }
        })
      }
    },
    [onUploadChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: false,
    onDrop,
  })

  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down(breakpoint))

  return (
    <Stack
      direction={isMobileView ? 'row' : 'column'}
      gap={isMobileView ? '16px' : '8px'}
      height="213px"
      p="3px"
      alignItems="center"
      justifyContent="center"
      {...StackProps}
      sx={{
        [theme.breakpoints.down('sm2')]: {
          p: '16px',
          justifyContent: 'flex-start',
          height: '80px',
          bgcolor: theme.palette.gray[800],
          mb: '1px',
        },
        ...StackProps?.sx,
      }}
    >
      <Avatar
        variant="square"
        alt="User"
        src={value.url || ''}
        sx={{
          width: '84px',
          height: '84px',
          border: '1px solid',
          borderColor: 'gray.600',
          [theme.breakpoints.down('sm2')]: {
            width: '48px',
            height: '48px',
          },
        }}
      />
      <Stack direction="column" gap={isMobileView ? '6px' : '8px'}>
        <Box display="flex" order={isMobileView ? 2 : 1} {...getRootProps()}>
          <input {...getInputProps()} />
          {/*<Link
	    disabled={false}
            onClick={(event => event.preventDefault())}
            href="#"
            size="small"
            color="secondary"
            startIcon={<SvgIcon name="image-edit-line" />}
          >
            change avatar
      </Link> */}
        </Box>
        <Typography
          variant="labelSmall"
          color="gray.100"
          height="26px"
          display="flex"
          alignItems="center"
          order={isMobileView ? 1 : 2}
        >
          {name}
        </Typography>
      </Stack>
    </Stack>
  )
}
