export function getGeneralStatistics() {
    return { type: "GET_GENERAL_STATISTICS" };
}

export function getGameTemplates() {
    return { type: "GET_GAME_TEMPLATES" };
}

export function updatedGameTemplate(gametemplate) {
    return { type: "UPDATED_GAME_TEMPLATE", updatedGameTemplate: gametemplate };
}

export function getUsers() {
    return { type: "GET_USERS" };
}

export function getUser(id) {
    return { type: "GET_USER", id:id };
}

export function getMatchesPlayed(id) {
    return { type: "GET_MATCHES_PLAYED", id:id };
}

export function updateUser( values ) {
    return { type: "UPDATE_USER", values };
}

export function giveUserPromotion(id, amount) {
    return { type: "GIVE_USER_PROMOTION", id:id, amount:amount };
}

export function filterUsers(filter,page,pageSize) {
    return { type: "FILTER_USERS", filter:filter, page:page, pageSize:pageSize };
}

export function refundChallengeFee(challengeId, userId) {
    return { type: "REFUND_CHALLENGE_FEE", challengeId:challengeId, userId:userId };
}

export function createCashDiscountCode(amount, description) {
    return { type: "CREATE_CASH_DISCOUNT_CODE", amount: amount, description: description };
}

export function getDiscountCodes() {
    return { type: "GET_DISCOUNT_CODES" };
}

export function updateDiscountCode(id, status, amount, description) {
    return { type: "UPDATE_DISCOUNT_CODE", id, status, amount, description };
}

export function deleteDiscountCode(id) {
    return { type: "DELETE_DISCOUNT_CODE", id };
}

export function getRunningChallenges() {
    return { type: "GET_RUNNING_CHALLENGES" };
}

export function getFinishedChallenges() {
    return { type: "GET_FINISHED_CHALLENGES" };
}

export function getAvailableChallenges() {
    return { type: "GET_AVAILABLE_CHALLENGES" };
}

export function getChallenge(id) {
    return { type: "GET_CHALLENGE", id:id };
}

export function updateChallenge(id, fee, maxParticipans,disabled) {
    return { type: "UPDATE_CHALLENGE", id: id, fee: fee, maxParticipans: maxParticipans, disabled: disabled };
}

export function getLogs() {
    return { type: "GET_LOGS" };
}

export function getPayments() {
    return { type: "GET_PAYMENTS" };
}

export function getWithdrawals() {
    return { type: "GET_WITHDRAWALS" };
}

export function updateWithdrawal(id,status,reason) {
    return { type: "UPDATE_WITHDRAWAL", id, status, reason };
}

export function getServiceStatus() {
    return { type: "GET_SERVICE_STATUS" };
}

export function setServiceText(text) {
    console.log("sef");
    return { type: "SET_SERVICE_TEXT", text:text };
}

export function setServiceStatus(status) {
    return { type: "SET_SERVICE_STATUS", status: status};
}

export function cancelChallengeJob(id) {
    return { type: "CANCEL_CHALLENGE_JOB", id: id };
}

export function addChallengeCoin() {
    return { type: "ADD_CHALLENGE_COIN" };
}

export function getOwnerBalance() {
    return { type: "OWNER_BALANCE" };
}

export function withdrawToken(amount) {
    return { type: "WITHDRAW_TOKEN", amount: amount};
}