import { ChallengeEventType } from 'src/helpers/types'

const initialState = {
   /* freeChallenges: [],
    isFreeChallengesDisabled: false,
    bigBucksChallenges: [],
    isBigBucksChallengesDisabled: false,
    majorMayhemChallenges: [],
    isMajorMayhemChallengesDisabled: false,
    specialChallenges: [],
    isSpecialChallengesDisabled: false,*/
    activeChallenges: [],
    activeChallengeStatus: 'NOT_LOADED',
    challengeInfo: {
        challenge: undefined,
        personalChallengeInfo: undefined,
    }
};

export function challengeReducer(state = initialState, action) {
    switch (action.type) {
        case "LOBBIES_INFO":
            console.log("Lobbies info recieved!!");
            return {
                ...state,
                activeChallenges: action.challenges,
                activeChallengeStatus: 'LOADED',
            };
        case "DUMMY_NEEDED_FOR_TYPESCRIPT_CHECK_TO MAKE_CHALLENGE_UPDATE_WORK":
            console.log("Never get here!");
            return {
                ...state,
                availableChallenges: action.challenges,
            };
        case "LOBBY_INFO":
            console.log("Lobby info recieved!!");

            var newCollection;    
            if (state.activeChallenges.findIndex(x => x.id === action.challenge.id) !== -1) {
                //exists
                newCollection = state.activeChallenges.map((item, index) => {
                    if (item.id === action.challenge.id) {
                        return action.challenge;
                    }
                    return item;
                });
            }
            else {
                //new
                newCollection = [...state.activeChallenges, action.challenge];
            }

            return {
                ...state,
                activeChallengeStatus: 'LOADED',
                activeChallenges: newCollection,
                
            };
        case "LOBBY_NOT_FOUND": 
            return {
                ...state,
                activeChallengeStatus: 'NOT_FOUND',
            };
        case "CLEAR_ACTIVE_LOBBY":
            const challengeIdToRemove = action.id;

            return {
                ...state,
                activeChallenges: state.activeChallenges.filter((item, index) =>
                    item.id !== challengeIdToRemove  //Remove with id
                ),
            };       
        case "AVAILABLE_CHALLANGES": 
            console.log("Available challanges recieved");
            return Object.assign({}, state,
                {
                    availableChallenges: action.challenges,

/*                    freeChallenges: action.challenges.freeChallenges,
                    isFreeChallengesDisabled: action.challenges.isFreeChallengesDisabled,
                    bigBucksChallenges: action.challenges.bigBucksChallenges,
                    isBigBucksChallengesDisabled: action.challenges.isBigBucksChallengesDisabled,
                    majorMayhemChallenges: action.challenges.majorMayhemChallenges,
                    isMajorMayhemChallengesDisabled: action.challenges.isMajorMayhemChallengesDisabled,*/
                });
        case "AVAILABLE_SPECIAL_CHALLANGES":
            return {
                ...state,
              specialChallenges: action.challenges,
            };

        case "CHALLENGE_HISTORY":
            const userchallenge = action.challenge.participants.find(me => me.userId === action.userId);

            return {
                ...state,
                challengeInfo: {
                    challenge: action.challenge,
                    personalChallengeInfo: action.challenge.error ? action.challenge.errorDescription : (typeof userchallenge !== 'undefined' ? (userchallenge.error ? userchallenge.errorDescription : null) : null),
                }
            };
        case "CLEAR_CHALLENGE_HISTORY":
            return {
                ...state,
                challengeInfo: {
                    challenge: undefined,
                    personalChallengeInfo: undefined
                }
            };
        case "CHALLENGE_STATUS_UPDATE":
            const updateStatusForId = action.challengeId;
            return {
                ...state, // copy the state (level 0)
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === updateStatusForId) {
                        return {
                            ...item,
                           status: action.status
                        };
                    }
                    return item;
                }),
            }    
        case "CHALLENGE_UPDATE":
            console.log("Challenge update recieved");
            const id = action.challengeUpdate.challengeId;
            const updatedUserId = action.userId;
            console.log("Id=" + id + " UserId=" + updatedUserId);
                               
            let newState = {
                ...state, // copy the state (level 0)
                availableChallenges: state.availableChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserDisplayName : null) : item.influenserDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating
                        };
                    }
                    return item;
                }),
                /*
                bigBucksChallenges: state.bigBucksChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserDisplayName : null) : item.influenserDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating
                        };
                    }
                    return item;
                }),
                majorMayhemChallenges: state.majorMayhemChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            hasInfluenser: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? action.challengeUpdate.hasInfluenser : item.hasInfluenser,
                            influenserDisplayName: typeof action.challengeUpdate.hasInfluenser !== 'undefined' ? (action.challengeUpdate.hasInfluenser ? action.challengeUpdate.influenserDisplayName : null) : item.influenserDisplayName,
                            numberOfParticipants: action.challengeUpdate.participantCount,
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating
                        };
                    }
                    return item;
                }),
                specialChallenges: state.specialChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isParticipating: (typeof updatedUserId !== 'undefined' && updatedUserId === action.challengeUpdate.userId) ? action.challengeUpdate.userIsParticipating : item.isParticipating
                        };
                    }
                    return item;
                })*/
            };
            return newState;
           
        case "CHALLENGE_REMOVE":
            const idToRemove = action.id;
            console.log("Id=" + idToRemove);
            return {
                ...state, // copy the state (level 0)
                availableChallenges: state.availableChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                /*
                bigBucksChallenges: state.bigBucksChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                ),
                majorMayhemChallenges: state.majorMayhemChallenges.filter((item, index) =>
                    item.id !== idToRemove  //Remove with id
                )*/
            };
        case "CHALLENGE_ADD":
            let addedState = { ...state };
            //if (action.newChallenge.type === 'FREE') {
                addedState.availableChallenges = [...state.availableChallenges, action.newChallenge];
           // }
          /*  if (action.newChallenge.type === 'BIG_BUCKS') {
                addedState.bigBucksChallenges = [...state.bigBucksChallenges, action.newChallenge];
            }
            if (action.newChallenge.type === 'MAJOR_MAYHEM') {
                addedState.majorMayhemChallenges = [...state.majorMayhemChallenges, action.newChallenge];
            }*/
            return addedState;

        case "JOINED_CHALLENGE":
            const joinedChallengeId = action.joinedChallenge.challengeId;

            return {
                ...state, // copy the state (level 0)
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === joinedChallengeId) {
                        if (item.participants.findIndex(x => x.userId === action.joinedChallenge.userId)===-1) { //To avoid double events beeing reported
                            return {
                                ...item,
                                numberOfParticipants: item.numberOfParticipants + 1,
                                participants: [...item.participants, action.joinedChallenge]
                            };
                        }                        
                    }
                    return item;
                })
            };
        case "LEFT_CHALLENGE":
            const leftChallengeId = action.leftChallenge.challengeId;
            return {
                ...state, // copy the state (level 0)

                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === leftChallengeId) {
                        if (item.participants.findIndex(x => x.userId === action.leftChallenge.userId)>-1) { //To avoid double events beeing reported
                            return {
                                ...item,
                                numberOfParticipants: item.numberOfParticipants - 1,
                                participants: item.participants.filter((item, index) =>
                                    item.userId !== action.leftChallenge.userId  //Remove user with id
                                )
                            };
                        }
                    }
                    return item;
                })
            };
        case "CHALLENGE_PARTICIPANT_EVENT_FORWARDED":
            const challengeId = action.challengeParticipantEvent.challengeId;
            const userId = action.challengeParticipantEvent.participantId;

            return {
                ...state,
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === challengeId) {
                        return {
                            ...item,
                            participants: item.participants.map((pitem, index) => {
                                if (pitem.userId === userId) {
                                    return {
                                        ...pitem,
                                        status: action.challengeParticipantEvent.status,
                                        startedMatchmaking: action.challengeParticipantEvent.startedMatchmaking,
                                        score: action.challengeParticipantEvent.status === 'DONE' ? action.challengeParticipantEvent.score : null,
                                        kills: action.challengeParticipantEvent.status === 'DONE' ? action.challengeParticipantEvent.kills : null,
                                        finishedTop25: action.challengeParticipantEvent.status === 'DONE' ? action.challengeParticipantEvent.finishedTop25 : null,
                                        finishedTop10: action.challengeParticipantEvent.status === 'DONE' ? action.challengeParticipantEvent.finishedTop10 : null,
                                        finishedTop1: action.challengeParticipantEvent.status === 'DONE' ? action.challengeParticipantEvent.finishedTop1 : null,
                                    };
                                }
                                return pitem;
                            }),
                        };
                    }
                    return item;

                }),
            };
        case "CHALLENGE_PARTICIPANTS_ERROR_FORWARDED":
            const errorChallengeId = action.error.challengeId;
 
            return {
                ...state,

                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === errorChallengeId) {
                        return {
                            ...item,
                            participants: item.participants.map((pitem, index) => {
                                if (action.error.participantIds.findIndex(x=>x === pitem.userId)!==-1) {
                                    return {
                                        ...pitem,
                                        error: true,
                                        errorDescription: action.error.message,
                                    };
                                }
                                return pitem;
                            }),
                        };
                    }
                    return item;

                }),
            };
        case "CHALLENGE_LOG_EVENT":
            const logChallengeId = action.challengeLogEvent.challengeId;

            return {
                ...state,
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === logChallengeId) {

                        if (action.challengeLogEvent.participantId !== undefined) {

                            /* if (item.logEvents.findIndex(x => x.challengeEventType === action.challengeLogEvent.challengeEventType && x.participantId === action.challengeLogEvent.participantId) === -1)*/

                            if(item.logEvents.length === 0 || action.challengeLogEvent.challengeEventType===ChallengeEventType.GAME_ROUND_COMPLETE || !(item.logEvents[item.logEvents.length - 1].challengeEventType === action.challengeLogEvent.challengeEventType && item.logEvents[item.logEvents.length - 1].participantId === action.challengeLogEvent.participantId)) {

                                return {
                                    ...item,
                                    logEvents: [...item.logEvents, action.challengeLogEvent]
                                };
                            }

                        }
                        else {                                   

                            /*if (item.logEvents.findIndex(x => x.challengeEventType === action.challengeLogEvent.challengeEventType) === -1)*/

                            if (item.logEvents.length === 0 || !(item.logEvents[item.logEvents.length - 1].challengeEventType === action.challengeLogEvent.challengeEventType)) {
                                return {
                                    ...item,
                                    logEvents: [...item.logEvents, action.challengeLogEvent]
                                };
                            }

                        }

                        
                    }
                    return item;

                }),
            };
        case "CHALLENGE_LOG_EVENTS":
            const logChallengeId2 = action.challengeLogEvents[0].challengeId;
            return {
                ...state,
                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === logChallengeId2) {
                        var newLogs = [];
                        action.challengeLogEvents.forEach((log, index) => {

                            if (log.participantId !== undefined) {                               

                                /*if (item.logEvents.findIndex(x => x.challengeEventType === log.challengeEventType && x.participantId === log.participantId) === -1)*/

                                if (item.logEvents.length === 0 || !(item.logEvents[item.logEvents.length - 1].challengeEventType === log.challengeEventType && item.logEvents[item.logEvents.length - 1].participantId === log.participantId)) {
                                    newLogs.push(log);
                                }
                            }
                            else {                                

                                /*if (item.logEvents.findIndex(x => x.challengeEventType === log.challengeEventType) === -1)*/

                                if (item.logEvents.length === 0 || !(item.logEvents[item.logEvents.length - 1].challengeEventType === log.challengeEventType)) {                                    
                                    newLogs.push(log);                                    
                                }
                            }

                        });  
                        return {
                            ...item,
                            logEvents: [...item.logEvents, ...newLogs]
                        };
                    }
                    return item;

                }),
            };
        case "CHALLENGE_ROUND_RESULT_EVENT_FORWARDED":
            const roundResultChallengeId = action.challengeRoundResultEvent.challengeId;
            return {
                ...state,

                activeChallenges: state.activeChallenges.map((item, index) => {
                    if (item.id === roundResultChallengeId) {
                        return {
                            ...item,
                            participants: item.participants.map((pitem, index) => {
                                var roundResult = action.challengeRoundResultEvent.playerRoundResults.find(x => x.userId === pitem.userId);                                
                                if (roundResult !== undefined) {
                                    return {
                                        ...pitem,
                                        roundsWon: roundResult.roundsWon,
                                    };
                                }
                                return pitem;
                            }),
                        };
                    }
                    return item;

                }),
            };

        case "FOLLOWING_STREAMER":
            return {
                ...state,
                streamer: action.streamer.nick,
            };
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }

}