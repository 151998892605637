import { ComponentsVariants, ComponentsOverrides, Theme } from '@mui/material'

const MuiFormHelperText: {
  variants: ComponentsVariants['MuiFormHelperText']
  styleOverrides: ComponentsOverrides<Theme>['MuiFormHelperText']
} = {
  variants: [
    {
      props: { color: 'warning', error: false },
      style: ({ theme } : { theme: any }) => ({ color: theme.palette.warning.main }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.description,
      color: theme.palette.gray[200],
      margin: 0,
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
      '&.Mui-warning': {
        color: theme.palette.warning.main,
      },
    }),
  },
}

export default MuiFormHelperText
