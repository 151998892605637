import React from 'react'
import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import Typography from '../Typography/Typography'
import PlayerStatus, { Variants as StatusVariants } from '../PlayerStatus/PlayerStatus'
import TrophyNumber from '../TrophyNumber/TrophyNumber'

// List of table Style Variants
enum Variants {
  Commands = 'commands',
  Opponents = 'opponents',
}
type VariantsT = `${Variants}` // Co "Enum Variants" values to input options

// Custom class helpers (to change classes in one place)
enum CustomClasses {
  AfterRow = '-row',
  AfterCell = '-cell',
  PaddingNone = 'padding-none',
}

// Typing of the table Data
type TableDataT = {
  headRow: { cell: { value: string; color?: string }[] }
  bodyRow: {
    cell: { value: string; src?: string; color?: string }[] | null
    noPlayers?: boolean
  }[]
}

type TableEnemyProps = TableContainerProps & {
  data: TableDataT
  variant: VariantsT
  withTrophy: boolean,
  placement: number 
}

function TableEnemy({ data, variant, placement, withTrophy, ...restProps }: TableEnemyProps) {
 const theme = useTheme() 
 return (
   <TableContainer
      component="div"
      sx={{
        display: 'inline-block',
        width: 'auto',
        [theme.breakpoints.down('md')]: { display: 'block', width: '100%' },
      }}
      {...restProps}
    >
      <Table
        aria-label="customized table"
        sx={{
          maxWidth: '630px',
          [theme.breakpoints.down('sm2')]: {
            maxWidth: '100%',
          },
        }}
      >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>#</StyledTableCell>
            {data?.headRow?.cell?.map((th, index33) => (
              <StyledTableCell key={index33} className={`${variant}${CustomClasses.AfterCell}`}>
                <Typography variant="uiElement">{th.value}</Typography>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.bodyRow.map((row, index) => (
            <StyledTableRow className={`${variant}${CustomClasses.AfterRow}`} key={index}>
              <>
                <StyledTableCell>{getPlaceType(placement, withTrophy)}</StyledTableCell> 
                {!row.cell && (
                  <StyledTableCell
                    className={CustomClasses.PaddingNone}
                    colSpan={data?.headRow?.cell?.length}
                  >
                    <PlayerStatus
                      variant={emptyVariant(row.noPlayers).variant}
                      text={emptyVariant(row.noPlayers).text}
                    />
                  </StyledTableCell>
                )}
                {row?.cell?.map((cell, indexCell) => (
                  <StyledTableCell
                    key={indexCell}
                    className={`${variant}${CustomClasses.AfterCell}`}
                  >
                    {cell.src && <PlayerStatus src={cell.src} text={cell.value} />}
                    {!cell.src && (
                      <Typography variant="bodySmall" {...textTypographyColor(cell.color)}>
                        {cell.value ? cell.value : '-'}
                      </Typography>
                    )}
                  </StyledTableCell>
                ))}
              </>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

// ===
// === Stylization table START

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // Common styles for <td>
  padding: '0 8px',
  borderBottom: 'none',

  [`&.${tableCellClasses.head}`]: {
    // Common styles for <thead><td>
    backgroundColor: theme.palette.gray[700],
    color: theme.palette.gray[500],
    height: '29px',
    border: 'none',

    [`&.${Variants.Commands}${CustomClasses.AfterCell}`]: {
      // Styles for <th.Variants.Commands>
      width: '60px',
      '&:nth-of-type(2)': {
        // Common styles for <tr><th:second.Variants.Commands>
        width: '206px',
        [theme.breakpoints.down('md')]: {
          width: '185px',
        },
        [theme.breakpoints.down('sm2')]: {
          width: '140px',
        },
      },
      '&:nth-of-type(3)': {
        // Common styles for <tr><th:third.Variants.Commands>
        width: '165px',
        [theme.breakpoints.down('md')]: {
          width: '98px',
        },
        [theme.breakpoints.down('sm2')]: {
          width: '111px',
        },
      },
    },

    [`&.${Variants.Opponents}${CustomClasses.AfterCell}`]: {
      // Styles for  <th.Variants.Opponents>
      width: '70px',
      '&:nth-of-type(2)': {
        // Common styles for <tr><th:second.Variants.Opponents>
        width: '206px',
      },
      '&:nth-of-type(3)': {
        // Common styles for <tr><th:third.Variants.Opponents>
        width: '251px',
      },
    },
  },

  [`&.${tableCellClasses.body}`]: {
    // Common styles for <tbody><td>
    height: '34px',
    color: theme.palette.gray[25],
    '&:first-of-type': {
      // Common styles for <tr><td:first>
      width: '32px',
      minWidth: '32px',
      borderRight: `1px solid ${theme.palette.gray[700]}`,
    },
    '&:nth-of-type(2)': {
      // Common styles for <tr><td:second>
      padding: '0 2px',
    },
    '&:nth-of-type(3)': {
      // Common styles for <tr><td:third>
      borderLeft: `1px solid ${theme.palette.gray[700]}`,
    },

    [`&.${CustomClasses.PaddingNone}`]: {
      // Styles for <td.CustomClasses.PaddingNone>
      padding: 0,
    },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Common styles for <tr>
  backgroundColor: theme.palette.gray[900],
  border: `1px solid ${theme.palette.gray[700]}`,
  borderTopWidth: '2px',
}))

// === Stylization table END
// ===

// Changing the status and text of the user searching and if the search did not give results
const emptyVariant = (val: boolean | undefined) => {
  return {
    variant: val ? StatusVariants.NoPlayers : StatusVariants.Searching,
    text: val ? 'No players' : 'Searching for Player',
  }
}

const textTypographyColor = (val: string | undefined) => {
  let out = {}
  if (val) {
    out = { color: val }
  }
  return out
}

const getPlaceType = (val: number, withTrophy: boolean) => {
  switch (true) {
    case val===0:
      return ''
      break
    case val < 4 && withTrophy:
      return <TrophyNumber place={val} />
      break

    default:
      return val
  }
}

export default TableEnemy
