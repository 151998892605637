import React from 'react'
import { useField, FastFieldProps } from 'formik'
import TextField, { TextFieldProps } from 'src/components/TextField/TextField'

export type TextFieldComponentProps = TextFieldProps &
  Omit<FastFieldProps, 'form'> & {
    label?: string
  }

function TextFieldComponent({ field, meta, id, label, ...restProps }: TextFieldComponentProps) {
  const error = meta.touched && meta.error

  return (
    <TextField
      id={id || field.name}
      label={label}
      {...field}
      {...restProps}
      error={!!error}
      helperText={error}
    />
  )
}

export type FormikTextFieldProps = TextFieldProps & {
  name: string
}

export default function FormikTextField({ name, ...restProps }: FormikTextFieldProps) {
  const [field, meta] = useField({ name })

  return <TextFieldComponent field={field} meta={meta} {...restProps} />
}
