import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const MuiSelect: {
  variants: ComponentsVariants['MuiSelect']
  styleOverrides: ComponentsOverrides<Theme>['MuiSelect']
} = {
  variants: [
    {
      props: { size: 'large' },
      style: {
        '& .MuiInputBase-input': {
          height: '20px',
        },
        '& .MuiSelect-icon': {
          top: '14px',
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        '& .MuiSelect-icon': {
          top: '8px',
        },
      },
    },
  ],
  styleOverrides: {
    select: {
      '&.MuiSelect-outlined': {
        minHeight: '16px',
      },
    },
    icon: ({ theme }) => ({
      color: theme.palette.gray[25],
      width: '18px',
      height: '18px',
      top: '12px',
    }),
    iconOpen: ({ theme }) => ({
      color: theme.palette.primary.light,
    }),
  },
}

export default MuiSelect
