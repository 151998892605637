import * as React from 'react'
import clsx from 'clsx'
import { Link as RouterLink, LinkProps as NativeLinkProps, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import { styled } from '@mui/material/styles'

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled(RouterLink)({})

export interface RouterLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<
      NativeLinkProps,
      'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'
    > {
  href?: NativeLinkProps['to']
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  to: NativeLinkProps['to']
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}

export const RouterLinkComposed = React.forwardRef<HTMLAnchorElement, RouterLinkComposedProps>(
  function RouterLinkComposed(props, ref) {
    const {
      to,
      href,
      replace,
      className,
      size = 'medium',
      disabled,
      startIcon,
      endIcon,
      children,
      onClick,
      ...restProps
    } = props

    const sizeClass = React.useMemo(() => size[0].toUpperCase() + size.slice(1), [size])

    return (
      <Anchor
        to={href || to}
        className={`${className} MuiLink-size${sizeClass} ${disabled ? 'Mui-disabled' : ''}`}
        replace={replace}
        ref={ref}
        onClick={onClick}
        {...restProps}
      >
        {startIcon && (
          <Box
            component="span"
            display="flex"
            className={`MuiLink-startIcon MuiLink-iconSize${sizeClass}`}
          >
            {startIcon}
          </Box>
        )}
        <Box component="span" sx={{ transform: 'translateY(1px)' }}>
          {children}
        </Box>
        {endIcon && (
          <Box
            component="span"
            display="flex"
            className={`MuiLink-endIcon MuiLink-iconSize${sizeClass}`}
          >
            {endIcon}
          </Box>
        )}
      </Anchor>
    )
  }
)

export type LinkProps = {
  activeClassName?: string
  noLinkStyle?: boolean
  href: RouterLinkComposedProps['to']
  isActive?: boolean
} & Omit<RouterLinkComposedProps, 'to'> &
  Pick<MuiLinkProps, 'sx'>

// A styled version of the Router.js Link component:
// https://Routerjs.org/docs/api-reference/Router/link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    activeClassName = 'active',
    className: classNameProps,
    href,
    noLinkStyle,
    replace,
    isActive,
    ...other
  } = props

  const router = useLocation()
  const pathname = typeof href === 'string' ? href : href.pathname
  const className = clsx(classNameProps, {
    [activeClassName]: (router.pathname === pathname && activeClassName) || isActive,
  })

  const RouterjsProps = {
    to: href,
    replace,
  }

  if (noLinkStyle) {
    return <RouterLinkComposed className={className} ref={ref} {...RouterjsProps} {...other} />
  }

  return (
    <MuiLink
      component={RouterLinkComposed}
      className={className}
      ref={ref}
      underline="none"
      {...RouterjsProps}
      {...other}
    />
  )
})

export default Link
