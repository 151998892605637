import React, { useState } from 'react'
import {
  Box,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableContainerProps,
  TableRow,
  useTheme,
} from '@mui/material'
import Typography from '../Typography/Typography'
import PlayerStatus, { Variants as StatusVariants } from '../PlayerStatus/PlayerStatus'
import TrophyNumber from '../TrophyNumber/TrophyNumber'
import Button from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'

// Custom class helpers (to change classes in one place)
enum CustomClasses {
  AfterRow = 'styled-row',
  AfterCell = 'styled-cell',
  PaddingNone = 'padding-none',
}

// Typing of the table Data
type TableDataT = {
  headRow: { cell: { value: string; color?: string }[] }
  bodyRow: {
    cell: { value: string|number; src?: string; color?: string }[] | null
    noPlayers?: boolean
    playerid: number
  }[]
}

type TableEnemyWithScrollProps = TableContainerProps & {
  data: TableDataT
  withTrophy?: boolean
  rowCount?: number
  trophyLimit?: number
  refund: (id:number) => void
}

function TableEnemyWithScroll({
  data,
  withTrophy,
  rowCount = 12,
  trophyLimit,
  refund,
  ...restProps
}: TableEnemyWithScrollProps) {
  const theme = useTheme()
  const [showAll, setShowAll] = useState(false)
  const changeShowAll = () => {
    setShowAll(!showAll)
  }
  const mobileRightOffset = data.bodyRow.length < rowCount ? '0' : '-4px'

  return (
    <TableContainer
      component="div"
      sx={{ display: 'inline-block', width: 'auto', overflow: 'visible' }}
      {...restProps}
    >
      <Stack direction="row" sx={{ width: 'auto' }}>
            {/*<StyledHeadCell>#</StyledHeadCell>   We send placment in data */} 
            {data?.headRow?.cell?.map((th, indexHead) => (
              indexHead>0 ? 
              <StyledHeadCell key={indexHead} className={`${CustomClasses.AfterCell}`}>
                <Typography variant="uiElement">{th.value}</Typography>
              </StyledHeadCell>
                :
              <StyledHeadCell key={indexHead} className={`${CustomClasses.AfterCell}`}>#</StyledHeadCell>              
            ))}
        </Stack>
      <Box
        overflow="auto"
        maxHeight={showAll ? getFullTableHeight(data.bodyRow.length) : '410px'}
        sx={{
          transition: { xs: 'none', sm2: 'max-height 0.4s ease-in-out' },
          [theme.breakpoints.down('sm2')]: {
            mr: showAll ? '0' : mobileRightOffset,
          },
        }}
      >
        <Table sx={{ maxWidth: { xs: '100%', sm2: '980px' } }} aria-label="customized table">
          <TableBody>
            {data.bodyRow.map((row, index) => (
              <StyledTableRow className={`${CustomClasses.AfterRow}`} key={index}>
                <>
                  <StyledTableCell className={`${CustomClasses.AfterCell}`}>
                    { (row.cell ===null || row.cell[0].value===''|| row.cell[0].value==='-') ?
                            '-'
                            :
                            getPlaceType(Number(row.cell[0].value), trophyLimit, withTrophy)}
                  </StyledTableCell>
                  {!row.cell && (
                    <StyledTableCell
                      className={CustomClasses.PaddingNone}
                      colSpan={data?.headRow?.cell?.length}
                    >
                      <PlayerStatus
                        variant={emptyVariant(row.noPlayers).variant}
                        text={emptyVariant(row.noPlayers).text}
                      />
                    </StyledTableCell>
                  )}
                   {/* We slice away placment since it's handled above */}
                  {row?.cell?.slice(1).map((cell, indexCell) => (   
                    <StyledTableCell
                      key={indexCell}
                      className={`${CustomClasses.AfterCell}`}
                    >
                        
                      {indexCell < 5 && cell.src && <PlayerStatus src={cell.src} text={cell.value as string} />}
                      {indexCell < 5 && !cell.src && (
                        <Typography variant="bodySmall" {...textTypographyColor(cell.color)}>
                          {cell.value ? cell.value : '-'}
                        </Typography>
                      )}
                      {indexCell ===5 && <Button
                        endIcon={<SvgIcon name="wallet-line" sx={{ marginRight: '4px', marginLeft:'10px' }} />}
                        sx={{ minWidth: '100px'  }}
                        variant="contained"
                        size={ 'small'}
                        onClick={() => refund(row.playerid)}  
                      >
                          Refund
                      </Button>}
                    </StyledTableCell>
                  ))}
                </>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {data.bodyRow.length >= rowCount && (
        <Box pr={{ xs: '0', sm2: '4px' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={changeShowAll}
            sx={{ width: '100%' }}
            endIcon={
              <SvgIcon
                name="arrow-down-line"
                sx={{
                  transform: `rotate(${showAll ? -180 : 0}deg)`,
                  transition: 'transform 0.4s ease',
                }}
              />
            }
          >
            {showAll ? 'Show less' : 'Show all'}
          </Button>
        </Box>
      )}
    </TableContainer>
  )
}

// ===
// === Stylization table START
const tableStyleParams = {
  td: {
    height: 34,
    firstWith: '32px',
    secondWith: '206px',
    thirdWidth: '251px',
    fourthWith: '70px',
    fifthWith: '70px',
    sixthhWith: '70px',
    seventhWith: '120px',
  },
  tdMobile: {
    firstWith: '9.5%',
    secondWith: '37%',
    thirdWidth: '24.5%',
    fourthWith: '11.5%',
    fifthWith: '17.5%',
    sixthhWith: '10%',
    seventhWith: '10%',
  },
}

const cellStyleCommonObj = {
  [`&.${CustomClasses.AfterCell}`]: {
    '&:first-of-type': { width: tableStyleParams.td.firstWith },
    '&:nth-of-type(2)': { width: tableStyleParams.td.secondWith },
    '&:nth-of-type(3)': { width: tableStyleParams.td.thirdWidth },
    '&:nth-of-type(4)': { width: tableStyleParams.td.fourthWith },
    '&:nth-of-type(5)': { width: tableStyleParams.td.fifthWith },
    '&:nth-of-type(6)': { width: tableStyleParams.td.sixthhWith },
    '&:nth-of-type(7)': { width: tableStyleParams.td.seventhWith },
  },
}

const cellStyleCommonMobileObj = {
  [`&.${CustomClasses.AfterCell}`]: {
    '&:first-of-type': { width: tableStyleParams.tdMobile.firstWith },
    '&:nth-of-type(2)': { width: tableStyleParams.tdMobile.secondWith },
    '&:nth-of-type(3)': { width: tableStyleParams.tdMobile.thirdWidth },
    '&:nth-of-type(4)': { width: tableStyleParams.tdMobile.fourthWith },
    '&:nth-of-type(5)': { width: tableStyleParams.tdMobile.fifthWith },
    '&:nth-of-type(6)': { width: tableStyleParams.tdMobile.sixthhWith },
    '&:nth-of-type(7)': { width: tableStyleParams.tdMobile.seventhWith },
  },
}

const StyledHeadCell = styled(Box)(({ theme }) => ({
  padding: '2px 0px 0 8px',
  borderBottom: 'none',
  backgroundColor: theme.palette.gray[700],
  color: theme.palette.gray[500],
  height: '30px',
  flexGrow: 1,
  alignItems: 'center',
  display: 'flex',
  '&:first-of-type': {
    borderRight: `1px solid ${theme.palette.gray[700]}`,
  },
  ...cellStyleCommonObj,
  [theme.breakpoints.down('sm2')]: {
    ...cellStyleCommonMobileObj,
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0 0 0 8px',
  borderBottom: 'none',
  [`&.${tableCellClasses.body}`]: {
    height: `${tableStyleParams.td.height}px`,
    color: theme.palette.gray[25],
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.gray[700]}`,
    },
    '&:nth-of-type(2)': { padding: '0 2px' },
    '&:nth-of-type(3)': { borderLeft: `1px solid ${theme.palette.gray[700]}` },
    ...cellStyleCommonObj,
    [theme.breakpoints.down('sm2')]: {
      ...cellStyleCommonMobileObj,
    },
    [`&.${CustomClasses.PaddingNone}`]: { padding: 0 },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Common styles for <tr>
  backgroundColor: theme.palette.gray[900],
  border: `1px solid ${theme.palette.gray[700]}`,
  borderTopWidth: '2px',
  '&:first-of-type': {
    borderTopWidth: 0,
  },
}))
// === Stylization table END
// ===

// Changing the status and text of the user searching and if the search did not give results
const emptyVariant = (val: boolean | undefined) => {
  return {
    variant: val ? StatusVariants.NoPlayers : StatusVariants.Searching,
    text: val ? 'No players' : 'Searching for Player...',
  }
}

const textTypographyColor = (val: string | undefined) => {
  let out = {}
  if (val) {
    out = { color: val }
  }
  return out
}

const getPlaceType = (val: number, trophyLimit: number | undefined, withTrophy?: boolean) => {
  const limit = trophyLimit ? trophyLimit : 3
  switch (true) {
    case val <= limit && withTrophy:
      return <TrophyNumber place={val} />
    default:
      return val
  }
}

// Getting the height of the table for smooth altitude changes
const getFullTableHeight = (val: number) => {
  return tableStyleParams.td.height * val + 1
}

export default TableEnemyWithScroll
