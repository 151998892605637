import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Stack, useTheme } from '@mui/material';
import LoginMainBanner from 'src/components/LoginMainBanner/LoginMainBanner'
import MainBanner from 'src/components/MainBanner/MainBanner'
import TableWrapper from 'src/components/TableWrapper/TableWrapper'
import TableHistoryProfile from 'src/components/TableHistoryProfile/TableHistoryProfile'
import { getGeneralStatistics,getRunningChallenges } from '../actions/adminActions'
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { gamesPlayedMapper } from 'src/helpers/data';
import ctx from 'src/context/context'
import LiveActivityNew from 'src/components/LiveActivityNew/LiveActivityNew';
import Button from 'src/components/Button/Button'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

export default function Home() {
    const theme = useTheme();
    const dispatch =  useAppDispatch();
    const navigate = useNavigate();
    const auth = ctx.useAuth();
     const general = useAppSelector(state => state.adminReducer.general);
    const running = useAppSelector(state => state.adminReducer.runningChallenges);

    useEffect(() => {
        if(auth.isWalletConnected){
            dispatch(getGeneralStatistics());
            dispatch(getRunningChallenges());
        }
    }, [auth.isWalletConnected])

    const profileHistoryTable =  gamesPlayedMapper(running);
            
  function refresh(arg0: boolean): void {
    //fetch getRunningChallenges
    dispatch(getRunningChallenges());
  }

    return (
        <>
        <Box sx={{ position: 'relative', background: '#040811' }}>
            <Container 
                maxWidth="lg2" 
                disableGutters 
                sx={auth.isWalletConnected ? 
                    { pt: { xs: '28px', sm: '12px' }, mb: { xs: '36px', md2: '28px' } }
                    : 
                    {
                        pt: '32px',
                        pb: '52px',
                        [theme.breakpoints.down('md')]: {
                          pt: '12px',
                          pb: '42px',
                        },
                        [theme.breakpoints.down('sm')]: {
                          pb: '36px',
                        },
                      }
                }>
                {auth.isWalletConnected ? < LoginMainBanner user={auth.user} general={general} /> : <MainBanner/> } 
            </Container>
            {auth.isWalletConnected ?
                  
                <Grid item>
                   <Box paddingLeft="20px" paddingBottom="20px">
                  <Button
                  endIcon={<SvgIcon name="arrow-down-line" sx={{ marginRight: '4px' }} />}
                  sx={{ minWidth: '100px'  }}
                  variant="contained"
                  size={ 'small'}
                  onClick={() => refresh(true)}
                >
                  Refresh
                </Button>
                </Box>
                <Box paddingLeft="20px" width="1200px">
                 
                <LiveActivityNew                                             
                          TableContainerProps={{
                              sx: { maxHeight: '461px' },
                          }}
                      />
                </Box>
              </Grid>
                 : null}
            </Box>
        </>
    );
    
}

