import React, { useCallback, useMemo } from 'react'
import { Stack, StackProps, Box, BoxProps } from '@mui/material'

export type SquareRowProps = StackProps & {
  count?: number
  highlightedIndex?: number
  squareProps?: BoxProps
  getSquareBgColor?: (isHighlighted: boolean) => string
}

export default function SquareRow({
  count = 3,
  highlightedIndex = 0,
  squareProps,
  getSquareBgColor,
  ...restRootStackProps
}: SquareRowProps) {
  const setBgColor = useCallback(
    (isHighlighted: boolean) => {
      if (getSquareBgColor) {
        return getSquareBgColor(isHighlighted)
      }
      return isHighlighted ? 'gray.500' : 'gray.700'
    },
    [getSquareBgColor]
  )

  const squares = useMemo(
    () =>
      Array.from({ length: count }, (_, index) => (
        <Box
          key={index}
          width="12px"
          height="3px"
          {...squareProps}
          bgcolor={setBgColor(index === highlightedIndex)}
        />
      )),
    [count, highlightedIndex, squareProps, setBgColor]
  )

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center" gap="1px" {...restRootStackProps}>
      {squares}
    </Stack>
  )
}
