import React from 'react'
import PropTypes from 'prop-types'
import LogEntry from './logEntry'

const LogTable = ({ logs }) => (

    <table className="table lobby-table table-hover table-striped match-history">
        <thead>
            <tr>
                <th>ChallengeId</th>
                <th>Date</th>
                <th>ChallengeType</th>
                <th>Nick</th>
                <th>Description</th>
                <th>ChallengeStatus</th>
                <th>ParticipantStatus</th>               
                <th>Error</th>
                <th>ErrorType</th>
                <th>ErrorDescription</th>
            </tr>
        </thead>
        <tbody>
            {typeof logs === 'undefined' ? 'Loading...' : (logs.map((log, index) => (
                <LogEntry key={log.id} {...log} index={index} />
            )))}
        </tbody>
    </table>
)

export default LogTable