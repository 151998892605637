import React from 'react'
import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot'
import SquareRow, { SquareRowProps } from 'src/components/SquareRow/SquareRow'

export type TableWrapperProps = BoxProps & {
  title: string
  subtitle?: string
  squareRowProps?: SquareRowProps
  bodyStackProps?: StackProps
  TitleRootStackProps?: StackProps
  TitleTypographyProps?: TypographyProps
  TitleDotProps?: BoxProps
}

export default function TableWrapper({
  title,
  subtitle,
  squareRowProps,
  children,
  bodyStackProps,
  TitleRootStackProps,
  TitleTypographyProps,
  TitleDotProps,
  ...restRootBoxProps
}: TableWrapperProps) {
  return (
    <Box
      p="2px 2px 0"
      bgcolor="gray.800"
      border="1px solid"
      borderColor="gray.700"
      {...restRootBoxProps}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
        p="15px"
        pr="13px"
        pb="14px"
        {...bodyStackProps}
      >
        <TitleWithDot
          rootStackProps={{ spacing: '6px', ...TitleRootStackProps }}
          typographyProps={{ variant: 'h6', ...TitleTypographyProps }}
          dotProps={{ bottom: '7px', ...TitleDotProps }}
        >
          {title}
        </TitleWithDot>
        <SquareRow {...squareRowProps} />
        {subtitle && (
          <Typography variant="bodySmall" color="gray.100" mt="4px" width="100%">
            {subtitle}
          </Typography>
        )}
      </Stack>
      {children}
    </Box>
  )
}
