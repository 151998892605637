import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const MuiLink: {
  variants?: ComponentsVariants['MuiLink']
  styleOverrides?: ComponentsOverrides<Theme>['MuiLink']
} = {
  variants: [
    {
      props: { color: 'secondary' },
      style: ({ theme }: { theme: any }) => ({
        color: theme.palette.gray[25],
        '&:hover': {
          color: theme.palette.gray[0],
        },
        '&.Mui-disabled': {
          color: theme.palette.gray[600],
          pointerEvents: 'none',
          cursor: 'default',
        },
      }),
    },
  ],
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...theme.typography.buttonMedium,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...(ownerState.color === 'primary' && {
        '&:hover': {
          color: theme.palette.primary.light,
        },
        '&.Mui-disabled': {
          color: theme.palette.gray[700],
          pointerEvents: 'none',
          cursor: 'default',
        },
      }),
      '&.MuiLink-sizeLarge': {
        '.MuiSvgIcon-root': {
          fontSize: '24px',
        },
      },
      '&.MuiLink-sizeMedium': {
        '.MuiSvgIcon-root': {
          fontSize: '18px',
        },
      },
      '&.MuiLink-sizeSmall': {
        ...theme.typography.uiElement,
        '.MuiSvgIcon-root': {
          fontSize: '14px',
        },
      },
      '& .MuiLink-startIcon': {
        '&.MuiLink-iconSizeSmall, &.MuiLink-iconSizeMedium': {
          marginRight: 0,
          ...(ownerState.children && {
            marginRight: '8px',
          }),
        },
      },
      '& .MuiLink-endIcon': {
        '&.MuiLink-iconSizeSmall, &.MuiLink-iconSizeMedium': {
          marginLeft: 0,
          ...(ownerState.children && {
            marginLeft: '8px',
          }),
        },
      },
      '& .MuiLink-startIcon, .MuiLink-endIcon': {
        '&.MuiLink-iconSizeSmall .MuiSvgIcon-root': {
          fontSize: '14px',
        },
        '&.MuiLink-iconSizeMedium .MuiSvgIcon-root': {
          fontSize: '18px',
        },
      },
    }),
  },
}

export default MuiLink
