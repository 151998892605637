import * as React from 'react'
import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import SvgIcon, { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'

type SelectMenuItemProps = MuiMenuItemProps & {
  size?: 'small' | 'medium' | 'large'
  icon?: SvgIconProps['name']
}

export default function SelectMenuItem({
  children,
  size = 'medium',
  icon,
  ...restProps
}: SelectMenuItemProps) {
  return (
    <MuiMenuItem
      {...restProps}
      sx={{
        '&.MuiMenuItem-root': {
          margin: '2px',
          p: '8px',
          backgroundColor: 'gray.900',
          ...(size === 'small' && {
            minHeight: '36px',
          }),
          ...((size === 'medium' || size === 'large') && {
            minHeight: '44px',
            p: '12px',
          }),
          color: 'gray.25',
          '&:hover': {
            backgroundColor: 'gray.800',
            color: 'gray.0',
          },
          '&.Mui-selected': {
            backgroundColor: 'gray.800',
            '& .MuiListItemIcon-root, .MuiListItemText-root': {
              color: 'primary.main',
            },
          },
        },
      }}
    >
      {icon && (
        <ListItemIcon
          sx={{
            '&.MuiListItemIcon-root': {
              fontSize: '14px',
              minWidth: '24px !important',
            },
          }}
        >
          <SvgIcon name={icon} fontSize="inherit" />
        </ListItemIcon>
      )}
      <ListItemText primaryTypographyProps={{ variant: 'paragraphSmall' }}>{children}</ListItemText>
    </MuiMenuItem>
  )
}
