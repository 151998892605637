import React from 'react';
import Box from '@mui/material/Box'
import { Outlet } from "react-router-dom";
import { WaitDialog } from 'src/components/WaitDialog/WaitDialog';


export const LayoutBasic = () => {
    
    return (
        <Box
            sx={{
            minHeight: '100vh',
            }}
        >               
            <Outlet/>
            <WaitDialog/> {/*Only used to show error dialog in registration*/} 
        </Box>
    );


}

