import React from 'react'
import { Box, BoxProps } from '@mui/material'

type LinesProps = BoxProps & {
  degNumber?: number
  lineWidth?: number
  lineGap?: number
  lineThickness?: number
}

export default function PatternLines({
  degNumber = 30,
  lineWidth = 3,
  lineGap = 2,
  lineThickness = 5,
  bgcolor = 'gray.900',
  color = '#121A2A',
  sx,
  ...restProps
}: LinesProps) {
  return (
    <Box
      position="absolute"
      top={0}
      width="100vw"
      height="100%"
      ml="calc(-100vw - 27px)"
      bgcolor={bgcolor}
      sx={{
        background: `repeating-linear-gradient(${degNumber}deg, transparent, transparent ${lineGap}px, ${color} ${lineWidth}px, ${color} ${lineThickness}px)`,
        ...sx,
      }}
      {...restProps}
    />
  )
}
