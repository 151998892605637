import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import LogTable from './logTable';
import { getLogs } from '../actions/adminActions';

var panelStyle = { paddingLeft: '20px', paddingRight: '20px' }

class Logs extends React.Component {

    constructor() {
        super();
    }

    componentDidMount() {
        // calling the new action creator
        this.props.localGetLogs();
    }

    render() {
        const { logs } = this.props;

        return (
            <div>
                <div className="container-fluid">
                   <h2 className="panel-title">Logs</h2>
                   <div className="panel" style={panelStyle}>
                        <LogTable logs={logs} />
                   </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        logs: state.adminReducer.logs,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetLogs: () => dispatch(getLogs()),
    };
}

const logs = connect(mapStateToProps, mapDispatchToProps)(Logs);
export { logs as Logs }; 