import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Stack, StackProps } from '@mui/material'
import Comment from '../Comment/Comment'
import TripleSquare from '../TripleSquare/TripleSquare'
import VerticalDividerWithContent from '../VerticalDividerWithContent/VerticalDividerWithContent'
import LiveUpdateMessage, {
  LiveUpdateMessageProps,
} from 'src/components/LiveUpdateMessage/LiveUpdateMessage'

type StatusListProps = StackProps & {
  list?: LiveUpdateMessageProps[]
}

export default function LiveUpdateList({ list = [], ...restProps }: StatusListProps) {
  const theme = useTheme()
  return (
    <Stack
      border="1px solid"
      borderColor="gray.700"
      bgcolor="gray.800"
      pt="11px"
      height={{ xs: '271px', sm: '343px' }}
      justifyContent="space-between"
      {...restProps}
    >
      <Box mb={{ xs: '22px', sm: '24px' }}>
        <TripleSquare
          mb="6px"
          mr="13px"
          justifyContent="right"
          sx={{ [theme.breakpoints.down('sm')]: { mb: '1px' } }}
        />
        <VerticalDividerWithContent
          rootStackProps={{ ml: '20px', sx: { [theme.breakpoints.down('sm')]: { ml: '15px' } } }}
        >
          <Comment rootStackProps={{ mt: '3px' }} typographySlashProps={{ color: 'gray.600' }}>
            Live updates
          </Comment>
        </VerticalDividerWithContent>
      </Box>
      <Stack
        pl="23px"
        mr="6px"
        maxHeight="273px"
        minHeight="192px"
        mb={{ xs: '15px', sm: '23px' }}
        sx={{ overflowY: 'auto', [theme.breakpoints.down('sm')]: { pl: '15px' } }}
      >
        {list?.length > 0
          ? list.map((item, index) => (
              <LiveUpdateMessage key={item?.id || index} isLastMessage={index === 0} {...item} />
            ))
          : null}
      </Stack>
    </Stack>
  )
}
