import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router";
import Button from "src/components/Button/Button";
import PageContainer from "src/components/PageContainer/PageContainer";
import PatternLines from "src/components/PatternLines/PatternLines";
import SvgIcon from "src/components/SvgIcon/SvgIcon";
import TitleWithDot from "src/components/TitleWithDot/TitleWithDot";

export default function OnTime() {
    const navigate = useNavigate();
    
    return (
        <Box bgcolor="gray.900" position="relative" marginBottom="10px">
            <PageContainer sx={{ pt: '17px' }}
                innerContainerProps={{
                    sx: { position: 'relative' },
                }}
            >
                <PatternLines height="262px" top="9px" />
                <TitleWithDot
                    typographyProps={{ variant: 'h2' }}
                    dotProps={{ bottom: '16px' }}
                    rootStackProps={{ spacing: '10px', mb: '23px' }}
                >
                    Challenges
                </TitleWithDot>
                <Grid item>
                   <Box paddingLeft="160px" paddingBottom="20px">
                        <Button
                            endIcon={<SvgIcon name="edit-line" sx={{ marginRight: '4px' }} />}
                            sx={{ minWidth: '100px'  }}
                            variant="contained"
                            size={ 'small'}
                            onClick={() => navigate('/ontime/create')}
                            >
                            Create new
                        </Button>
                    </Box>{/*
                    <DataGrid
                        disableColumnMenu rows={userRows}
                        columns={columns}
                        loading={isLoading}
                        pageSizeOptions={[10, 25, 50]}
                        paginationMode="server"
                        rowCount={rowCountState}
                        paginationModel={paginationModel}
                        onPaginationModelChange={fetchData}
                        sx={{
                            height: '650px',
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                            },
                        }}
            
                    /> */}
                </Grid>
            </PageContainer>
           
        </Box>
            
    );
}