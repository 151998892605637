import * as React from 'react'
import {
  useTheme,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  FormHelperText,
  SelectProps,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'
import SelectMenuItem from 'src/components/SelectMenuItem/SelectMenuItem'
import { SvgIconProps } from 'src/components/SvgIcon/SvgIcon'

export type Options = {
  icon?: SvgIconProps['name']
  value: string
  label: string
}[]

export type CustomSelectProps = Omit<SelectProps, 'label'> & {
  options?: Options
  label?: string
  helperText?: string
  InputLabelProps?: MuiTextFieldProps['InputLabelProps']
  FormHelperTextProps?: MuiTextFieldProps['FormHelperTextProps']
}

export default function Select({
  id = '',
  name = '',
  size = 'medium',
  options = [],
  value = '',
  onChange,
  label,
  error,
  helperText,
  InputLabelProps,
  FormHelperTextProps,
  ...restSelectProps
}: CustomSelectProps) {
  const selectId = id || name || 'simple-select'
  const theme = useTheme()

  return (
    <FormControl
      sx={{
        minWidth: '160px',
        [theme.breakpoints.down('md')]: {
          minWidth: '216px',
        },
        [theme.breakpoints.down('sm')]: {
          flexGrow: 1,
        },
      }}
    >
      {label && (
        <InputLabel
          {...InputLabelProps}
          error={!!error}
          shrink
          id="select-label"
          color={helperText ? 'warning' : undefined}
        >
          {label}
        </InputLabel>
      )}
      <MuiSelect
        labelId="select-label"
        id={selectId}
        label={null}
        value={value}
        onChange={onChange}
        size={size}
        sx={{
          borderRadius: 0,
        }}
        MenuProps={{
          sx: {
            '& .MuiMenu-paper': {
              marginTop: '5px',
              backgroundColor: 'gray.900',
              border: 'unset',
              // @ts-ignore
              boxShadow: theme.shadows[26],
              outline: `solid 1px ${theme.palette.gray[800]}`,
              outlineOffset: '-1px',
              borderRadius: 0,
              backgroundImage: 'none',
              '& .MuiList-root': {
                padding: 0,
              },
            },
          },
        }}
        error={error}
        color={helperText ? 'warning' : undefined}
        {...restSelectProps}
      >
        {options.map((option) => (
          <SelectMenuItem key={option.value} icon={option.icon} value={option.value} size={size}>
            {option.label}
          </SelectMenuItem>
        ))}
      </MuiSelect>
      <FormHelperText
        {...FormHelperTextProps}
        error={!!error}
        color={helperText ? 'warning' : undefined}
      >
        {helperText || ' '}
      </FormHelperText>
    </FormControl>
  )
}
