import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { addChallengeCoin, getOwnerBalance, withdrawToken } from '../actions/adminActions';
import { TokenType } from "../helpers/types";
import { toToken, formatTokenToString } from "../utils/tokenUtil";

export const Blockchain = () => {

    const dispatch = useDispatch();
    const [amount, setAmount] = useState('');



    const { wallet } = useSelector(state => ({
        wallet: state.adminReducer.blockchain.ownerBalance,
    }), shallowEqual);

    useEffect(() => {
        dispatch(getOwnerBalance());
    }, ['ALL']); //Avoids infinite loop

    const handleClick = () => {
        // handle the click event
        dispatch(addChallengeCoin());
    };

    const handleWithdrawClick = () => {
        // handle the click event
        dispatch(withdrawToken(toToken(amount)));
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                    <h2 className="panel-title">Import token</h2>
                    <div className="panel">
                        <button type="button" name="button" onClick={handleClick}>
                            Import coin to wallet
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <h2 className="panel-title">Balance</h2>
                    <div className="panel">
                       Owner wallet: {wallet !== undefined ? formatTokenToString(wallet,TokenType.CT) : '-'}
                    </div>
                </div>
                <div className="col-md-4">
                    <h2 className="panel-title">Withdraw token</h2>
                    <div className="panel">
                        <input type="text" name="depositAmount" placeholder={"Enter amount to withdraw"} onChange={e => setAmount(e.target.value)} />
                        <button type="button" name="button" onClick={handleWithdrawClick}>
                            Withdraw
                        </button>
                    </div>
                </div>

            </div>
        </div>
        );
}