import React, { useCallback, useState } from 'react';
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot';
import PatternLines from 'src/components/PatternLines/PatternLines';
import PageContainer from 'src/components/PageContainer/PageContainer';
import Comment from 'src/components/Comment/Comment'
import {useAppDispatch, useAppSelector} from "src/hooks";
import ctx from 'src/context/context';
import WalletItem from 'src/components/WalletItem/WalletItem';
import { PNG_ICONS } from 'src/assets/icons/icons';
import { StakeActionType, StakeType, TokenType } from 'src/helpers/types';
import StakingForm from './stakeForm';
import { formatDuration, formatUnixTimestampToDateTime, isTimestampGreaterThanCurrentLocalTime } from 'src/utils/datetime';
import Button from 'src/components/Button/Button';
import Typography from 'src/components/Typography/Typography';
import SimpleTabs, { SimpleTabPanel, SimpleTabsWrapper } from 'src/components/SimpleTabs/SimpleTabs';
import { useFetchBlockchainFlexiStakingDataQuery, useFetchBlockchainVestedStakingDataQuery, useFetchFlexiStakingDataQuery, useFetchHistoricStakingsQuery, useFetchVestedStakingDataQuery } from 'src/api/stakingApi';
import { formatTokenToString } from 'src/utils/tokenUtil';

//import { fetchStakingData } from 'src/api/stakingApi';
//import { useQuery } from '@tanstack/react-query';

const tabList = ['Vested']
const tablist1 = ['Vested Staking']

export default function Staking() {  
    const theme = useTheme()
    const isMd834View = useMediaQuery(theme.breakpoints.down('md834'))
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
    const dispatch = useAppDispatch();
    const blockchain = ctx.useBlockchain();
    const auth = ctx.useAuth();

    const useBackend = !auth.isWalletConnected; //|| (blockchain.isWalletConnected && blockchain.isNetworkMismatch===true);
    const useBlockchain = auth.isWalletConnected; // && blockchain.isWalletConnected && blockchain.isNetworkMismatch===false;

    //React query
    //const { isLoading, error, data } = useQuery({queryKey:['backendStakingData'], queryFn: fetchStakingData});
    const resultFlexi1  = useFetchFlexiStakingDataQuery(undefined, { skip: !useBackend }); //Must have a first argument   
    const resultFlexi2 = useFetchBlockchainFlexiStakingDataQuery(auth.user?.username, { skip: !useBlockchain, pollingInterval: 60000}); //undefined,{ pollingInterval: 15000}  //Must have a first argument
    const { data:flexiData, error:flexiError, isLoading:isFlexiLoading } = useBlockchain ? resultFlexi2 : resultFlexi1;
    console.log('FlexiStaking data:', JSON.stringify(flexiData));

    const resultVested1  = useFetchVestedStakingDataQuery(undefined, { skip: !useBackend }); //Must have a first argument   
    const resultVested2 = useFetchBlockchainVestedStakingDataQuery(auth.user?.username, { skip: !useBlockchain, pollingInterval: 60000 }); //undefined,{ pollingInterval: 15000}  //Must have a first argument
    const { data:vestedData, error:vestedError, isLoading:isVestedLoading } = useBlockchain ? resultVested2 : resultVested1;
    console.log('VestedStaking data:', JSON.stringify(vestedData));


    //const flexiStaking =  useAppSelector(state => state.stakingReducer.flexiStaking);
    //const vestedStaking = useAppSelector(state => state.stakingReducer.vestedStaking);
    const totalBlance = (flexiData?.accountBalance!==undefined ? BigInt(flexiData?.accountBalance) :  0n) + (vestedData?.accountBalance!==undefined ? BigInt(vestedData?.accountBalance) : 0n);
    const totalSupply= (flexiData?.totalStaked!==undefined ? BigInt(flexiData?.totalStaked) :  0n) + (vestedData?.totalStaked!==undefined ? BigInt(vestedData?.totalStaked) : 0n); 
    const totalStakers = (flexiData?.stakerCount !== undefined ? BigInt(flexiData?.stakerCount): 0n) + (vestedData?.stakerCount !== undefined ? BigInt(vestedData?.stakerCount): 0n);
    
    let ccCoins = useAppSelector(state => state.userReducer.userInfo.coins?.challengeCoins);
    ccCoins = ccCoins ? ccCoins : '0';
        
    //const lastUpdatedBackend = useAppSelector(state => state.stakingReducer.fetchBackendTimeStamp);
    //const lastUpdatedBlockchain = useAppSelector(state => state.stakingReducer.fetchBlockchainTimeStamp);
    
    const [activeTab, setActiveTab] = useState<number>(0)
    const [activeVestedTab, setActiveVestedTab] = useState<number>(0)
    
    const tabs = tabList;
    const tabs2 = tablist1;

    const handleTabs = useCallback(
        (val: number) => {
         
            setActiveTab(val)
          },       
        [activeTab]
      ) 
 
    const handleVestedTabs = useCallback(
    (val: number) => {
        
        setActiveVestedTab(val)
        },       
    [activeTab]
    )  
    
     /*
    useEffect(() => {         
        if(!auth.isWalletConnected || (blockchain.isWalletConnected && blockchain.isNetworkMismatch===true))  {      
            if(isUpdateTime(lastUpdatedBackend, 60)){
                console.log('Fetching staking info...');
                dispatch(getBackendStakingData());
            }
        }                  
    }, [auth.isWalletConnected, blockchain.isWalletConnected, blockchain.isNetworkMismatch]);

    useEffect(() => {      
        if (auth.isWalletConnected && blockchain.isWalletConnected && blockchain.isNetworkMismatch===false) { //Must check for false because it can be undefined
            if(isUpdateTime(lastUpdatedBlockchain, 60)){
                console.log('Fetching staking info from blockchain...');
                dispatch(getFlexiStakingInfo());
                dispatch(getVestedStakingInfo());
            }
        }       
    }, [auth.isWalletConnected, blockchain.isWalletConnected, blockchain.isNetworkMismatch]);*/

    const vestingType = (type: number) => {
        switch(type) {
            case 1:
                return 'Pre Seed Funding';
            case 2:
                return 'Private Presale';
            case 3:
                return 'Team and Advisors';
            case 4:
                 return 'Public Vesting';
            default:
                return 'Unknown';
        }
    }
    

 

    return (
        <>        
        <Box bgcolor="gray.900" position="relative">
            <PageContainer
                sx={{
                pt: '17px',
                [theme.breakpoints.down('sm')]: {
                    pt: '37px',
                },
                }}
                innerContainerProps={{
                sx: { position: 'relative' },
                }}
            >
                <PatternLines
                    sx={{
                        height: '262px',
                        top: '9px',
                        [theme.breakpoints.down('lg4')]: {
                        ml: 'calc(-100vw - 12px)',
                        height: '238px',
                        },
                        [theme.breakpoints.down('sm')]: {
                        height: '66px',
                        ml: 'calc(-100vw - 8px)',
                        top: -10,
                        },
                    }}
                />
                <TitleWithDot
                    typographyProps={{ variant: isMobileView ? 'h3' : 'h2' }}
                    dotProps={{ bottom: { xs: '12px', sm: '16px' } }}
                    rootStackProps={{ spacing: { xs: '6px', sm: '10px' }, mb: { xs: '20px', sm: '24px' } }}
                    >
                       Staking
                </TitleWithDot>
                <Box mb={{ xs: '31px', sm: '38px', md: '68px'}} sx={{ml: '30px'}}>
                    <Grid
                        container
                        direction={{ xs: 'column', sm2: 'row' }}
                        spacing={{ xs: '20px', sm: '28px' }}     
                        alignItems="flex-start"
                                              >
                       
                        <Grid item xs={12} sm={4}
                            sx={{

                            }}>
                            <Box>
                                <Comment>Flexi Staking</Comment>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray[700]}`,
                                        padding: '17px',
                                        borderColor: theme.palette.gray[500], // Add type annotation to borderColor property
                                        '&:hover': {
                                            borderColor: theme.palette.gray[500],
                                        },
                                        [theme.breakpoints.down('md2')]: {
                                            width: '100%',
                                            padding: '11px',
                                        },
                                    }}
                                >
                                     {
                                        isTimestampGreaterThanCurrentLocalTime(flexiData?.currentPeriodFinish) ? `Reward period running, ends ${formatUnixTimestampToDateTime(flexiData?.currentPeriodFinish as string)}`:'Reward period not started'}
                                     
                                     <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Upcoming reward`}
                                        total={flexiData ? `${flexiData.upcomingRewards}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Rewards for period ${formatDuration(Number(flexiData?.currentRewardsDuration ?? 0))}`}
                                        total={flexiData ? `${flexiData.rewardForDuration}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                    {flexiData?.currentPeriodFinish!=='0' ? `Ended ${formatUnixTimestampToDateTime(flexiData?.currentPeriodFinish as string)}`:""}
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Total staked by ${flexiData?.stakerCount ?? 0} users`}
                                        total={flexiData ? `${flexiData.totalStaked}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                     
                                </Box>
                                
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}
                            sx={{
                      
                            }}>
                            <Box>
                                <Comment>Total staked</Comment>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray[700]}`,
                                        padding: '17px',
                                      
                                        '&:hover': {
                                        borderColor: theme.palette.gray[500],
                                        },
                                        [theme.breakpoints.down('md2')]: {
                                        width: '100%',
                                        padding: '11px',
                                        },
                                    }}
                                    >
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Total staked by ${totalStakers} users`}
                                        total={totalSupply.toString()}
                                        tokenType={TokenType.CT}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}
                            sx={{
                               
                            }}>
                            <Box>
                                <Comment>Vested staking</Comment>
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.gray[700]}`,
                                        padding: '17px',
                                      
                                        '&:hover': {
                                        borderColor: theme.palette.gray[500],
                                        },
                                        [theme.breakpoints.down('md2')]: {
                                        width: '100%',
                                        padding: '11px',
                                        },
                                    }}
                                    >
                                    {isTimestampGreaterThanCurrentLocalTime(vestedData?.currentPeriodFinish) ? `Reward period running, ends ${formatUnixTimestampToDateTime(vestedData?.currentPeriodFinish as string)}`:'Reward period not started'}
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Upcoming reward`}
                                        total={vestedData ? `${vestedData.upcomingRewards}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Rewards for period ${formatDuration(Number(vestedData?.currentRewardsDuration ?? 0))}`}
                                        total={vestedData ? `${vestedData.rewardForDuration}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                      {vestedData?.currentPeriodFinish!=='0' ? `Ended ${formatUnixTimestampToDateTime(vestedData?.currentPeriodFinish as string)}` : ''}
                                    <WalletItem
                                        iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                        title={`Total staked by ${vestedData?.stakerCount ?? 0} users`}
                                        total={vestedData ? `${vestedData.totalStaked}` : undefined}
                                        tokenType={TokenType.CT}
                                    />
                                    
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={{ xs: '31px', sm: '38px', md: '68px'}} sx={{ml: '30px'}}>
                    <Grid
                        container
                        direction={{ xs: 'column', sm2: 'row' }}
                        spacing={{ xs: '20px', sm: '28px' }}     
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} sm={6}>
                       
                                
                                <SimpleTabPanel value={activeTab} index={0}>
                                    <SimpleTabsWrapper showBorder={(activeVestedTab) !== 0}>
                                    <SimpleTabs
                                            list={tabs2}
                                            value={activeVestedTab}
                                            onChange={handleVestedTabs}
                                            showBorder={(activeVestedTab) === 0}
                                        />
                                         <SimpleTabPanel value={activeVestedTab} index={0}>
                                            <StakingForm type={StakeType.VESTED} actionType={StakeActionType.STAKE} ccCoins={BigInt(ccCoins)}/>
                                        </SimpleTabPanel>
                                       
                                    </SimpleTabsWrapper>
                                </SimpleTabPanel>

                        </Grid>
                        <Grid item xs={12} sm={6}>

                            {vestedData && vestedData?.vestingSchedule?.periods.length > 0 && 
                                <div>
                                {vestingType(+vestedData.vestingSchedule.vestingType)} {/*+ to convert to number*/}
                                {vestedData.vestingSchedule.periods.map((period:number, index:number) => {
                                    return (
                                        <Box key={index}>
                                            <Comment>Vesting period {index + 1}</Comment>
                                            <Box
                                                sx={{
                                                    border: `1px solid ${theme.palette.gray[700]}`,
                                                    padding: '17px',
                                                  
                                                    '&:hover': {
                                                    borderColor: theme.palette.gray[500],
                                                    },
                                                    [theme.breakpoints.down('md2')]: {
                                                    width: '100%',
                                                    padding: '11px',
                                                    },
                                                }}
                                                >
                                                <WalletItem
                                                    iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                                    title={`released at ${formatUnixTimestampToDateTime(period)}`}   	
                                                    total={vestedData.vestingSchedule.amounts[index]+''}
                                                    tokenType={TokenType.CT}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }) }
                                 <Stack
                                    direction="row"
                                    gap="5px">
                                        <Typography
                                            variant="bodySmall"
                                            align="center"
                                            color={theme.palette.gray[25]}
                                            sx={{
                                            m: '0 auto 22px auto',
                                            [theme.breakpoints.down('sm')]: {
                                                m: '0 auto 18px auto',
                                                width: '100%',
                                            },
                                            }}
                                        >
                                            Reward: {vestedData.accountEarned!==undefined ? formatTokenToString(vestedData.accountEarned,TokenType.WETH):''}
                                        </Typography>
            
                           
                                    </Stack>    
                                </div>
                            }
                            {flexiData && BigInt(flexiData?.accountBalance)>0 &&
                                <div>
                                <Box>
                                    <Comment>Flexi staking</Comment>
                                    <Box
                                        sx={{
                                            border: `1px solid ${theme.palette.gray[700]}`,
                                            padding: '17px',
                                            
                                            '&:hover': {
                                            borderColor: theme.palette.gray[500],
                                            },
                                            [theme.breakpoints.down('md2')]: {
                                            width: '100%',
                                            padding: '11px',
                                            },
                                        }}
                                        >
                                        <WalletItem
                                            iconData={{ src: PNG_ICONS.coins, sx: { width: '24px', height: '24px' } }}
                                            title={`Available now for a withdrawal fee of ${flexiData.unstakeFee}%`}   	
                                            total={flexiData!=undefined ? flexiData.accountBalance+'' : undefined}
                                            tokenType={TokenType.CT}
                                        />
                                    </Box>
                                </Box>
                                <Stack
                                direction="row"
                                gap="5px">
                                    <Typography
                                        variant="bodySmall"
                                        align="center"
                                        color={theme.palette.gray[25]}
                                        sx={{
                                        m: '0 auto 22px auto',
                                        [theme.breakpoints.down('sm')]: {
                                            m: '0 auto 18px auto',
                                            width: '100%',
                                        },
                                        }}
                                    >
                                        Reward: {flexiData.accountEarned !==undefined ? formatTokenToString(flexiData.accountEarned,TokenType.WETH):''}
                                    </Typography>
        
                         
                                </Stack>    
                                </div>
                            }
                            
                        </Grid>
                    </Grid>
                </Box>
            </PageContainer>
        </Box>
        </>
    )
    
}