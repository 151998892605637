const initialState = {
    general: {

    },
    users: {
        users: [],
        filter: null,
        isLoading: false,
    },
    user: {
        user: null,
        matchesPlayed: [],
    },
    payments: {
        payments: null,
        filter: null
    },
    withdrawals: {
        withdrawals: [],
        filter:null
    },
    discountCodes: {
        discountCodes: [],
        filter:null
    },
    status: {
        allowRegister: false,
        allowChallengeToStart: false,
        enforceWhitelist: false,
        enforceUserCountLimit: false,
        userCountLimit: 0,
        showDisturbanceMessage: false,
        showInfoMessage: false,
        infoMessage: '',
        allowJoinChallenge: false,
        allowChallengeToStart: false,
        disturbanceMessage: '',
        botsEnabled: false,
    },
    gameTemplates: [],
    runningChallenges: [],
    finishedChallenges: [],
    availableChallenges: [],
    challenge: null,
    logs: [],
    isError: false,
    errorMessages: [],
    blockchain: {
        ownerBalance: 0
    },
};

export function adminReducer(state = initialState, action) {
    switch (action.type) {
        case "GAME_TEMPLATES":
            return {
                ...state,
                gameTemplates: action.gameTemplates,
            }
        case "UPDATED_GAME_TEMPLATE":
            return {
                ...state,
                gameTemplates: state.gameTemplates.map((item, index) => {
                    if (item.id === action.updatedGameTemplate.id) {
                        return action.updatedGameTemplate;
                    }
                    return item;
                })
            }
        case "RUNNING_CHALLENGES":
            return {
                ...state,
                runningChallenges: action.runningChallenges,
            }
        case "FINISHED_CHALLENGES":
            return {
                ...state,
                finishedChallenges: action.finishedChallenges,
            }
        case "AVAILABLE_CHALLENGES":
            return {
                ...state,
                availableChallenges: action.availableChallenges,
            }
        case "CHALLENGE":
            return {
                ...state,
                challenge: action.challenge,
            }
        case "GENERAL":
            return {
                ...state,
                general: action.general,
            }
        case "USERS":
            return {
                ...state,
                users: {
                    ...state.users,
                    users: action.users,
                    isLoading: false,
                }
            }
        case "USER":
            return {
                ...state,
                user:{ 
                    ...state.user,
                    user: action.user
                }
            }
        case "USER_MATCHES_PLAYED":
            return {
                ...state,
                user: {
                    ...state.user,
                    matchesPlayed: action.matches,
                }
            }

        case "UPDATE_USER":
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.user
                },
            }
        case "SET_FILTER":
            return {
                ...state,
                users: {
                    ...state.users,
                    filter: action.filter,
                    isLoading: true,
                }
            }
        case "PROMOTION":
            return {
                ...state,
                user: {
                    ...state.user,
                    amount: action.promotion.newAmount,
                    transactions : [action.promotion.transaction, ...state.user.transactions]
                },
            }
        case "PAYMENTS":
            return {
                ...state,
                payments: {
                    ...state.payments,
                    payments: action.payments,
                }
            }
        case "WITHDRAWALS":
            return {
                ...state,
                withdrawals: {
                    ...state.withdrawals,
                    withdrawals: action.withdrawals,
                }
            }
        case "UPDATED_WITHDRAWAL":
            return {
                ...state,
                withdrawals: {
                    ...state.withdrawals,
                    withdrawals: state.withdrawals.withdrawals.map((item, index) => {
                        if (item.id === action.withdrawal.id) {
                            return action.withdrawal;

                            
                        }
                        return item;
                    })
                }
            }
        case "DISCOUNT_CODE":
            return {
                ...state,
                discountCodes: {
                    ...state.discountCodes,
                    discountCodes: [action.discountCode, ...state.discountCodes.discountCodes]
                },
            }
        case "UPDATED_DISCOUNT_CODE":
            return {
                ...state,
                discountCodes: {
                    ...state.discountCodes,
                    discountCodes: state.discountCodes.discountCodes.map((item, index) => {
                        if (item.id === action.updatedDiscountCode.id) {
                            return action.updatedDiscountCode;


                        }
                        return item;
                    })
                },
            }
        case "DELETED_DISCOUNT_CODE":
            return {
                ...state,
                discountCodes: {
                    ...state.discountCodes,
                    discountCodes: state.discountCodes.discountCodes.filter(item => item.id !== action.deletedDiscountCode.id)
                },
            }
        case "DISCOUNT_CODES":
            return {
                ...state,
                discountCodes: {
                    ...state.discountCodes,
                    discountCodes: action.discountCodes,
                }
            }
        case "SERVICE_TEXT":
            return {
                ...state,
                status: {
                    ...state.status,
                    disturbanceText: action.text,

                }
            }
        case "SERVICE_STATUS":
            return {
                ...state,
                status: {
                    ...state.status,
                    allowRegister: action.serviceStatus.allowRegister!==undefined ? action.serviceStatus.allowRegister : state.status.allowRegister,
                    allowChallengeToStart: action.serviceStatus.allowChallengeToStart!==undefined ? action.serviceStatus.allowChallengeToStart : state.status.allowChallengeToStart,
                    enforceWhitelist: action.serviceStatus.enforceWhitelist!==undefined ? action.serviceStatus.enforceWhitelist : state.status.enforceWhitelist,
                    enforceUserCountLimit: action.serviceStatus.enforceUserCountLimit!==undefined ? action.serviceStatus.enforceUserCountLimit : state.status.enforceUserCountLimit,
                    userCountLimit: action.serviceStatus.userCountLimit!==undefined ? action.serviceStatus.userCountLimit : state.status.userCountLimit,
                    showDisturbanceMessage: action.serviceStatus.showDisturbanceMessage!==undefined ? action.serviceStatus.showDisturbanceMessage : state.status.showDisturbanceMessage,
                    showInfoMessage: action.serviceStatus.showInfoMessage!==undefined ? action.serviceStatus.showInfoMessage : state.status.showInfoMessage,
                    infoMessage: action.serviceStatus.infoMessage!==undefined ? action.serviceStatus.infoMessage : state.status.infoMessage,
                    allowJoinChallenge: action.serviceStatus.allowJoinChallenge!==undefined ? action.serviceStatus.allowJoinChallenge : state.status.allowJoinChallenge,
                    allowChallengeToStart: action.serviceStatus.allowChallengeToStart!==undefined ? action.serviceStatus.allowChallengeToStart : state.status.allowChallengeToStart,
                    disturbanceMessage: action.serviceStatus.disturbanceMessage!==undefined ? action.serviceStatus.disturbanceMessage : state.status.disturbanceMessage,
                    botsEnabled: action.serviceStatus.botsEnabled!==undefined ? action.serviceStatus.botsEnabled : state.status.botsEnabled,
                }
            }
        case "LOGS":
            return {
                ...state,
                logs: action.logs.results,
            }
        case "CANCEL_CHALLENGE_JOB":
            const id = action.id;
            return {
                ...state,
                runningChallenges: state.runningChallenges.map((item, index) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            interrupt: true,
                           
                        };
                    }
                    return item;
                })
            }
        case "IS_WORKING":
            return {
                ...state,
                working: true,

            }
        case "FINISHED_WORKING":
            return {
                ...state,
                working: false,

            }
        case "SET_ERROR":   
            
            return {
                ...state,
                isError: true,
                errorMessages: [] // [...state.errorMessages, action.errorMessage]

            }
        case "CLEAR_ERROR":
            return {
                ...state,
                isError: false,
                errorMessages: []

            }
        case "OWNER_BALANCE_RESULT":
            return {
                ...state,
                blockchain: {
                    ...state.blockchain,
                    ownerBalance: action.ownerBalance
                }
            }
        default:
            return state;
    }
}