import React, { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import Button, { ButtonProps } from '../Button/Button'
import Corner from '../Corner/Corner'
import Loader from '../Loader/Loader'
import Typography from '../Typography/Typography'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

enum Status {
  Waiting = 'waiting',
  Commencing = 'commencing',
  Cancelled = 'cancelled',
}

type StatusType = `${Status}`

export type LobbyStatusBarProps = BoxProps & {
  status?: StatusType
  text: string
  stackProps?: StackProps
  btnProps?: ButtonProps
  closeToast?: () => void
}

export default function LobbyStatusBar({
  status = Status.Waiting,
  text,
  stackProps,
  btnProps,
  closeToast,
  ...restProps
}: LobbyStatusBarProps) {
  const theme = useTheme()

  const getBgColor = useCallback(() => {
    switch (status) {
      case Status.Waiting:
        return theme.palette.gray[800]
      case Status.Commencing:
        return theme.palette.background.notificationSuccess
      case Status.Cancelled:
        return theme.palette.background.notificationError
      default:
        return theme.palette.gray[800]
    }
  }, [status, theme.palette])

  const { sx, ...restStackProps } = stackProps || {}

  const onOpenLobby = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (btnProps?.onClick) {
        btnProps.onClick(event)
      }
      if (closeToast) {
        closeToast()
      }
    },
    [btnProps, closeToast]
  )

  return (
    <Corner variant="bottomRight" {...restProps}>
      <Stack
        direction="row"
        minWidth="302px"
        bgcolor="gray.800"
        padding="9px 9px 9px 12px"
        alignItems="center"
        gap="13px"
        border="1px solid"
        borderColor="gray.700"
        sx={{
          // @ts-ignore
          boxShadow: theme.shadows[26],
          background: getBgColor(),
          ...sx,
        }}
        {...restStackProps}
      >
        {status === Status.Waiting && <Loader size="small" />}
        {status === Status.Commencing && (
          <Box fontSize="18px" display="flex" pl="1px">
            <SvgIcon name="check-double-line" fontSize="inherit" color="success" />
          </Box>
        )}
        {status === Status.Cancelled && (
          <Box fontSize="18px" display="flex" pl="1px">
            <SvgIcon name="close-circle-line" fontSize="inherit" color="error" />
          </Box>
        )}
        <Typography variant="description" flexGrow={1}>
          {text}
        </Typography>
        <Button variant="contained" size="small" {...btnProps} onClick={onOpenLobby}>
          {btnProps?.children || 'open lobby'}
        </Button>
      </Stack>
    </Corner>
  )
}
