import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Stack, Collapse } from '@mui/material'
import { ROUTE_PATH } from 'src/routes'
import Button from 'src/components/Button/Button'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import HeaderTokens from 'src/components/HeaderTokens/HeaderTokens'
import HeaderUserCell from 'src/components/HeaderUserCell/HeaderUserCell'
import Logo from 'src/components/Logo/Logo'
import MenuItem from 'src/components/MenuItem/MenuItem'
import Popup from 'src/components/Popup/Popup'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import MobileHeader from 'src/components/Header/MobileHeader'
import Drawer from 'src/components/Drawer/Drawer'
import { loginWallet } from "../../actions/userActions";
import { UserType } from 'src/helpers/types';
import { ExchangeRateTicker } from 'src/components/Ticker/ExchangeRateTicker'
import { logout } from "src/actions/userActions";

export type HeaderProps = {
    isWalletConnected: boolean,
    user: UserType
}

export default function Header({ isWalletConnected, user }: HeaderProps) {
    const [cartWalletModalIsOpen, setCartWalletModalIsOpen] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme()
    const isLessThan1300 = useMediaQuery(theme.breakpoints.down(1300))
    const isLessThan1114 = useMediaQuery(theme.breakpoints.down(1114))
    const isLessThan1000 = useMediaQuery(theme.breakpoints.down(1000))
    const isLgView = useMediaQuery(theme.breakpoints.down('lg'))
    const isMdView = useMediaQuery(theme.breakpoints.down('md'))
    const isLessThan834 = useMediaQuery(theme.breakpoints.down('md834'))
    const isLessThan720 = useMediaQuery(theme.breakpoints.down('md720'))

    const onLogout = (event: React.KeyboardEvent | React.MouseEvent) => {
      dispatch(logout())
      toggleDrawer(event)
    }

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setIsDrawerOpen((prevState) => !prevState)
    }

    const renderMenuItems = useCallback(() => {
      if (!isWalletConnected && isMdView) {
        return null
      }
      if (isWalletConnected && isLessThan1300) {
        return null
      }
      return (
        <Stack
          direction="row"
          spacing="28px"
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="nowrap"
        >
          {[
            {
              label: 'Startpage',
              href: ROUTE_PATH.startPage,
            },
            {
              label: 'Game Templates',
              href: ROUTE_PATH.templates,
            },
            {
              label: 'Tournaments',
              href: ROUTE_PATH.tournaments,
            },
            {
              label: 'Challenges',
              href: ROUTE_PATH.ontime,
            },
            {
              label: 'Status',
              href: ROUTE_PATH.status,
            },
            {
              label: 'Users',
              href: ROUTE_PATH.users,
            },
            {
              label: 'Staking',
              href: ROUTE_PATH.staking,
            },
          ].map((item, index) => {
            return (
              <MenuItem key={`header-menu-item-${item.label}-${index}`} href={item.href}>
                {item.label}
              </MenuItem>
            )
          })}
        </Stack>
      )
    }, [isWalletConnected, isLessThan1300, isMdView])

    const renderExchangeRates = useCallback(() => {
      if (isWalletConnected && isLessThan1000) {
        return null
      }
      if (!isWalletConnected && isLessThan1114 && !isMdView) {
        return null
      }
      return (
        <ExchangeRateTicker BoxProps={{ width: 255 }} />
      )
    }, [isWalletConnected, isLessThan1000, isLessThan1114, isMdView])

    const renderConnectWalletBtn = useCallback(() => {
      return (
        <Collapse in={!isWalletConnected} orientation="horizontal">
          <Button
            endIcon={<SvgIcon name="wallet-line" sx={{ marginRight: '4px' }} />}
            sx={{ minWidth: isMdView ? '217px' : '172px' }}
            variant="contained"
            size={isMdView ? 'big' : 'medium'}
            onClick={() => setCartWalletModalIsOpen(true)}
          >
            Connect wallet
          </Button>
        </Collapse>
      )
    }, [isWalletConnected, isMdView])

    const renderUserMenu = useCallback(() => {
      return (
        <Stack direction="row" alignItems="center">
          <Collapse in={isWalletConnected} orientation="horizontal">
            <Stack direction="row" gap="24px" alignItems="center">
            
              <HeaderUserCell
                user={{
                  name: user.nickname,
                  avatarUrl: 'https://i.pravatar.cc/58',
                  isOnline: true,
                }}
                onClick={()=>navigate('/profile')}
              />
            </Stack>
          </Collapse>
          {renderConnectWalletBtn()}
        </Stack>
      )
    }, [isWalletConnected, renderConnectWalletBtn])

    const renderMenuIcon = useCallback(() => {
      if (!isWalletConnected && !isLessThan1114) {
        return null
      } else if (isWalletConnected && !isLessThan1300) {
        return null
      }
      return (
        <ButtonIcon
          variant={isDrawerOpen ? 'contained' : 'outlined'}
          size={isMdView || (isWalletConnected && isLessThan1300) ? 'big' : 'medium'}
          onClick={toggleDrawer}
        >
          <SvgIcon name={isDrawerOpen ? 'close-fill' : 'menu-fill'} />
        </ButtonIcon>
      )
    }, [isWalletConnected, isLessThan1300, isMdView, isDrawerOpen, !isLessThan1114])

    const renderHeaderContent = useCallback(() => {
      return (
        <Box
          minHeight="64px"
          display="flex"
          gap={isLgView ? '39.5px' : '48px'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={isLgView ? '28.5px' : '47.5px'} alignItems="center">
            <Logo variant="logo-colored-admin" size="small" onClick={()=> navigate('/')} />
            {renderExchangeRates()}
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            gap={isWalletConnected && isLessThan1300 ? '8px' : '48px'}
          >
            {renderMenuItems()}
 	<Stack direction="row" alignItems="center" gap="8px">
            {renderUserMenu()}
            {renderMenuIcon()}
        </Stack>
          </Box>
        </Box>
      )
    }, [
      isWalletConnected,
      isLessThan1300,
      isLgView,
      renderExchangeRates,
      renderMenuIcon,
      renderMenuItems,
      renderUserMenu,
    ])

    
    return (
      <>
        {(!isWalletConnected && isLessThan834) || (isWalletConnected && isLessThan720) ? (
          <>
            <MobileHeader
              isWalletConnected={isWalletConnected}
              isDrawerOpen={isDrawerOpen}
              onToggleDrawer={toggleDrawer}
              user={user}
            />
            <Popup
              open={cartWalletModalIsOpen}
              title="You are not Login"
              iconData={{
                name: 'user-locked',
                fontSize: 'extraLarge',
              }}
              description="To enter challenge and participated in it you have to be Registered on Challenge.gg"
              onClose={() => {
                setCartWalletModalIsOpen(false)
              }}
              primaryButtonData={{
                onClick: () => {
                  setCartWalletModalIsOpen(false)
                  dispatch(loginWallet())                
                },
                label: 'Connect wallet',
                endIcon: (
                  <SvgIcon
                    name="wallet-line"
                    sx={{
                      [theme.breakpoints.down('sm')]: { mr: '5px' },
                    }}
                  />
                ),
              }}
            />
          </>
        ) : (
          <Box
            minHeight="94px"
            py="15px"
            px="16px"
            bgcolor="gray.900"
            position="sticky"
            top={0}
            zIndex={theme.zIndex.drawer + 1}
          >
            {renderHeaderContent()}
          </Box>
        )}
        <Drawer
          isWalletConnected={isWalletConnected}
          open={isDrawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          onLogout={onLogout}
          user={user}
          onConnect={
            ()=> {setCartWalletModalIsOpen(true)
            setIsDrawerOpen(false)}
          }
        />
        <Popup
          open={cartWalletModalIsOpen}
          title="You are not Login"
          iconData={{
            name: 'user-locked',
            fontSize: 'extraLarge',
          }}
          description="To enter challenge and participated in it you have to be Registered on Challenge.gg"
          onClose={() => {
            setCartWalletModalIsOpen(false)
          }}
          primaryButtonData={{
            onClick: () => {
                  setCartWalletModalIsOpen(false)
                  dispatch(loginWallet())
            },
            label: 'Connect wallet',
            endIcon: <SvgIcon name="wallet-line" />,
          }}
        />
    </>
  )
}
