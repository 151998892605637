import { alpha, Box, Grid, Modal, useTheme } from '@mui/material'
import React, { ReactElement } from 'react'
import Button, { ButtonProps } from '../Button/Button'
import MainButton, { MainButtonProps } from '../MainButton/MainButton'
import SvgIcon, { SvgIconProps } from '../SvgIcon/SvgIcon'
import Typography from '../Typography/Typography'
import { ImageProps } from '../Image/Image'
import ImageWithTitle from '../ImageWithTitle/ImageWithTitle'
import useMediaQuery from '@mui/material/useMediaQuery'

export type PopupContentProps = {
  title?: string
  description?: string
  iconData?: SvgIconProps
  primaryButtonData?: MainButtonProps & {
    label: string
  }
  cancelButtonData?: ButtonProps & {
    label: string
  }
  headerData?: {
    sx?: ButtonProps['sx']
    title?: string
    iconSrc?: ImageProps['src']
    backgroundImage?: ReactElement
  }
  sx?: ButtonProps['sx']
  onClose?: () => void
  headerContainer?: ReactElement
  bodyContainer?: ReactElement
  footerContainer?: ReactElement
}

export const PopupContent = (props: PopupContentProps) => {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  const isMdView = useMediaQuery(theme.breakpoints.down('md'))
  let footerContainer = props.footerContainer
  const { name: nameIcon, ...restIconData } = props.iconData || {}

  if (props.primaryButtonData && !props.cancelButtonData) {
    footerContainer = (
      <Button variant="spaceBetween" size="large" fullWidth {...props.primaryButtonData}>
        {props.primaryButtonData.label}
      </Button>
    )
  }

  if (props.primaryButtonData && props.cancelButtonData) {
    footerContainer = (
      <Grid
        container
        sx={{
          display: 'grid',
          gap: '2px',
          gridTemplateColumns: '8fr 2.4fr',
        }}
      >
        <MainButton variant="spaceBetween" fullWidth {...props.primaryButtonData}>
          {props.primaryButtonData.label}
        </MainButton>
        <Button variant="outlined" size="medium" fullWidth {...props.cancelButtonData}>
          {props.cancelButtonData.label}
        </Button>
      </Grid>
    )
  }
  const commonButtonSx = {
    backgroundColor: 'transparent',
    color: theme.palette.gray[25],
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: theme.palette.gray[0],
    },
  }
  return (
    <Grid
      container
      flexDirection={'column'}
      sx={{
        minHeight: '352px',
        maxWidth: '406px',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.common.black}`,
        boxShadow: `0px 0px 0px 2px ${theme.palette.gray[800]}`,
        [theme.breakpoints.down('md')]: {
          minHeight: '349px',
        },
        [theme.breakpoints.down('sm')]: {
          minHeight: '334px',
          maxWidth: '343px',
          width: '100%',
          margin: '0 auto',
        },
        ...(props.sx || {}),
      }}
    >
      {/* Header */}
      <Grid>
        {props.headerContainer ? (
          props.headerContainer
        ) : (
          <Box
            sx={{
              backgroundColor: theme.palette.gray[800],
              height: '40px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'relative',
              ...props.headerData?.sx,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: 33,
                left: 19,
                zIndex: 2,
                [theme.breakpoints.down('md')]: {
                  left: 23,
                  bottom: 34,
                },
                [theme.breakpoints.down('sm')]: {
                  bottom: 25,
                  left: 13,
                },
              }}
            >
              {props.headerData?.title && props.headerData?.iconSrc && (
                <ImageWithTitle
                  rootStackProps={{ spacing: { xs: '8px', sm: '10px', md: '6px' } }}
                  typographyProps={{ variant: isSmView ? 'h7' : 'h6' }}
                  imageProps={{
                    src: props.headerData?.iconSrc,
                    sx: {
                      [theme.breakpoints.down('md')]: {
                        width: '22px',
                        height: '22px',
                      },
                    },
                  }}
                  title={props.headerData?.title}
                  imageBoxProps={{ size: isMdView ? 'xs' : 'small' }}
                />
              )}
            </Box>

            {props.headerData?.backgroundImage && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  opacity: '0.5',
                  overflow: 'hidden',
                  '& img': {
                    objectFit: 'cover',
                    objectPosition: 'top left',
                  },
                }}
              >
                {props.headerData?.backgroundImage}
              </Box>
            )}
            <Button
              onClick={props.onClose}
              sx={
                props.headerData?.backgroundImage
                  ? {
                      width: '60px',
                      height: '60px',
                      ...commonButtonSx,
                    }
                  : {
                      paddingX: '27px',
                      paddingY: '35px',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '24px',
                        paddingY: '32px',
                      },
                      [theme.breakpoints.down('sm')]: {
                        paddingX: '21px',
                        paddingY: '33px',
                      },
                      ...commonButtonSx,
                    }
              }
            >
              <SvgIcon
                name="exit-icon"
                fontSize="small"
                sx={{
                  width: '17px',
                }}
              />
            </Button>
          </Box>
        )}
      </Grid>
      {/* Body */}
      <Grid
        container
        flexDirection={'column'}
        alignItems="center"
        gap={'24px'}
        sx={{
          padding: '0 28px 20px',
          flexGrow: 1,
          backgroundColor: theme.palette.gray[800],
          mb: '2px',
          ...(props.headerData?.backgroundImage ? { paddingTop: '27px' } : {}),
          [theme.breakpoints.down('md')]: {
            px: '24px',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '0 12px 24px',
          },
        }}
      >
        {props.bodyContainer ? (
          props.bodyContainer
        ) : (
          <>
            {props.title && (
              <Typography
                variant="h5"
                align="center"
                sx={{ mt: { xs: '11px', sm: '10px', md: '13.2px' } }}
              >
                {props.title}
              </Typography>
            )}
            {props.iconData && (
              <SvgIcon
                sx={{
                  fontSize: '48px',
                  width: '48px',
                  height: '48px',
                  ...(props.iconData?.sx || {}),
                }}
                name={nameIcon || 'wallet-line'}
                color={'warning'}
                {...restIconData}
              />
            )}
            {props.description && (
              <Typography
                variant="bodySmall"
                align="center"
                sx={{
                  width: '358px',
                  color: theme.palette.gray[25],
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    maxWidth: '300px',
                  },
                }}
              >
                {props.description}
              </Typography>
            )}
          </>
        )}
      </Grid>
      {/* Footer */}
      {footerContainer}
    </Grid>
  )
}

export type PopupProps = PopupContentProps & {
  open: boolean
  onClose: () => void
}

const Popup = (props: PopupProps) => {
  const theme = useTheme()

  let container

  if (typeof window !== 'undefined') {
    container = document.getElementById('modal-root')
  }

  return (
    <Modal
      open={props.open}
      container={container ? container : undefined}
      BackdropProps={{
        sx: {
          background: alpha(theme.palette.gray[900], 0.84),
        },
      }}
      onClose={props.onClose}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            px: '16px',
          },
        }}
      >
        <PopupContent {...props} />
      </Box>
    </Modal>
  )
}

export default Popup
