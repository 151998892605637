import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Avatar, Stack, useMediaQuery } from '@mui/material'
import { WinnerLobbyBoxProps } from 'src/components/WinnerLobbyBox/WinnerLobbyBox'
import Typography from 'src/components/Typography/Typography'
import WinnerBox from 'src/components/WinnerBox/WinnerBox'
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount'
import WinnerPlaceNum from 'src/components/WinnerPlaceNum/WinnerPlaceNum'
import GradientBorder from '../GradientBorder/GradientBorder'

type HorizontalWinnerBoxProps = Omit<WinnerLobbyBoxProps, 'isVertical'>

export default function HorizontalWinnerBox({
  avatarSrc,
  name,
  place = 1,
  amount,
}: HorizontalWinnerBoxProps) {
  const theme = useTheme()
  const isSmView = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Stack direction="row" spacing="1px" alignItems="center">
      <GradientBorder place={place}>
        <WinnerBox
          width="240px"
          height="82px"
          p="7px"
          borderColor="gray.800"
          display="flex"
          alignItems="center"
          flexDirection="row"
          gap="18px"
          place={place}
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: '210px',
              height: '58px',
              p: '4px',
              gap: '12px',
            },
          }}
        >
          <Avatar
            src={avatarSrc}
            alt="Winner"
            variant="square"
            sx={{
              width: '68px',
              height: '68px',
              border: '1px solid',
              borderColor: 'gray.600',
              [theme.breakpoints.down('sm')]: {
                width: '52px',
                height: '52px',
              },
            }}
          />
          <Box width="100%">
            <Typography
              variant="labelSmall"
              color="gray.25"
              minHeight="26px"
              display="flex"
              alignItems="center"
            >
              {name}
            </Typography>
            <WinnerPlaceNum place={place} mt="2px" />
          </Box>
        </WinnerBox>
      </GradientBorder>

      <GradientBorder>
        <WinnerBox
          width="108px"
          height="82px"
          py="5px"
          px="11px"
          borderColor="gray.700"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          gap="4px"
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: '88px',
              height: '58px',
              p: '7px',
            },
          }}
        >
          <Typography variant="paragraphMedium" color="gray.500" height="19px">
            Receive
          </Typography>
          <CoinImageWithAmount
            spacing="8px"
            avatarProps={{ sx: { width: '24px', height: '24px' } }}
            amount={amount}
            amountTypographyProps={{
              variant: isSmView ? 'h7' : 'h6',
              color: 'gray.0',
            }}
          />
        </WinnerBox>
      </GradientBorder>
    </Stack>
  )
}
