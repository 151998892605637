import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material'
import Logo from 'src/components/Logo/Logo'
import ButtonIcon from 'src/components/ButtonIcon/ButtonIcon'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import HeaderUserCell from 'src/components/HeaderUserCell/HeaderUserCell'
import { UserType } from 'src/helpers/types'

type MobileHeaderProps = {
  isWalletConnected?: boolean
  isDrawerOpen?: boolean
  onToggleDrawer?: (event: React.KeyboardEvent | React.MouseEvent) => void
  user: UserType
}

export default function MobileHeader({
  isDrawerOpen,
  onToggleDrawer,
  isWalletConnected,
  user
}: MobileHeaderProps) {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      minHeight="56px"
      pt="8px"
      px="16px"
      bgcolor="gray.900"
      position="sticky"
      top={0}
      zIndex={1100}
    >
      <Logo variant="logo-colored" size="xxs" onClick={()=> navigate('/')} />
      <Stack direction="row" spacing="8px">
        {isWalletConnected ? (
          <HeaderUserCell
            user={{
              name: user.nickname,
              avatarUrl: 'https://i.pravatar.cc/58',
              isOnline: true,
            }}
            onClick={()=>navigate('/profile')}
          />
        ) : (
          <ButtonIcon variant="contained" size="small">
            <SvgIcon name="wallet-line" />
          </ButtonIcon>
        )}
        <ButtonIcon
          variant={isDrawerOpen ? 'contained' : 'outlined'}
          size="small"
          onClick={onToggleDrawer}
        >
          <SvgIcon name={isDrawerOpen ? 'close-fill' : 'menu-fill'} />
        </ButtonIcon>
      </Stack>
    </Stack>
  )
}
