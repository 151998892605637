import React, { useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'src/routes'
import { createCtx } from './helpers'

type AuthContextType = {
  isAuth: boolean
  isWalletConnected: boolean
  login: () => void
  connectWallet: () => void
  disconnectWallet: () => void
  setWalletConnected: (connected:boolean) => void
  user: any
  setUser : (user:any) => void
}

const [useAuthContext, AuthContextProvider] = createCtx<AuthContextType>()

export { useAuthContext }

export default function AuthProvider({ children }: React.PropsWithChildren<unknown>) {
  const [isAuth] = useState(false)
  const [isWalletConnected, setIsWalletConnected] = useState(false)
  const [user, setUser] = useState(undefined)

  const navigate = useNavigate()

  const connectWallet = useCallback(() => {
    setIsWalletConnected(true)
  }, [])

  const disconnectWallet = useCallback(() => {
    setIsWalletConnected(false)
  }, [])

  const setWalletConnected = useCallback((connected:boolean) => {
    setIsWalletConnected(connected)
  }, [])

  // eslint-disable-next-line
  const login = useCallback(() => {}, [])

  const authValue = useMemo(
    () => ({
      isAuth,
      isWalletConnected,
      connectWallet,
      disconnectWallet,
      setWalletConnected,
      login,
      user,
      setUser
    }),
    [isAuth, isWalletConnected, connectWallet, disconnectWallet, setWalletConnected, login, user, setUser]
  )
  // console.log('authValue', authValue)
  return <AuthContextProvider value={authValue}>{children}</AuthContextProvider>
}
