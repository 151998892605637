export const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength16 = maxLength(16)

const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minLength8 = minLength(8)

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Not a valid e-mail address'
        : undefined;

export const phoneNumber = value =>
    value && !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/i.test(value)
        ? 'Not a valid phone number'
        : undefined;

export const alphaNumeric = value =>
    value && /[^A-Za-z0-9-åÅäÄöÖ ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined

export const passwordsMustMatch = (value, values) =>
    value !== values.password ?
        'Passwords do not match'
        : undefined

export const numbersOnly = (value) =>
    value && !/^-?\d+\.?\d*$/i.test(value)
        ? 'Not a valid zipcode'
        : undefined