import React, { useState, useMemo, useCallback } from 'react'
import { ChallengeCardProps } from 'src/components/ChallengeCard/ChallengeCard'
import { ImageProps } from 'src/components/Image/Image'
import { createCtx } from './helpers'
import { challengeItems } from 'src/helpers/mockData'

type PayloadT = {
  filter?: {
    title: string
  }
  itemsOfPage?: number
  pageNumber?: number
}
type Challenges = Array<
  Omit<ChallengeCardProps, 'btnProps'> & {
    iconSrc: ImageProps['src']
  }
>
type ChallengeContextType = {
  challenges: Challenges
  totalChallenges: number
  showChallenge: number
  loadMore?: () => void
  load?: (payload: PayloadT) => void
}

const [useChallengeContext, ChallengeContextProvider] = createCtx<ChallengeContextType>()

export { useChallengeContext }

export default function ChallengeProvider({ children }: React.PropsWithChildren<unknown>) {
  const [challenges, setChallenges] = useState<ChallengeContextType['challenges']>([])
  const [total, setTotal] = useState(0)
  const load = useCallback(async () => {
    try {
      await new Promise((res) => setTimeout(res, 1000))
      setChallenges(challengeItems)
      setTotal(challengeItems.length)
    } catch (error) {
      setChallenges([])
      setTotal(0)
    }
  }, [challengeItems])

  const ChallengeValue = useMemo(
    () => ({
      load,
      challenges,
      showChallenge: challenges?.length,
      totalChallenges: total,
    }),
    [challenges, total]
  )
  return <ChallengeContextProvider value={ChallengeValue}>{children}</ChallengeContextProvider>
}
