import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { forwardRef } from 'react';
import { getAvailableChallenges, updateChallenge } from '../../actions/adminActions';
import { translateChallengeType } from '../../utils/translate'


{/*}
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
*/}
export const AvailableChallenges = () => {

    var columns = [ { title: "id", field: "id", hidden: true },
        { title: "Type", render: rowData => translateChallengeType(rowData.type) },
        { title: "Fee", field: "fee", type: 'currency' },
        { title: "MaxParticipants", field: "maxParticipans", type: 'numeric' },
        {
            title: "Disabled", field: "disabled", type: 'boolean',
            //render: rowData => <Switch value={rowData.disabled} checked={rowData.disabled} />, render works
           // editComponent: props => <Switch value={props.disabled} checked={props.disabled} onChange={e => props.onChange(e.target.value)} /> //editComponent does not work
        },
        { title: "NumberOfParticipants", field: "numberOfParticipants", editable: 'never', type: 'numeric'}
                  ];
    const dispatch = useDispatch();

    const { challenges, remoteHasError, remoteErrorMessages } = useSelector(state => ({
        challenges: state.adminReducer.availableChallenges,
        remoteHasError: state.adminReducer.isError,
        remoteErrorMessages: state.adminReducer.errorMessages
    }), shallowEqual);

    const [isError, setIsError] = useState(remoteHasError)
    const [errorMessages, setErrorMessages] = useState(remoteErrorMessages)

    useEffect(() => {
        dispatch(getAvailableChallenges());
    }, ['All']); //Avoids infinite loop

    useEffect(() => {
        setIsError(remoteHasError);
    }, [remoteHasError]);

    useEffect(() => {
        setErrorMessages(remoteErrorMessages);
    }, [remoteErrorMessages]);

    const handleRowUpdate = (newData, oldData, resolve) => {
        console.log("newData=" + newData.disabled);
        console.log("oldData=" + oldData.disabled);
        let errorList = [];
        if (isNaN(newData.fee)) {
            errorList.push(newData.fee +" is not a valid number")
        }

        if (errorList.length < 1) {
            dispatch(updateChallenge(newData.id, newData.fee, newData.maxParticipans, newData.disabled));
        }
        else {
            setErrorMessages(errorList)
            setIsError(true)
        }
        resolve();
    }

    return (
        <>
        {/*        <Grid container spacing={1}>

            <Grid item xs={8}>
            <div>
                {isError &&
                    <Alert severity="error">
                        {errorMessages.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                        })}
                    </Alert>
                }
            </div>
            <MaterialTable
                    title="Challenges"
                    columns={columns}
                    data={challenges}
                    icons={tableIcons}
                    options={{
                        pageSize: 15,
                        pageSizeOptions: [15,50],
                        toolbar: true,
                        paging: false,
                        search: false,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                handleRowUpdate(newData, oldData, resolve);
                            }),
                       
                    }}
            />
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>  */}
        </>
        );
}