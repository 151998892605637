import React from 'react'
import { alpha } from '@mui/material/styles'
import { Dialog, DialogProps } from '@mui/material'

type DialogWrapperProps = DialogProps

export default function DialogWrapper({
  open,
  fullWidth,
  maxWidth = 'xs',
  onClose,
  children,
  ...restProps
}: DialogWrapperProps) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: (theme) => ({
            background: alpha(theme.palette.gray[900], 0.84),
          }),
        },
      }}
      PaperProps={{
        elevation: 0,
        square: true,
        sx: {
          backgroundColor: 'transparent',
        },
      }}
      {...restProps}
    >
      {children}
    </Dialog>
  )
}
