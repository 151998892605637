// @ts-ignore
import hero from './hero.mp4'
// @ts-ignore
import lobbyFilled from './lobby-filled.mp4'
// @ts-ignore
import lobbySolo from './lobby-solo.mp4'

const VIDEOS = {
  hero,
  lobbyFilled,
  lobbySolo,
}

export default VIDEOS
