import {
  IndexRouteObject as IndexRouteObj,
  NonIndexRouteObject as NonIndexRouteObj,
} from 'react-router-dom'

export const ROUTE_PATH = {
  root: '/',
  registration: '/registration',
  startPage: '/',
  startPageLogin: '/',
  leaderboard: '/leaderboard',
  challenges: '/challenges',
  game: '/challenges/game/:gameId',
  lobby: '/lobby/:gameId',
  profile: '/profile',
  templates: '/templates',
  tournaments: '/tournaments',
  ontime: '/ontime',
  status: '/status',
  users: '/users',
  staking: '/staking',
} as const

interface NonIndexRouteObject extends NonIndexRouteObj {
  label?: string
  disabled?: boolean
  isHeaderLink?: boolean
  children?: RouteObject[]
}

type RouteObject = IndexRouteObj | NonIndexRouteObject

export function getHeaderLinks(): RouteObject[] | [] {
 return [
    {"path":"/","isHeaderLink":true,"label":"Startpage"},
    {"path":"/templates","isHeaderLink":true,"label":"Game Templates"},
    {"path":"/tournaments","isHeaderLink":true,"label":"Tournaments"},
    {"path":"/ontime","isHeaderLink":true,"label":"OnTime"},
    {"path":"/status","isHeaderLink":true,"label":"Status"},
    {"path":"/users","isHeaderLink":true,"label":"Users"},

 ];
}

export function getProfileHeaderLinks() {
  return [
    {"path":"/profile","isHeaderLink":true,"label":"General"},
    {"path":"/profile/assets","isHeaderLink":true, disabled:true, "label":"My Assets"},
    {"path":"/profile/other","isHeaderLink":true, disabled:true, "label":"Other"},
 ];
}