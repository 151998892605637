import React from 'react'
import { Box, Grid, BoxProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Image, { ImageProps } from 'src/components/Image/Image'
import Typography from 'src/components/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { TokenType } from 'src/helpers/types'
import { formatTokenToString } from 'src/utils/tokenUtil'

export interface WalletItemProps {
  total?: string 
  tokenType?: TokenType
  title: string
  iconData: {
    src: ImageProps['src']
    sx: BoxProps['sx']
  }
}

const WalletItem = ({ iconData, total, tokenType, title }: WalletItemProps) => {
  const theme = useTheme()
  const isSm2View = useMediaQuery(theme.breakpoints.down('sm2'))
  return (
    <Grid container spacing={{ xs: '8px', sm2: '16px', md2: '18px' }}>
      <Grid item>
        <Box
          sx={{
            width: '62px',
            height: '62px',
            background: theme.palette.gray[800],
            border: `1px solid ${theme.palette.gray[700]}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm2')]: {
              width: '44px',
              height: '44px',
            },
          }}
        >
          <Box component={Image} src={iconData.src} alt="coints" sx={iconData.sx}></Box>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            mt: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm2')]: {
              mt: '1px',
            },
          }}
        >
          <div>
            <Typography variant={isSm2View ? 'h7' : 'h6'} mb={{ xs: '5px', sm2: '4px' }}>
               {tokenType!==undefined ? formatTokenToString(total,tokenType,6,false,true,true): total}
            </Typography>
            <Typography
              variant={isSm2View ? 'paragraphSmall' : 'paragraphMedium'}
              sx={{ color: `${theme.palette.gray[500]}` }}
            >
              {title}
            </Typography>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WalletItem
