import { Box, BoxProps } from '@mui/material'
import React from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'

type TrophyNumberProps = BoxProps & {
  place: number
  numberBoxProps?: BoxProps
}
// List of Winner Place num icon
enum NumIcon {
  WinnerPlaceNum1 = 'WinnerPlaceNum1',
  WinnerPlaceNum2 = 'WinnerPlaceNum2',
  WinnerPlaceNum3 = 'WinnerPlaceNum3',
}

function TrophyNumber({ numberBoxProps, place, ...restProps }: TrophyNumberProps) {
  return (
    <Box
      color={getPrizeObj(place).color}
      fontSize="17px"
      width="17px"
      height="17px"
      position="relative"
      {...restProps}
    >
      <SvgIcon name="trophy-fill" fontSize="inherit" color="inherit" sx={{ display: 'block' }} />
      <Box
        fontSize="6px"
        color="gray.900"
        position="absolute"
        textAlign="center"
        top={0}
        bottom={0}
        left={0}
        right={0}
        pt="4px"
        {...getPrizeObj(place).boxProps}
        {...numberBoxProps}
      >
        <SvgIcon
          name={getPrizeObj(place).numIcon}
          fontSize="inherit"
          color="inherit"
          sx={{ zIndex: 1 }}
        />
      </Box>
    </Box>
  )
}

// Creating a list of objects of parameters for prizes
const getPrizeObj = (val: number) => {
  switch (val) {
    case 1:
      return {
        color: 'primary.main',
        numIcon: NumIcon.WinnerPlaceNum1,
        boxProps: {
          pl: '1px',
        },
      }
    case 2:
      return {
        color: 'gray.100',
        numIcon: NumIcon.WinnerPlaceNum2,
        boxProps: {
          pr: '1px',
        },
      }
    case 3:
      return {
        color: '#C69040',
        numIcon: NumIcon.WinnerPlaceNum3,
        boxProps: {
          pr: '1px',
        },
      }
    default:
      return {
        color: 'gray.100',
        numIcon: NumIcon.WinnerPlaceNum1,
      }
  }
}

export default TrophyNumber
