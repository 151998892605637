import React, { useState, useEffect, useCallback } from 'react'
import ExchangeRates, {ExchangeRatesProps} from 'src/components/ExchangeRates/ExchangeRates'

const BNB_URL = 'https://www.binance.com/api/v3/ticker/price?symbol=BNBUSDT';
const ETH_URL= 'https://www.binance.com/api/v3/ticker/price?symbol=ETHUSDT';
//const ETH_URL= 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';

export function ExchangeRateTicker({ BoxProps, ExchangeRateProps }: Omit<ExchangeRatesProps,'data'> ) {
    const [ticker, setTicker] = useState(0);

    useEffect(() => {
        fetch(ETH_URL)
            .then(data => data.json())
            .then(obj => setTicker(() => obj.price))
            .catch(console.error);

        const interval = setInterval(() => {

            fetch(ETH_URL)
                .then(data => data.json())
                .then(obj => setTicker(() => obj.price))
                .catch(console.error);


        }, 180000);

        return () => clearInterval(interval);
    }, []);

    const parseCurrency = (value: number | string) => {
        return (+value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      
    return (
        <ExchangeRates
              ExchangeRateProps={ExchangeRateProps} BoxProps={BoxProps}
              data={[
                {
                  label: 'CC/USD',
                  value: `$ 2,43`,
                },
                {
                  label: 'ETH/USD',
                  value:  parseCurrency(ticker),
                },
              ]}
            />
       
    )
}
