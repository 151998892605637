import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import  MainLayout from './layouts/MainLayout'
import RootLayout from './layouts/RootLayout'
import { LayoutBasic } from './layouts/BasicLayout'
import { RequireAdmin } from './utils/requireAdmin'
import  Home  from './pages'
import Login from './components/login'
import Logout from './components/logout'
import NotFound from './pages/notFound';
import { Logs } from './components/logs';
import { Management } from './components/management/management';
import User from './pages/users/user';
import { FinishedChallenges } from './components/challenges/finishedChallenges';
import { AvailableChallenges } from './components/challenges/availableChallenges';
import { Challenge } from './components/challenges/challenge';
import { Blockchain } from './components/blockchain';
import LobbyHistory from './pages/lobby/history'
import GameTemplates from './pages/templates'
import Tournaments from './pages/tournaments'
import Status from './pages/status'
import Users from './pages/users'
import Profile from './pages/profile'
import {CreateTournament} from './pages/tournaments/create'
import {CreateTemplate} from './pages/templates/create'
import OnTime from './pages/ontime'
import {CreateOnTime} from './pages/ontime/create'
import Staking from './pages/staking/stake'
import HistoricStaking from './pages/staking';
import Info from './pages/info'

import Safe from './pages/safe'

export default function App() {
    const loggedIn = useSelector(state => state.userReducer.loggedIn);
    const user = useSelector(state => state.userReducer.userInfo);
    return (
        <Routes>
             <Route element={<RootLayout />}>
                <Route path="/login" element={<LayoutBasic />}>
                        <Route index element={<Login />} />
                </Route>
                
                <Route path="/logout" element={<LayoutBasic />}>
                        <Route index element={<Logout />} />
                </Route>

                <Route path='*' element={<MainContainer user={user} loggedIn={loggedIn} />} />
            </Route>
        </Routes>
    );
    
}

const MainContainer = ({user,loggedIn}) => (   

    <Routes>
        <Route path="/" element={<MainLayout/>} >
            <Route index element={ <Home /> } />            
            <Route path='/lobby/history/:challengeId' element={<RequireAdmin redirectTo="/"><LobbyHistory/> </RequireAdmin>}/>
            <Route path='/templates' element={<RequireAdmin redirectTo="/"><GameTemplates/> </RequireAdmin>}/>
            <Route path='/templates/create' element={<RequireAdmin redirectTo="/"><CreateTemplate/> </RequireAdmin>}/>
            <Route path='/tournaments' element={<RequireAdmin redirectTo="/"><Tournaments/> </RequireAdmin>}/>
            <Route path='/tournaments/create' element={<RequireAdmin redirectTo="/"><CreateTournament/> </RequireAdmin>}/>
            <Route path='/ontime' element={<RequireAdmin redirectTo="/"><OnTime/> </RequireAdmin>}/>
            <Route path='/ontime/create' element={<RequireAdmin redirectTo="/"><CreateOnTime/> </RequireAdmin>}/>
            <Route path='/status' element={<RequireAdmin redirectTo="/"><Status/> </RequireAdmin>}/>
            <Route path='/users' element={<RequireAdmin redirectTo="/"><Users/> </RequireAdmin>}/>
            <Route path='/users/:userId' element={<RequireAdmin redirectTo="/"><User/> </RequireAdmin>}/>
            <Route path='/profile' element={<RequireAdmin redirectTo="/"><Profile/></RequireAdmin>} />
            <Route path='/safe' element={<RequireAdmin redirectTo="/"><Safe/></RequireAdmin>} />
            <Route path='/staking' element={<RequireAdmin redirectTo="/"><HistoricStaking/></RequireAdmin>} />
            <Route path='/staking/create' element={<RequireAdmin redirectTo="/"><Staking/></RequireAdmin>} />
            <Route path='/info' element={<RequireAdmin redirectTo="/"><Info/></RequireAdmin>} />
            <Route path='*' element={<NotFound/>} />

            <Route element={<RequireAdmin redirectTo="/">  </RequireAdmin>}>
                <Route path='/logs' element={<Logs/>} />
                <Route path='/management' element={<Management/>} />
                <Route path='/challenges' element={<FinishedChallenges/>} />
                <Route path='/availablechallenges' element={<AvailableChallenges/>} />
                <Route path='/challenge/:challengeId' element={<Challenge/>} />
                <Route path='/blockchain' element={<Blockchain/>} />
              
                <Route path='*' element={<NotFound/>} />
            </Route>
        </Route>
    </Routes>


)

