import { TokenType } from 'src/helpers/types';

let user = JSON.parse(localStorage.getItem('user'));
let userInfoStorage = JSON.parse(localStorage.getItem('userInfo'));
userInfoStorage = userInfoStorage ? userInfoStorage : { 

}

let initialState = user ? {
    loggedIn: true,
    user,
    userInfo: {},
    userInfoFresh: false,
   
} :
{
    loggedIn: false,
    user:{},
    userInfo: {},
    userInfoFresh: false,
};

//TO handle ts checking
initialState = {
    ...initialState,
    userInfo:{
        ...userInfoStorage
    }
}

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case "IS_WORKING":
            return {
                ...state,
                working: true,
               
            }
        case "FINISHED_WORKING":
            return {
                ...state,
                working: false,

            }
        case "USER_LOGGED_IN":
            return {
                ...state,
                working: false,
                loggedIn: true,
                user: {
                    token: action.userData.token,
                    expires: action.userData.expires
                },
                type: 'alert-success',
                message: '',
                userInfo: action.userData.user,
                userInfoFresh: true,
              
            }
        case "LOGIN_FAILED":
            return {
                ...state,
                working: false,
                loggedIn: false,
                user: {},
                type: 'alert-danger',
                message: action.payload,
                userInfo: {},
                userInfoFresh: false,
            }
        case "USER_INFO":
            return {
                ...state, 
                userInfo: action.userInfo,
                userInfoFresh: true,
            }
        case "USER_BALANCE":
            return {
                ...state,
                userInfo: { 
                    ...state.userInfo, 
                    coins: {
                        ...state.userInfo.coins,
                        ...action.balance 
                    }
                },
            }
        case "USER_BALANCE_TOKEN":

            // determine which coin to update
            let coinToUpdate = null;
            if (action.tokenType === TokenType.WETH) {
                coinToUpdate = 'weth';
            } else if (action.tokenType === TokenType.CT) {
                coinToUpdate = 'challengeCoins';
            } else if (action.tokenType === TokenType.ETH) {
                coinToUpdate = 'eth';
            }

            if (coinToUpdate) {
                return {
                    ...state,
                    userInfo: { 
                        ...state.userInfo,
                        coins: {
                            ...state.userInfo.coins,
                            [coinToUpdate]: action.balance,
                        } 
                    },
                }
            }
            return state;
        case "PICTURE_INFO":
            return {
                ...state,
                userInfo: { ...state.userInfo, ...action.pictureInfo }

            }
        case "LOGOUT":
            return {
                ...state,
                loggedIn: false,
                userInfo: {},
                userInfoFresh: false,
            };
         default:
            return state
    }
}