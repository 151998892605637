import React from "react";
import Countries from 'react-select-country';

export const renderField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            <input {...input} placeholder={placeholder} type={type} />
            {touched &&
                ((error && <div className="errorMessage">{error}</div>) ||
                    (warning && <div className="errorMessage">{warning}</div>))}
        </div>
    )



export const renderFieldWithShake = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            <input {...input} className={touched && error ? 'error animated shake' : ''} placeholder={placeholder} type={type} />
            {touched &&
                ((warning && <div className="errorMessage">{warning}</div>))}
        </div>
    )


export const renderFieldCheckbox = ({
    id,
    input,
    label,
    disabled,
    placeholder,
    type,
    meta: { touched, error, warning, valid }
}) => (
        <div className="checkbox-input-container">
            <div onClick={() => document.getElementById(id).click()} className={input.value  ? "checkbox-active" : "checkbox"}></div>

            <span>{label}</span>
            <input {...input} type={type} id={id} disabled={disabled} />
            {touched &&
                ((error && error !== 'Required' && <label className="errorLabel">{error}</label>) ||
                    (warning && <label className="errorLabel">{warning}</label>))}
        </div>
    )
export const renderCountrySelectField = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
        <div>
            <label>{label}</label>
            <Countries  {...input} className={touched && error ? 'error animated shake' : ''} empty={placeholder}>
            </Countries >
            {touched &&
                ((error && <div className="errorMessage">{error}</div>) ||
                    (warning && <div classname="errorMessage">{warning}</div>))}
        </div>
    )
