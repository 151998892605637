import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ReduxRouter } from '@lagunovsky/redux-react-router'
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import configureStore, { history } from "./store/configureStore";
import App from './app'; 


const store = configureStore; 
window.store = store;
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ReduxRouter history={history}>
            <App />
        </ReduxRouter>
    </Provider>
);
unregister();
//registerServiceWorker();