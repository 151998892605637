import React from 'react'
import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material'
import typographySettings from 'src/themes/common/typography'

export type TypographyProps = MuiTypographyProps & {
  variant?: keyof typeof typographySettings.typography
}
export default function Typography({ children, ...restProps }: TypographyProps) {
  return <MuiTypography {...restProps}>{children}</MuiTypography>
}
