import React from 'react'
import { Container, ContainerProps } from '@mui/material'

type PageContainerProps = ContainerProps & {
  children: React.ReactNode
  innerContainerProps?: ContainerProps
}

export default function PageContainer({
  children,
  innerContainerProps,
  ...rootContainerProps
}: PageContainerProps) {
  return (
    <Container maxWidth="xl2" disableGutters {...rootContainerProps}>
      <Container maxWidth="lg2" disableGutters {...innerContainerProps}>
        {children}
      </Container>
    </Container>
  )
}
