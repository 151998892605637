import React, {useEffect} from 'react';
import { Box } from '@mui/material'
import { Outlet, useLocation } from "react-router-dom";
import { InfoBar } from './InfoBar';
import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'
import { TopBar } from '../components/topBar';
import { SideBar } from '../components/sideBar';
import { MobileMenu } from '../components/mobileMenu';
import { useAppSelector, useAppDispatch } from 'src/hooks'
import ctx from 'src/context/context'
import { WaitDialog } from 'src/components/WaitDialog/WaitDialog';
import { ROUTE_PATH } from 'src/routes';
import { config } from 'src/config/config';
import { getCurrentNetwork } from 'src/utils/ethersUtil';
import { hideChangeChainPanel, showChangeChainPanel } from 'src/actions/infoPanelActions';
import useChainChange from 'src/helpers/hooks/useChainChange';

export default function MainLayout() {

    const isLoggedIn = useAppSelector(state => state.userReducer.loggedIn);
    const user = useAppSelector(state => state.userReducer.userInfo);
    const auth = ctx.useAuth();
    const router = useLocation();
    const dispatch =  useAppDispatch();
    const path = router.pathname;
    const isStakingPage =  path.startsWith(ROUTE_PATH.staking); //This will not work without previous path
    const configuredChainId = isStakingPage ? config.blockchainStaking.chainId : config.blockchain.chainId;

    console.log('MainLayout: isStakingPage: ', isStakingPage);
    console.log('MainLayout: configuredChainId: ', configuredChainId);
    
    const chainId = useChainChange();
    console.log('MainLayout: chainId: ', bigintToHex(chainId));
    
    useEffect(() => {
        auth.setWalletConnected(isLoggedIn);
        auth.setUser(user);
    }, [isLoggedIn,user]);

    const compareStringsIgnoreCase = (str1: string, str2: string): boolean => {
        return str1.toLowerCase() === str2.toLowerCase();
    };
    function bigintToHex(bigint: bigint|null): string {
        if(bigint === null)
            return '0x0';
        return '0x' + bigint.toString(16);
    }

    useEffect(() => {
        const checkChain = async () => {
            try{
             if(chainId === undefined || chainId === null)
                return;
             
             console.log('MainLayout: network: ', chainId);
             if(!compareStringsIgnoreCase(bigintToHex(chainId),configuredChainId)){
                console.log('MainLayout: network.chainId !== configuredChainId: ', chainId, configuredChainId   );
                dispatch(showChangeChainPanel(configuredChainId,isStakingPage ? config.blockchainStaking.slug : config.blockchain.chainDisplayName ,`Please switch to ${isStakingPage ? config.blockchainStaking.chainDisplayName : config.blockchain.chainDisplayName}`));
             }
             else{
                dispatch(hideChangeChainPanel());
             }
                
            }catch (error) {
                console.error('Error setting signer: ', error);
                return;
            }
        };
        checkChain();    
    }, [configuredChainId,chainId]);
    
    return (
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            position: 'relative',
            }}
        >
            <Header isWalletConnected={auth.isWalletConnected} user={user} />
			<InfoBar />     
            
            
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Box flexGrow={1} px={{ xs: '16px', sm: '28px', lg4: '0' }}>
                    <Outlet />
                </Box>
            </Box>
            <Footer />
            <WaitDialog/>
        </Box>
    );
    

}

