import React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Stack,
  Box,
  AvatarProps as MuiAvatarProps,
  StackProps as MuiStackProps,
} from '@mui/material'
import { PNG_ICONS } from 'src/assets/icons/icons'
import ImageBox, { ImageBoxProps } from 'src/components/ImageBox/ImageBox'
import Typography, { TypographyProps } from 'src/components/Typography/Typography'

export type TotalTokensProps = {
  /** [The root element of this component uses the StackProps from Material UI](https://mui.com/material-ui/react-stack/) */
  StackProps?: MuiStackProps
  /**
   * [Props applied to the ImageBox element.](/?path=/docs/components-imagebox--size-small)
   * */
  ImageBoxProps?: ImageBoxProps
  total: string
  label?: string
  icon?: string
  /**
   * [Props applied to the Avatar element.](https://mui.com/material-ui/react-avatar/)
   * */
  AvatarProps?: MuiAvatarProps
  totalTypographyProps?: TypographyProps
  labelTypographyProps?: TypographyProps
}

export default function TotalTokens({
  StackProps,
  // eslint-disable-next-line
  ImageBoxProps,
  total,
  label = 'Total tokens',
  icon = PNG_ICONS.weth,
  AvatarProps,
  totalTypographyProps,
  labelTypographyProps,
}: TotalTokensProps) {
  const { alt = 'Coin', sx, ...restAvatarProps } = AvatarProps || {}

  const theme = useTheme()

  return (
    <Stack direction="row" spacing="12px" alignItems="center" {...StackProps}>
      <ImageBox
        width="48px"
        height="48px"
        sx={{
          [theme.breakpoints.down('sm')]: { width: '44px', height: '44px' },
          ...ImageBoxProps?.sx,
        }}
        {...ImageBoxProps}
      >
        <Avatar
          src={icon}
          alt={alt}
          sx={{
            width: '18.58px',
            height: '18.58px',
            [theme.breakpoints.down('sm')]: {
              width: '18px',
              height: '18px',
            },
            ...sx,
          }}
          {...restAvatarProps}
        />
      </ImageBox>
      <Box>
        <Typography variant="h7" color="gray.0" mb="4px" {...totalTypographyProps}>
          {total}
        </Typography>
        <Typography variant="paragraphSmall" color="gray.500" {...labelTypographyProps}>
          {label}
        </Typography>
      </Box>
    </Stack>
  )
}
