export function toBigInt(n: any): bigint {
  return BigInt(n);
}

export function subtract(a: bigint, b: bigint): bigint {
    a = BigInt(a);
    b = BigInt(b);
    return a - b;

}

export function lessThan(a: bigint, b: bigint): boolean {
    a = BigInt(a);
    b = BigInt(b);
    return a < b;
}