import { takeEvery, call, put, delay, select, fork, takeLatest, all  } from "redux-saga/effects";
import { push } from '@lagunovsky/redux-react-router'
import { config  } from "../config/config.ts";
import { authHeader } from "../utils/authHeader";
import { openAlert, openSuccessAlert, openErrorAlert, closeAlert, errorGradient, successGradient } from "../actions/dialogActions";
import { getBlockchain, changeNetwork, watchChallengeAsset } from "../utils/ethersUtil";
import { showErrorToast, showInfoToast, showSuccessToast } from "../components/Toast/Toasts";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { TokenType } from "src/helpers/types";
import { formatTokenToString } from "src/utils/tokenUtil";

export default function* apiSagas() {

    yield takeEvery("LOGIN_USER_WALLET", loginWalletWorker);
    yield takeEvery("LOGIN_USER", loginWorker);
    yield takeEvery("LOGOUT_USER", logoutWorker);

    yield takeEvery("GET_LOGS", getLogsWorker);
    
    yield takeEvery("GET_GAME_TEMPLATES", getGameTemplatesWorker);

    yield takeEvery("GET_RUNNING_CHALLENGES", getRunningChallengesWorker);
    yield takeEvery("GET_FINISHED_CHALLENGES", getFinishedChallengesWorker);
    yield takeEvery("GET_FINISHED_CHALLENGE", getFinishedChallengeWorker);
    yield takeEvery("GET_AVAILABLE_CHALLENGES", getAvailableChallengesWorker);
    yield takeEvery("GET_CHALLENGE", getChallengeWorker);
    yield takeEvery("UPDATE_CHALLENGE", updateChallengeWorker);
    yield takeEvery("REFUND_CHALLENGE_FEE", refundChallengeFeeWorker);
    yield takeEvery("CANCEL_CHALLENGE_JOB", cancelChallengeWorker);
    yield takeEvery("GET_GENERAL_STATISTICS", getGeneralStatisticsWorker);
    yield takeEvery("GET_USERS", getUsersWorker);
    yield takeEvery("FILTER_USERS", getFilteredUsersWorker);
    yield takeEvery("GET_USER", getUserWorker);
    yield takeEvery("GET_MATCHES_PLAYED", getUserMatchesPlayedWorker);
    yield takeEvery("GIVE_USER_PROMOTION", givePromotionWorker);
    yield takeEvery("UPDATE_USER", updateUserWorker);
    yield takeEvery("GET_SERVICE_STATUS", getServiceStatusWorker);
    yield takeEvery("SET_SERVICE_TEXT", setServiceTextWorker);
    yield takeEvery("SET_SERVICE_STATUS", setServiceStatusWorker);

    yield takeEvery("GET_PAYMENTS", getPaymentsWorker);
    yield takeEvery("GET_WITHDRAWALS", getWithdrawalsWorker);
    yield takeEvery("UPDATE_WITHDRAWAL", updateWithdrawalWorker);

    yield takeEvery("CREATE_CASH_DISCOUNT_CODE", createDiscountCodeWorker);
    yield takeEvery("GET_DISCOUNT_CODES", getDiscountCodesWorker);
    yield takeEvery("UPDATE_DISCOUNT_CODE", updateDiscountCodeWorker);
    yield takeEvery("DELETE_DISCOUNT_CODE", deleteDiscountCodeWorker);


    yield takeEvery("ADD_CHALLENGE_COIN", addChallengeCoinWorker);
    yield takeEvery("OWNER_BALANCE", ownerBalanceWorker);
    yield takeEvery("WITHDRAW_TOKEN", withdrawTokenWorker);

    yield takeEvery("SHOW_ERROR_POPUP", showErrorPopupWorker);
    yield takeEvery("SHOW_SUCCESS_POPUP", showSuccessPopupWorker);

    yield takeEvery("UPDATE_BALANCE", updateBalanceWorker);
}

function* loginWalletWorker(action) {
 
    let account;
    let web3;
    let signerObj;

    const { from } = action //Not used yet!

    try {
       /* web3 = yield call(getWeb3);
        console.log("web3:" + web3);
        const accounts = yield call(web3.eth.getAccounts);*/

             
        yield changeNetwork(config.blockchain.chainId,config.blockchain.slug); //Check and change network if needed

       // yield watchChallengeAsset(); //Add CC coin if needed

        const { provider, signer, wallet, challengeCoin } = yield call(getBlockchain)
        signerObj = signer;
        const accounts = yield provider.listAccounts();

        console.log("accounts:" + JSON.stringify(accounts));
        if (accounts.length !== 0) {
            account = accounts[0].address;

            const getNonce = yield call(loginWallet, account);
            /*  const signature = yield call(web3.eth.personal.sign, `I am signing my one-time nonce: ${getNonce.nonce}`, account, '' // MetaMask will ignore the password argument here
              );*/


            let signature = yield signer.signMessage(`Please login to challenge.gg\nby signing this nonce:\n\n${getNonce.nonce}`);

            //remove user from localstorage
            yield call(clearWorker);
           
            const [reply] = yield all([call(verifyWalletSignature, account, signature)]);
           
            yield put({ type: "USER_LOGGED_IN", userData: reply });
 
           
            yield put(push('/', { refresh: true }))                     
            
        }
        else {
            showErrorToast('Connect error', 'No accounts visible');
            console.log("No accounts visible");
        }

    }
    catch (e) {       
        console.log("Login failed," + e);            
        showErrorToast('Connect error',  parseMetamaskError(e));                                
        yield put({ type: "LOGIN_FAILED" });
        
    }
}

function parseMetamaskError(e) {

    const parsedEthersError = getParsedEthersError(e);
    console.log("parsed error:"+ JSON.stringify(parsedEthersError));
    if(parsedEthersError!==undefined){
        switch(parsedEthersError.errorCode){
            case 'REJECTED_TRANSACTION':
                return 'User rejected transaction';
            case 'TRANSACTION_UNDERPRICED':
                return 'Out of gas';
        }
    }

    //Metamask error code
    if(e.info?.error?.code && e.info.error.code === 4001)
        return 'User rejected the request';
    else if(e.error?.code && e.error.code === -32002)
        return 'Resource is currently unavailable'

    var txt = e.reason !== undefined  &&  e.reason !== null? 
        e.reason 
        : 
        ((e.data !== undefined && e.data !== null && e.data.message !== undefined) ? 
            e.data.message 
            : 
            ((e.info?.error?.data?.message !== undefined ) ? 
                e.info.error.data.message 
                : 
                e.message));

    if (txt?.includes("VM Exception while processing transaction: revert ")) {
        txt = txt.substring(50);
    }
    else if (txt?.includes("execution reverted: ")) {
        txt = txt.substring(20);
    }
    return txt ? txt: e;
}

function* addChallengeCoinWorker() {
    yield watchChallengeAsset();
}

function* updateBalanceWorker() {
    const account = yield select(state => state.userReducer.userInfo.username);
    const balance = yield call(getUserCoinBalanceWorker,account,config.blockchainStaking.chainId);
    yield put({ type: "USER_BALANCE", balance });
}

function* ownerBalanceWorker() {
    const { provider, signer, gameServer, challengeCoin } = yield call(getBlockchain);

    const accounts = yield provider.listAccounts();
    const walletTokenBalance = yield challengeCoin.balanceOf(accounts[0]);
    yield put({ type: "OWNER_BALANCE_RESULT", ownerBalance: walletTokenBalance });
}

function* withdrawTokenWorker(action) {

    try {
        const { provider, signer, gameServer, challengeCoin } = yield call(getBlockchain);
        const { amount } = action;
        const accounts = yield provider.listAccounts();

        const tx1 = yield challengeCoin.withdrawToken(amount.toString()); //,options
        const tx11 = yield tx1.wait();
        yield put(openSuccessAlert({ title: 'Withdraw successful of ' + formatTokenToString(amount,TokenType.CT) }));

    }
    catch (e) {
     
        //error from backend coomes in e.message, from metamask e.data.message
        yield put(openErrorAlert({ title: e.data !== undefined ? e.data.message : e.message }));
    }

}



function* loginWorker(action) {
    console.log("login worker");

    try {
        yield put({ type: "IS_WORKING" });
        const { username, password } = action;
        const user = yield call(login, username, password);
        yield put({ type: "USER_LOGGED_IN", user });
        yield put(push('/', { refresh: true }))
    }
    catch (e) {
        console.log("Login failed," + e)
        yield put(openErrorAlert({ title: e.message}));
        yield put({ type: "LOGIN_FAILED", payload: e });
    }
}
function* getUsersWorker(action) {
    try {

        const users = yield call(getUsers);
        yield put({ type: "USERS", users: users });
    }
    catch (e) {
        console.log("Users failed," + e)
        yield put({ type: "GET_USERS_FAILED", payload: e });
    }
}

function* getFilteredUsersWorker(action) {
    try {
        const { filter, page, pageSize } = action;
        yield put({ type: "SET_FILTER", filter: filter });
        const users = yield call(getPaginatedFilteredUsers, filter,page,pageSize);
        yield put({ type: "USERS", users: users });
    }
    catch (e) {
        console.log("Users failed," + e)
        yield put({ type: "GET_USERS_FAILED", payload: e });
    }
}

function* getUserWorker(action) {
    try {
        const { id } = action;
        const user = yield call(getUser,id);
        const coins = yield call(getUserCoinBalanceWorker,user.username,config.blockchain.chainId);

        user.coins = coins;


        yield put({ type: "USER", user: user });
    }
    catch (e) {
        console.log("User failed," + e)
        yield put({ type: "GET_USER_FAILED", payload: e });
    }
}

function* refundChallengeFeeWorker(action) {
    try {
        const { challengeId,userId } = action;
        const user = yield call(refundChallengeFee, challengeId,userId);
        //yield put({ type: "USER", user: user });
        put({ type: "SHOW_WAIT_DIALOG",  title:"Success",  subtitle: "Fee refunded!", status: 'success', timeout: 1000 }); 
    }
    catch (e) {
        yield put({ type: "SHOW_WAIT_DIALOG",  title:"Error",  subtitle: e.message, status: 'error', timeout: 0 }); 
        console.log("efund failed," + e)
        yield put({ type: "REFUND_FAILED", payload: e });
    }
}

function* getUserCoinBalanceWorker(account) {
    
    try {
        console.log("Account="+account);
        const {weth, challengeCoin } = yield call(getBlockchain)
        const [walletWETHBalance, walletChallengeTokenBalance] = yield all([
            call(weth.balanceOf, account),
            call(challengeCoin.balanceOf, account),
          

        ]);
        const ethBalance=0;
        console.log("wallet WETH balance(weth):" + walletWETHBalance);
        console.log("wallet Challenge Coin balance(CC):" + walletChallengeTokenBalance);
        var coins = {weth: walletWETHBalance+"", challengeCoins: walletChallengeTokenBalance+"", eth: ethBalance+""}; //Need to be string since JSON.stringify do not handle BigInt
         return coins;
    }
    catch (e) {
        console.log("getUserCoinBalanceWorker failed," + e)
        showErrorToast('Incorrect network', e.message);
        return "-";
       
    }
}

export function* internalUpdateBalanceWorker(newBalance, tokenType){
    yield call(updateLocalStoreUserToken,newBalance+"",tokenType); //Need to be string since JSON.stringify do not handle BigInt
    yield put({ type: "USER_BALANCE_TOKEN", balance: newBalance.toString(), tokenType: tokenType });
}

function updateLocalStoreUserToken(token, tokenType) {
    var existing = localStorage.getItem('userInfo');

    switch (tokenType) {
        case TokenType.WETH:            
            existing = existing ? JSON.parse(existing) : {coins: {weth:"0"}};
            existing.coins ? existing.coins.weth = token : existing.coins = {weth: token};
        break;
        case TokenType.CT:
            existing = existing ? JSON.parse(existing) : {coins: {challengeCoins:"0"}};
            existing.coins ? existing.coins.challengeCoins = token : existing.coins = {challengeCoins: token};
        break;
        case TokenType.ETH:
            existing = existing ? JSON.parse(existing) : {coins: {eth:"0"}};
            existing.coins ? existing.coins.eth = token : existing.coins = {eth: token};
        default:
    }

    
    // Save back to localStorage
    console.log("updateLocalStoreUserToken:" + JSON.stringify(existing));
    localStorage.setItem('userInfo', JSON.stringify(existing));
}

function* getETHBalanceInternal(account) {
    const { provider } = yield call(getBlockchain)  
    const balanceWei = yield provider.getBalance(account);  
    return balanceWei;
}

function* getUserMatchesPlayedWorker(action) {
    try {
        const { id } = action;
        const matches = yield call(getUserMatchesPlayed,id);
        yield put({ type: "USER_MATCHES_PLAYED", matches: matches });
    }
    catch (e) {
        console.log("Matches played failed," + e)
        yield put({ type: "USER_MATCHES_PLAYED_FAILED", payload: e });
    }
}

function* updateUserWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { values } = action;
        const user = yield call(updateUser, values);
        yield put({ type: "UPDATE_USER", user: user });
        yield put({ type: "FINISHED_WORKING" });
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("User failed," + e)
        yield put({ type: "UPDATE_USER_FAILED", payload: e });
    }
}

function* givePromotionWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { id,amount } = action;
        const promotion = yield call(givePromotion, id, amount);
        yield put({ type: "PROMOTION", promotion: promotion });
        yield put({ type: "FINISHED_WORKING" });
        yield put(openSuccessAlert({ title: 'User was given $' +amount+ ". New amount is " + promotion.newAmount }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("Promotion failed," + e)
        yield put({ type: "PROMOTION_FAILED", payload: e });
    }
}

function* createDiscountCodeWorker(action) {
    try {
        yield put({ type: "IS_WORKING" });
        const { amount,description } = action;
        const discountCode = yield call(createDiscountCode, amount, description );
        yield put({ type: "DISCOUNT_CODE", discountCode: discountCode });
        yield put({ type: "FINISHED_WORKING" });
        yield put(openSuccessAlert({ title: 'Discount code "' + discountCode.code + '" created. Amount is $' + amount }));
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("Promotion failed," + e)
        yield put({ type: "DISCOUNT_CODE_FAILED", payload: e });
    }
}

function* getGeneralStatisticsWorker(action) {
    try {

        const general = yield call(getGeneralStatistics);
        yield put({ type: "GENERAL", general: general });
    }
    catch (e) {
        console.log("General failed," + e)
        yield put({ type: "GENERAL_FAILED", payload: e });
    }
}

function* getServiceStatusWorker(action) {
    try {

        const serviceStatus = yield call(getServiceStatus);
        yield put({ type: "SERVICE_STATUS", serviceStatus: serviceStatus });
    }
    catch (e) {
        console.log("service status failed," + e)
        yield put({ type: "SERVICE_STATUS_FAILED", payload: e });
    }
}


function* setServiceTextWorker(action) {

        const { text } = action;
        yield put({ type: "SERVICE_TEXT", text: text });
 }

function* setServiceStatusWorker(action){

    try {
        yield put({ type: "IS_WORKING"});
        const serviceStatus = yield call(setServiceStatus, action.status)
        yield put({ type: "SERVICE_STATUS", serviceStatus: serviceStatus });
        yield put({ type: "FINISHED_WORKING" });
    }
    catch (e) {
        yield put({ type: "FINISHED_WORKING" });
        console.log("Set service status failed," + e)
        yield put({ type: "SET_SERVICE_STATUS_FAILED", payload: e });
    }
}

function* getLogsWorker(action) {
    try {

        const logs = yield call(getLogs);
        yield put({ type: "LOGS", logs: logs });
    }
    catch (e) {
        console.log("logs failed," + e)
        yield put({ type: "LOGS_FAILED", payload: e });
    }
}

function* getPaymentsWorker(action) {
    try {

        const payments = yield call(getPayments);
        yield put({ type: "PAYMENTS", payments: payments });
    }
    catch (e) {
        console.log("payments failed," + e)
        yield put({ type: "PAYMENTS_FAILED", payload: e });
    }
}

function* getWithdrawalsWorker(action) {
    try {

        const withdrawals = yield call(getWithdrawals);
        yield put({ type: "WITHDRAWALS", withdrawals: withdrawals });
    }
    catch (e) {
        console.log("withdrawals failed," + e)
        yield put({ type: "WITHDRAWALS_FAILED", payload: e });
    }
}

function* updateWithdrawalWorker(action) {
    try {

        const withdrawal = yield call(updateWithdrawal, action.id, action.status, action.reason);
        yield put({ type: "UPDATED_WITHDRAWAL", withdrawal: withdrawal });
        yield put({ type: "CLEAR_ERROR" });
    }
    catch (e) {
        console.log("update withdrawal failed," + e)
        yield put({ type: "UPDATE_WITHDRAWAL_FAILED", payload: e });
        yield put({ type: "SET_ERROR", errorMessage: e.message });

    }
}

function* getDiscountCodesWorker(action) {
    try {

        const discountCodes = yield call(getDiscountCodes);
        yield put({ type: "DISCOUNT_CODES", discountCodes: discountCodes });
    }
    catch (e) {
        console.log("discountCodes failed," + e)
        yield put({ type: "DISCOUNT_CODES_FAILED", payload: e });
    }
}

function* updateDiscountCodeWorker(action) {
    try {

        const updatedDiscountCode = yield call(updateDiscountCode, action.id, action.status, action.amount, action.description);
        yield put({ type: "UPDATED_DISCOUNT_CODE", updatedDiscountCode: updatedDiscountCode });
        yield put({ type: "CLEAR_ERROR" });
    }
    catch (e) {
        console.log("update discount code failed," + e)
        yield put({ type: "UPDATED_DISCOUNT_CODE_FAILED", payload: e });
        yield put({ type: "SET_ERROR", errorMessage: e.message });

    }
}

function* deleteDiscountCodeWorker(action) {
    try {

        const deletedDiscountCode = yield call(deleteDiscountCode, action.id);
        yield put({ type: "DELETED_DISCOUNT_CODE", deletedDiscountCode: deletedDiscountCode });
        yield put({ type: "CLEAR_ERROR" });
    }
    catch (e) {
        console.log("delted discount code failed," + e)
        yield put({ type: "DELETED_DISCOUNT_CODE_FAILED", payload: e });
        yield put({ type: "SET_ERROR", errorMessage: e.message });

    }
}

function* getGameTemplatesWorker(action) {
    try {

        const gameTemplates = yield call(getGameTemplates);

        yield put({ type: "GAME_TEMPLATES", gameTemplates: gameTemplates });
    }
    catch (e) {
        console.log("gameTemplates failed," + e)
        yield put({ type: "GAME_TEMPLATES_FAILED", payload: e });
        yield put({ type: "SHOW_WAIT_DIALOG",  title:'Failed fetching',  subtitle: e.message, status: 'error', timeout: 2000 }); 
    }
}

function* getRunningChallengesWorker(action) {
    try {

        const runningChallenges = yield call(getRunningChallenges);
        yield put({ type: "RUNNING_CHALLENGES", runningChallenges: runningChallenges });
    }
    catch (e) {
        console.log("runningChallenges failed," + e)
        yield put({ type: "RUNNING_CHALLENGES_FAILED", payload: e });
    }
}

function* getFinishedChallengesWorker(action) {
    try {

        const finishedChallenges = yield call(getFinishedChallenges);
        yield put({ type: "FINISHED_CHALLENGES", finishedChallenges: finishedChallenges });
    }
    catch (e) {
        console.log("finishedChallenges failed," + e)
        yield put({ type: "FINISHED_CHALLENGES_FAILED", payload: e });
    }
}

function* getFinishedChallengeWorker(action) {
    try {
        yield put({ type: "CLEAR_CHALLENGE_HISTORY"});
        const userId = yield select(state => state.userReducer.userInfo.id);
        const challengeId = action.id;
        const challenge = yield call(getChallenge, challengeId, true);
        yield put({ type: "CHALLENGE_HISTORY", challenge: challenge, userId: userId });
    }
    catch (e) {
        console.log("Failed getting finished challenge," + e)
        showErrorToast('Error getting challenges',e.message);
    }
}

function* getAvailableChallengesWorker(action) {
    try {

        const availableChallenges = yield call(getAvailableChallenges);
        yield put({ type: "AVAILABLE_CHALLENGES", availableChallenges: availableChallenges });
    }
    catch (e) {
        console.log("availableChallenges failed," + e)
        yield put({ type: "AVAILABLE_CHALLENGES_FAILED", payload: e });
    }
}

function* getChallengeWorker(action) {
    try {

        const challenge = yield call(getChallenge,action.id);
        yield put({ type: "CHALLENGE", challenge: challenge });
    }
    catch (e) {
        console.log("get challenge failed," + e)
        yield put({ type: "CHALLENGE_FAILED", payload: e });
    }
}

function* updateChallengeWorker(action) {
    try {

        const challenge = yield call(updateChallenge, action.id, action.fee, action.maxParticipans, action.disabled);
        yield call(getAvailableChallengesWorker);
        yield put({ type: "CLEAR_ERROR" });
    }
    catch (e) {
        console.log("update challenge failed," + e)
        yield put({ type: "UPDATE_CHALLENGE_FAILED", payload: e });
        yield put({ type: "SET_ERROR", errorMessage: e.message });

    }
}

function* cancelChallengeWorker(action) {
    try {

        const challenge = yield call(cancelChallenge, action.id);
        yield put({ type: "CHALLENGE_CANCELED" });
    }
    catch (e) {
        console.log("cancelChallengeWorker failed," + e)
        yield put({ type: "CANCEL_CHALLENGE_FAILED", payload: e });
    }
}


function* logoutWorker(action) {
    yield call(clearWorker);
    yield put(push('/'));
   
}

function* clearWorker(action) {
    yield call(logout);
    yield put({ type: "UNSUBSCRIBE_CHALLENGES" });
    yield put({ type: "LOGOUT" });
}

function* showErrorPopupWorker(action){
    yield put({ type: "SHOW_WAIT_DIALOG",  title:action.title,  subtitle: action.subtitle, status: 'error', timeout: 0 }); 
}
function* showSuccessPopupWorker(action){
    yield put({ type: "SHOW_WAIT_DIALOG",  title:action.title,  subtitle: action.subtitle, status: 'success', timeout: 1000 }); 
}
/* Return public address and nonce */
function loginWallet(account) {
    // console.log("Username=" + username);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.url.BASE_URL}/api/account/publicaddress/${account}`, requestOptions)
        .catch (handleError)
        .then(handleResponse);       
}


function verifyWalletSignature(account, signature) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ publicAddress: account, signature })
    };
    return fetch(`${config.url.BASE_URL}/api/account/verifysignature`, requestOptions)
        .catch(handleError)
        .then(handleResponse)
        .then(reply => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('userInfo', JSON.stringify(reply.user));
            localStorage.setItem('user', JSON.stringify({ token: reply.token, expires: reply.expires }));
            return reply;
        });

}


function getGeneralStatistics() {

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/statistics/general`, requestOptions).then(handleResponse);
}

function getUsers() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/users`, requestOptions).then(handleResponse);
}

function getFilteredUsers(filter){
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/users?filter=${filter}`, requestOptions).then(handleResponse);
}

function getPaginatedFilteredUsers(filter, page, pageSize){
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };

    if(filter !== undefined)
        return fetch(`${config.url.BASE_URL}/api/management/users/paginated?filter=${filter}&page=${page}&pageSize=${pageSize}`, requestOptions).then(handleResponse);
    else
        return fetch(`${config.url.BASE_URL}/api/management/users/paginated?page=${page}&pageSize=${pageSize}`, requestOptions).then(handleResponse);
}

function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/users/${id}`, requestOptions).then(handleResponse);
}

function getUserMatchesPlayed(id) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/users/${id}/matchesplayed`, requestOptions).then(handleResponse);
}

function refundChallengeFee(challengeId, userId) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges/${challengeId}/refund/${userId}`, requestOptions).then(handleResponse);
}

function updateUser(values) {
    console.log("Val=" + JSON.stringify(values));
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify(values)
    };
    return fetch(`${config.url.BASE_URL}/api/management/users/${values.id}`, requestOptions).then(handleResponse);
}

function updateChallenge(id, fee, maxParticipans, disabled) {
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ fee, maxParticipans, disabled })
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges/${id}`, requestOptions).then(handleResponse);
}

function givePromotion(id, amount) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount })
    };
    return fetch(`${config.url.BASE_URL}/api/management/users/${id}/promotion`, requestOptions).then(handleResponse);
}

function createDiscountCode(amount, description) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ amount, description })
    };
    return fetch(`${config.url.BASE_URL}/api/discounts/cash/issue`, requestOptions).then(handleResponse);
}

function getPayments() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/payments`, requestOptions).then(handleResponse);
}

function getWithdrawals() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/withdrawals`, requestOptions).then(handleResponse);
}

function updateWithdrawal(id, status, reason) {
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ status, reason })
    };
    return fetch(`${config.url.BASE_URL}/api/management/withdrawals/${id}`, requestOptions).then(handleResponse);
}

function getDiscountCodes() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/discounts`, requestOptions).then(handleResponse);
}

function updateDiscountCode(id, status, amount, description) {
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify({ status, amount, description })
    };
    return fetch(`${config.url.BASE_URL}/api/discounts/${id}`, requestOptions).then(handleResponse);
}

function deleteDiscountCode(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/discounts/${id}`, requestOptions).then(handleResponse);
}

function getLogs() {

    const ascending = false;

    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/logs/event?ascending=${ascending}`, requestOptions).then(handleResponse);
}

function getServiceStatus() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/status`, requestOptions).then(handleResponse);
}

function setServiceStatus(status) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify(status)
    };
    return fetch(`${config.url.BASE_URL}/api/management/status`, requestOptions).then(handleResponse);
}

function getGameTemplates() {    
        const requestOptions = {
            method: 'GET',
            headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        };
        return fetch(`${config.url.BASE_URL}/api/management/templates`, requestOptions)
        .catch(handleError)
        .then(handleResponse);
}

export function updateGameTemplate(gameTemplate) {    
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify(gameTemplate)
    };
    return fetch(`${config.url.BASE_URL}/api/management/templates`, requestOptions)
            .catch(handleError)
            .then(handleResponse);
}

export function saveGameTemplate(gameTemplate) {
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify(gameTemplate)
    };
    return fetch(`${config.url.BASE_URL}/api/management/templates`, requestOptions)
            .catch(handleError)
            .then(handleResponse);
}

export function deleteGameTemplate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/templates/${id}`, requestOptions)
            .catch(handleError)
            .then(handleResponse);
}

export function saveTournament(tournament) {
    console.log("Save tournament=" + JSON.stringify(tournament));
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify(tournament)
    };
    return fetch(`${config.url.BASE_URL}/api/management/tournaments`, requestOptions)
        .catch(handleError)
        .then(handleResponse);
        
}

export function saveChallenge(challenge) {
    console.log("Save challenge=" + JSON.stringify(challenge));
    const requestOptions = {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
        body: JSON.stringify(challenge)
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges`, requestOptions)
        .catch(handleError)
        .then(handleResponse);
        
}

function getRunningChallenges() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges/running`, requestOptions).then(handleResponse);
}

function getFinishedChallenges() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges/finished`, requestOptions).then(handleResponse);
}


function getAvailableChallenges() {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges/available`, requestOptions).then(handleResponse);
}

function getChallenge(id,sortLogEvents) {
    const requestOptions = {
        method: 'GET',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/challenges/${id}?sortLogEvents=${sortLogEvents}`, requestOptions).then(handleResponse);
}

function cancelChallenge(jobId) {
    const requestOptions = {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, authHeader()),
    };
    return fetch(`${config.url.BASE_URL}/api/management/challengejobs/${jobId}/cancel`, requestOptions).then(handleResponse);
}

function login(username, password) {
    // console.log("Username=" + username);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(`${config.url.BASE_URL}/api/account/authenticate`, requestOptions)
        .then(handleResponse)
        .then(console.log('Logging in: ', requestOptions))
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}


function errorCallout(error) {
    alert(error.message);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('userInfo');
}

function displayError(errorCode) {
    console.log('There was an error: ', errorCode)
}






function handleResponse(response) {

    //console.log("Response=" + response);
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
                //location.reload isn't used when verifying credentials during login, it's for after the user is logged in if their authentication token becomes invalid for any reason 
                //which causes a 401 response to be returned by the api.If a 401 is returned logout() is called which removes the user from local storage, 
                //then location.reload(true) is called which refreshes the app to redirect the user back to the login page.
            }           
            else if (response.status === 503) {
                //Service unavailable, will be returned in case of maintenance
                const error = { errorCode: 503, message: 'Service is undergoing maintenance' };
                return Promise.reject(error);
            }

            const data = text && JSON.parse(text);
            if (typeof data !== 'undefined' && typeof data.message !== 'undefined') {
                return Promise.reject(data);
            }
            else if (typeof data !== 'undefined' && typeof data.title !== 'undefined') {
                const error = { errorCode: -1, message: typeof data.detail !== 'undefined' ? data.detail: data.title};
                return Promise.reject(error);
            }
            else {
                const error = { errorCode: -1, message: response.statusText };
                return Promise.reject(error);
            }
        }
        const data = text && JSON.parse(text);
        //console.log("data=" + JSON.stringify(data));
        //console.log("statusText=" + response.statusText);
        return data;
    });
}

function handleError(error) {
    if (error.message === 'Failed to fetch') {
        const msg = { errorCode: -1, message: 'Unable to connect to backend, please check you internet connection' };
        throw msg;
    }

    throw error;

}
