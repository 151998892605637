import React from 'react'
import { Box, Grid } from '@mui/material'
import WalletItem from 'src/components/WalletItem/WalletItem'
import { useTheme } from '@mui/material/styles'
import { PNG_ICONS } from 'src/assets/icons/icons'
import { TokenType, UserType } from 'src/helpers/types'

type YourWalletProps = {
    stats: any
}

const YourWallet = ({stats}: YourWalletProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.gray[700]}`,
        padding: '17px',
        width: '1000px',
        '&:hover': {
          borderColor: theme.palette.gray[500],
        },
        [theme.breakpoints.down('md2')]: {
          width: '100%',
          padding: '11px',
        },
      }}
    >
      <Grid container spacing={{ xs: '0', md2: '44px' }}>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '53%',
            },
          }}
        >
          <WalletItem
            iconData={{ src: PNG_ICONS.graph, sx: { width: '24px', height: '24px' } }}
            title={'Total users'}
            total={stats?.users?.userCount}
            tokenType={undefined}
          />
        </Grid>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '47%',
            },
          }}
        >
          <WalletItem
            iconData={{ src: PNG_ICONS.graph, sx: { width: '20px', height: '20px' } }}
            title={'Has Epic account'}
            total={stats?.users?.epicVerifiedUserCount}
            tokenType={undefined}
          />
        </Grid>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '47%',
            },
          }}
        >
          <WalletItem
            iconData={{ src: PNG_ICONS.graph, sx: { width: '20px', height: '20px' } }}
            title={'Running challenges'}
            total={stats?.challenges?.runningChallengeCount}
            tokenType={undefined}
          />
        </Grid>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('md2')]: {
              width: '47%',
            },
          }}
        >
          <WalletItem
            iconData={{ src: PNG_ICONS.graph, sx: { width: '20px', height: '20px' } }}
            title={'Finished challenges'}
            total={stats?.challenges?.finishedChallengeCount}
            tokenType={undefined}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default YourWallet
