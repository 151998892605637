import { Stack, useTheme } from '@mui/material';
import React, { ChangeEvent, useState } from 'react'
import TextField from 'src/components/TextField/TextField'
import {useDebounce} from 'src/helpers/hooks/useDebounce'

type FilterProps = {
    onFilterChange: (filter: string ) => void
}

export function Filter({ onFilterChange }: FilterProps) {
    const theme = useTheme();
    const [filter, setFilter] = useState('');

    function filterChangeHandler(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setFilter(event.target.value);
        debouncedRequest();
    }

    const debouncedRequest = useDebounce(() => {
        // send request to the backend
        // access to latest state here
        onFilterChange(filter);
      },1500);



    return (
        <Stack
            direction="column"
            gap="2px"
            sx={{
                background: theme.palette.gray[800],
                padding: { xs: '18px 10px 18px', sm: '25px 23px 35px' },
                marginBottom: '12px',
            }}
        >
            <TextField id="search" name="filter" placeholder="Enter filter text, search for address or nick" label="Filter" size="large"
                value={filter}
                onChange={filterChangeHandler}

            />
        </Stack>
    )
}


