import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import { login, clear } from "../actions/userActions";
import { required, email, alphaNumeric } from "./validations";
import { renderFieldWithShake } from '../utils/renderFields';

class Login extends Component {

    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        // calling the new action creator
        this.props.localClear();

    }
       

    handleFormSubmit(values) {
        if (this.props.valid) {
            this.props.localLogin(values.username, values.password);
        }
    }


    render() {

        const { handleSubmit, pristine, reset, submitting, valid, working } = this.props;

        return (
            
            <div className="login-cont">
                <div className="auth-panel">
                    <div className="title-holder">
                        CHALLENGE.GG ADMIN
				    </div>
                    <div className="left"></div>
                    <div className="right">
                        <h2>Admin login</h2>
                        <form id="login-form" method="post" onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>

                            <Field
                                name="username"
                                type="text"
                                component={renderFieldWithShake}
                                label="E-mail address"
                                placeholder="Enter your e-mail address"
                                validate={[required, email]}
                            />

                            <Field
                                name="password"
                                type="password"
                                component={renderFieldWithShake}
                                label="Password"
                                placeholder="Enter your password"
                                validate={[required]}
                                
                            />
                            <div className="auth-controller">
                                <button type="submit" id="submit-button" name="button" className="submit" disabled={!valid || pristine || submitting}>
                                    <span>{working ? 'Working...' : 'SIGN IN'}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    console.log('Maping state to props');
    const { working, type, message } = state.userReducer;
    return {
        working, type, message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //functions
        localLogin: (username, password) => dispatch(login(username, password)),
        localClear: () => dispatch(clear())
    };

}

Login = reduxForm({
    form: 'login' // a unique name for this form
})(Login);

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login
