export const errorGradient = 'linear-gradient(-238deg, #FF6A8C 0%, #FF7B64 100%)';
export const successGradient = 'linear-gradient(-224deg, #2F80ED 0%, #2698FF 51%, #2F80ED 100%)';

function parseArgument(f1, f2, f3) {
    if (typeof f1 === 'string') {
        const payload = {
            title: f1,
        };
        if (f2) payload.text = f2;
        if (f3) payload.type = f3;
        return payload;
    }
    return f1[0];
}

export function openAlert(...args) {
    const payload = parseArgument(args);
    return({
        type: "SHOW_SWAL",
        payload
    });
}

export function openSuccessAlert(...args) {
    const payload = { ...parseArgument(args), icon: 'success', background: successGradient, confirmButtonColor: 'rgb(80, 227, 194)', confirmButtonText: 'Ok' };
    console.log(payload);
    return ({
        type: "SHOW_SWAL",
        payload
    });
}

export function openErrorAlert(...args) {
    const payload = { ...parseArgument(args), icon: 'error', background: errorGradient, confirmButtonColor: 'rgb(80, 227, 194)', confirmButtonText: 'Ok' };
    console.log(payload);
    return ({
        type: "SHOW_SWAL",
        payload
    });
}

export function closeAlert(){
    return({
        type: "HIDE_SWAL",
    });
}


export function goToHome() {
    return { type: "GO_TO_URL", url:'/'};
}


export function goToRegister() {
    return { type: "GO_TO_URL", url: '/signup' };
}

export function showInfoPopup(message) {
    return { type: "SHOW_POPUP", messageType: 'info', message: message, gradient: successGradient};
}


export function showErrorPopup(title, subtitle){
    return {type: "SHOW_ERROR_POPUP", title, subtitle}
}

export function showSuccessPopup(title, subtitle){
    return {type: "SHOW_SUCCESS_POPUP", title, subtitle}
}


export function showErrorPopupWithAction(message, onClose) {
    return { type: "SHOW_POPUP", messageType: 'error', message: message, gradient: errorGradient, onClose: onClose};
}

export function hideAnimation() {
    return { type: "HIDE_CHALLENGE_EVENT"};
}

export function resetWaitDialog(){
    return {type: "RESET_WAIT_DIALOG"}
}

